import _ from "lodash";
import {useForm} from "@dladio/hooks";
import {TextField} from "@fluentui/react";
import {LabelOnLeft} from "@dladio/core-ui";
import {PI_ERP_METHODS} from "../../../../../../const/method.const";
import OnlineSearchField from "../../../../../Common/OnlineSearchField/OnlineSearchField";
import {PIFK_TBL_LINE_EDIT_FORM} from "../../../../../../const/form-field.const";
import {PURCHASE_INVOICE_FORM_STORE_KEYS} from "../../../../../../const/form-store.const";

const EditableLine = () => {
    const {form, setForm, formController} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.TABLE_LINE_EDIT_FORM)

    const onChangeItemHandler = (id: any, key: any) => {
        setForm(PIFK_TBL_LINE_EDIT_FORM.ITEM_CODE, key)
    }

    const onChangeWareHouseHandler = (e: any, {key, text}: any) => {
        setForm(PIFK_TBL_LINE_EDIT_FORM?.WAREHOUSE, key)
        setForm(PIFK_TBL_LINE_EDIT_FORM?.WAREHOUSE_NAME, text)
    }

    return (
        <div className="pt-3 px-2 pb-1 flex flex-col gap-2">
            <LabelOnLeft label={"Item Code"}>
                <TextField className="form-field-width" value={_.get(form, PIFK_TBL_LINE_EDIT_FORM.ITEM_CODE) ?? ''}
                           name={PIFK_TBL_LINE_EDIT_FORM.ITEM_CODE} disabled={true}/>
            </LabelOnLeft>
            <LabelOnLeft label="Item Name">
                <OnlineSearchField id={PIFK_TBL_LINE_EDIT_FORM.ITEM_CODE}
                                   defaultKey={_.get(form, PIFK_TBL_LINE_EDIT_FORM.ITEM_CODE) ?? ''}
                                   onChange={onChangeItemHandler}
                                   options={{method: PI_ERP_METHODS.GET_ITEMS}}/>
            </LabelOnLeft>
            <LabelOnLeft label="Quantity">
                <TextField className="form-field-width" value={_.get(form, PIFK_TBL_LINE_EDIT_FORM.QUANTITY) ?? ''}
                           name={PIFK_TBL_LINE_EDIT_FORM.QUANTITY}
                           onChange={formController}/>
            </LabelOnLeft>
            <LabelOnLeft label="Discount">
                <TextField className="form-field-width" value={_.get(form, PIFK_TBL_LINE_EDIT_FORM.DISCOUNT) ?? ''}
                           name={PIFK_TBL_LINE_EDIT_FORM.DISCOUNT}
                           onChange={formController}/>
            </LabelOnLeft>
            <LabelOnLeft label={"Unit Price"}>
                <TextField className="form-field-width" value={_.get(form, PIFK_TBL_LINE_EDIT_FORM.UNIT_PRICE) ?? ''}
                           name={PIFK_TBL_LINE_EDIT_FORM.UNIT_PRICE} disabled={true}/>
            </LabelOnLeft>
            <LabelOnLeft label="Warehouse">
                <OnlineSearchField id={PIFK_TBL_LINE_EDIT_FORM.WAREHOUSE}
                                   options={{method: PI_ERP_METHODS.GET_WAREHOUSE_LIST}}
                                   defaultKey={_.get(form, PIFK_TBL_LINE_EDIT_FORM.WAREHOUSE) ?? ''}
                                   onChange={onChangeWareHouseHandler}/>
            </LabelOnLeft>
            <LabelOnLeft label={"GST Code"}>
                <TextField className="form-field-width" value={_.get(form, PIFK_TBL_LINE_EDIT_FORM.GST_CODE) ?? ''}
                           name={PIFK_TBL_LINE_EDIT_FORM.GST_CODE} disabled={true}/>
            </LabelOnLeft>
            <LabelOnLeft label={'Tax Rate'}>
                <TextField className="form-field-width" value={_.get(form, PIFK_TBL_LINE_EDIT_FORM.TAX_RATE) ?? ''}
                           name={PIFK_TBL_LINE_EDIT_FORM.TAX_RATE} disabled={true}/>
            </LabelOnLeft>
            <LabelOnLeft label={"Line Total"}>
                <TextField className="form-field-width" value={_.get(form, PIFK_TBL_LINE_EDIT_FORM.LINE_TOTAL) ?? ''}
                           name={PIFK_TBL_LINE_EDIT_FORM.LINE_TOTAL} disabled={true}/>
            </LabelOnLeft>
        </div>
    )
}

export default EditableLine