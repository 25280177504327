import _ from "lodash"
import {ARCNFK} from "../../../const/form-field.const"
import {useRef} from "react";
import {useForm} from "@dladio/hooks"
import {TextField} from "@fluentui/react";
import {LabelOnLeft} from "@dladio/core-ui"
import {ARCN_ERP_METHODS} from "../../../const/method.const"
import AutoCompleteDropDown from "./AutoCompleteDropDown/AutoCompleteDropDown"
import {ARCN_FORM_STORE_KEYS} from "../../../const/form-store.const"
import AutoCompleteTextField from "../../../components/AutoCompleteTextField/AutoCompleteTextField";
import {useAppBridgeSelector} from "@dladio/app-bridge";

const SupplierDetails = ({parentRef}: any) => {
    const {form, setForm, formController} = useForm(ARCN_FORM_STORE_KEYS.CREDIT_NOTE)
    const {form: errorForm} = useForm(ARCN_FORM_STORE_KEYS.ERROR_FORM)
    const {model} = useAppBridgeSelector();
    const id: any = _.get(model, 'params.id',)
    const ref: any = useRef()

    parentRef.current = ref?.current
    const onChangeSupplier = (id: any, key: any) => {
        setForm(ARCNFK.CARD_CODE, key)
    }
    const onChangeContactPerson = (e: any, {key, text}: any) => {
        setForm(ARCNFK.CONTACT_PERSON, key)
    }

    return (
        <div className={'px-2 pb-2 bg-[#f0f0f0]  w-full h-full'}>
            <div className="px-2 pb-2 bg-white h-full rounded-md pt-3 flex flex-col gap-2">
                <LabelOnLeft label="Customer">
                    <div className={'space-y-1'}>
                        <AutoCompleteTextField
                            className={'w-[280px]'}
                            disabled={id}
                            defaultKey={_.get(form, ARCNFK.CARD_CODE)}
                            required
                            id={ARCNFK.CARD_CODE}
                            onChange={onChangeSupplier}
                            options={{
                                method: ARCN_ERP_METHODS.GET_CUSTOMER,
                                body: {}
                            }}
                        />
                        {_.get(errorForm, 'mainForm.CardCode') && (
                            <p className={'text-red-500 text-xs'}>{_.get(errorForm, 'mainForm.CardCode')}</p>)}
                    </div>
                </LabelOnLeft>
                <LabelOnLeft label="Contact Person">
                    <AutoCompleteDropDown method={ARCN_ERP_METHODS.GET_CONTACT_PERSON}
                                          defaultKey={_.get(form, ARCNFK.CONTACT_PERSON)}
                                          body={{id: _.get(form, ARCNFK.CARD_CODE)}}
                                          isDisabled={id}
                                          onChange={onChangeContactPerson}/>
                </LabelOnLeft>
                <LabelOnLeft label="Customer Ref.No">
                    <TextField className="form-field-width" value={_.get(form, ARCNFK.REFNO)} name={ARCNFK.REFNO}
                               onChange={formController}/>
                </LabelOnLeft>
            </div>
        </div>
    )
}

export default SupplierDetails
