import {
    Pivot,
    PivotItem,
    PrimaryButton,
    DefaultButton
} from "@fluentui/react";
import _ from "lodash";
import React from "react";
import {isArray} from "@dladio/utils";
import {exeMethod} from "../../../actions/actions";
import EditableLine from "./components/EditableLine";
import {useDispatch} from "react-redux";
import BinAllocation from "./components/BinAllocation";
import {buttonStyles} from "@fluentui/react/lib/components/Nav/Nav.styles";
import BatchAllocation from "./components/BatchAllocation";
import {PI_ERP_METHODS} from "../../../../../const/method.const";
import {Panel, PanelType} from '@fluentui/react/lib/Panel';
import {middleErrorToast} from "../../../../../service/toast.service";
import {clearAllFieldAction} from "../../../../PurchaseOrder/actions/actions"; //need to create this file inside purchase invoice folder and add this action in it
import {useForm, useLocalStore} from "@dladio/hooks";
import {PIFK_TBL_LINE_EDIT_FORM} from "../../../../../const/form-field.const";
import {PURCHASE_INVOICE_STORE_PATH} from "../../../../../const/local-store.const";
import {PURCHASE_INVOICE_FORM_STORE_KEYS} from "../../../../../const/form-store.const";

const EditTableLinePanel = () => {

    const dispatch: any = useDispatch()
    const {model, setStore} = useLocalStore(PURCHASE_INVOICE_STORE_PATH.TABLE_LINE_EDIT_PANEL)
    const {setStore: setLoadingState} = useLocalStore(PURCHASE_INVOICE_STORE_PATH.PAGE_LOADING)
    const {form, setForm, formController} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.TABLE_LINE_EDIT_FORM)
    const {
        form: purchaseInvoiceForm,
        setForm: setPurchaseInvoiceForm
    } = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.PURCHASE_INVOICE)
    const {form: batchLocationForm} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.BATCH_LOCATION_FORM)
    const {form: binLocationForm} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.BIN_LOCATION_FORM)

    const onSaveHandler = () => {

        if (_.get(form, PIFK_TBL_LINE_EDIT_FORM.ITEM_CODE) && _.get(form, PIFK_TBL_LINE_EDIT_FORM.QUANTITY)) {
            setLoadingState(true)
            setStore('is_open', false)

            dispatch(exeMethod({
                method: PI_ERP_METHODS.GENERATE_ITEM_LINE,
                body: {
                    [PIFK_TBL_LINE_EDIT_FORM.ITEM_CODE]: _.get(form, PIFK_TBL_LINE_EDIT_FORM.ITEM_CODE),
                    [PIFK_TBL_LINE_EDIT_FORM.QUANTITY]: _.get(form, PIFK_TBL_LINE_EDIT_FORM.QUANTITY),
                    [PIFK_TBL_LINE_EDIT_FORM.DISCOUNT]: _.get(form, PIFK_TBL_LINE_EDIT_FORM.DISCOUNT),
                    [PIFK_TBL_LINE_EDIT_FORM.SUPPLIER_CODE]: _.get(purchaseInvoiceForm, PIFK_TBL_LINE_EDIT_FORM.SUPPLIER_CODE),
                    [PIFK_TBL_LINE_EDIT_FORM.WAREHOUSE]: _.get(form, PIFK_TBL_LINE_EDIT_FORM.WAREHOUSE),
                }
            })).then((line: any) => {
                let clonedLines: any = _.cloneDeep(_.get(purchaseInvoiceForm, 'DocumentLines'))
                const newLine = {
                    ...line,
                    BatchNumbers: [...isArray(batchLocationForm) ? batchLocationForm : []],
                    DocumentLinesBinAllocations: [...isArray(binLocationForm) ? binLocationForm : []]
                }
                dispatch(clearAllFieldAction(PURCHASE_INVOICE_FORM_STORE_KEYS.BATCH_LOCATION_FORM))
                dispatch(clearAllFieldAction(PURCHASE_INVOICE_FORM_STORE_KEYS.BIN_LOCATION_FORM))
                clonedLines[_.get(model, 'selected_line_index')] = newLine
                setPurchaseInvoiceForm('DocumentLines', clonedLines)
            }).catch((error: any) => {
                console.error(error)
                middleErrorToast('Error', 'Item Line cannot be edited')
            }).finally(() => {
                setLoadingState(false)
            })
        } else {
        }
    }

    const onCancelHandler = () => {
        setStore('is_open', false)
        dispatch(clearAllFieldAction(PURCHASE_INVOICE_FORM_STORE_KEYS.BATCH_LOCATION_FORM))
        dispatch(clearAllFieldAction(PURCHASE_INVOICE_FORM_STORE_KEYS.BIN_LOCATION_FORM))
    }

    const onRenderFooterContent =
        () => (
            <div className={'flex gap-2'}>
                <PrimaryButton onClick={onSaveHandler} styles={buttonStyles}>
                    Save
                </PrimaryButton>
                <DefaultButton onClick={() => {
                    onCancelHandler()
                }
                }>Cancel</DefaultButton>
            </div>
        )

    return (
        <div>
            <Panel
                type={PanelType.large}
                isOpen={_.get(model, 'is_open')}
                // isOpen={true}
                onDismiss={() => setStore('is_open', false)}
                headerText="Edit Line"
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooterContent}
                isFooterAtBottom={true}
            >
                <Pivot>
                    <PivotItem headerText="Edit Line">
                        <EditableLine/>
                    </PivotItem>
                    <PivotItem headerText="Bin Location Allocation - Receipt">
                        <div className="flex flex-col justify-between h-[60vh]">
                            <BatchAllocation/>
                            <hr/>
                            <BinAllocation/>
                        </div>
                    </PivotItem>
                </Pivot>

            </Panel>
        </div>
    )
}
export default EditTableLinePanel