import _ from "lodash"
import {POFK} from "../../../const/form-field.const"
import {useRef} from "react";
import {useForm} from "@dladio/hooks"
import {TextField} from "@fluentui/react";
import {LabelOnLeft} from "@dladio/core-ui"
import {PO_ERP_METHODS} from "../../../const/method.const"
import OnlineSearchField from "../../Common/OnlineSearchField/OnlineSearchField";
import AutoCompleteDropDown from "./AutoCompleteDropDown/AutoCompleteDropDown"
import {PERCHASE_ORDER_FORM_STORE_KEYS} from "../../../const/form-store.const"

const SupplierDetails = ({parentRef}:any) => {
    const {form, setForm, formController} = useForm(PERCHASE_ORDER_FORM_STORE_KEYS.PURCHASE_ORDER)
    const {form: errorForm} = useForm(PERCHASE_ORDER_FORM_STORE_KEYS.ERROR_FORM)
    const ref:any = useRef()
    parentRef.current = ref?.current
    const onChangeSupplier = (id: any, key: any) => {
        setForm(POFK.SUPPLIER_CODE, key)
    }
    const onChangeContactPerson = (e: any, {key, text}: any) => {
        setForm(POFK.CONTACT_PERSON, key)
    }

    return (
        <div className={'px-2 pb-2 bg-[#f0f0f0]  w-full h-full'}>
            <div className="px-2 pb-2 bg-white h-full rounded-md pt-3 flex flex-col gap-2">
                <LabelOnLeft label="Supplier">
                    <div className={'space-y-1'}>
                        <OnlineSearchField id={'id'}
                                           ref={ref}
                                           defaultKey={_.get(form,POFK.SUPPLIER_CODE)}
                                           options={{method: PO_ERP_METHODS.GET_SUPPIRES, payload: {}}}
                                           onChange={onChangeSupplier}/>
                        {_.get(errorForm, 'mainForm.CardCode') && (
                            <p className={'text-red-500 text-xs'}>{_.get(errorForm, 'mainForm.CardCode')}</p>)}
                    </div>
                </LabelOnLeft>
                <LabelOnLeft label="Contact Person">
                    <AutoCompleteDropDown method={PO_ERP_METHODS.GET_CONTACT_PERSON}
                                          body={{id: _.get(form, POFK.SUPPLIER_CODE)}}
                                          onChange={onChangeContactPerson}/>
                </LabelOnLeft>
                <LabelOnLeft label="Supplier Ref.No">
                    <TextField className="form-field-width" value={_.get(form, POFK.REFNO)} name={POFK.REFNO}
                               onChange={formController}/>
                </LabelOnLeft>
            </div>
        </div>
    )
}

export default SupplierDetails
