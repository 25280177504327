import _ from "lodash";
import {isArray} from "@dladio/utils";
import {useDispatch} from "react-redux";
import React, {useRef} from "react";
import {PI_ERP_METHODS} from "../../../../../../const/method.const";
import OnlineSearchField from "../../../../../Common/OnlineSearchField/OnlineSearchField";
import {clearAllFieldAction} from "../../../../../PurchaseOrder/actions/actions";
import {useForm, useLocalStore} from "@dladio/hooks";
import { PIFK_BIN_LOCATION} from "../../../../../../const/form-field.const";
import {PrimaryButton, TextField} from "@fluentui/react";
import {PURCHASE_INVOICE_STORE_PATH} from "../../../../../../const/local-store.const";
import {PURCHASE_INVOICE_FORM_STORE_KEYS} from "../../../../../../const/form-store.const";

const BinLocationAllocationReceiptTable: React.FC = () => {
    const ref: any = useRef()
    const {form, setForm, formController} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.TEMP_ADD_BIN_FORM)
    const {
        form: binForm,
        setForm: setBinForm,
        formController: binFormController
    } = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.BIN_LOCATION_FORM)
    const {model, setStore} = useLocalStore(PURCHASE_INVOICE_STORE_PATH.TABLE_LINE_EDIT_PANEL)

    const dispatch: any = useDispatch()

    const onAddHandler = () => {
        const newBin = {
            [PIFK_BIN_LOCATION.BIN_ABS_ENTRY]: _.get(form, PIFK_BIN_LOCATION.BIN_ABS_ENTRY),
            [PIFK_BIN_LOCATION.QUANTITY]: _.get(form, PIFK_BIN_LOCATION.QUANTITY),
            [PIFK_BIN_LOCATION.BASE_LINE_NUMBER]: _.get(model, 'selected_line_index'),
            [PIFK_BIN_LOCATION.SERIAL_AND_BATCH_NUMBER_BASE_LINE]: _.get(model, 'selected_batch_line_index')
        }

        const bins = [...isArray(binForm) ? binForm : [], newBin]
        console.log('new bin', bins)
        setBinForm(bins)
        ref.current.clearSearch()
        dispatch(clearAllFieldAction(PURCHASE_INVOICE_FORM_STORE_KEYS.TEMP_ADD_BIN_FORM))
    }

    return (
        <>
            <div className="flex items-end gap-2 p-2">
                {/*<AutoCompleteDropDown label={'Bin Location'} method={PI_ERP_METHODS.GET_BIN_LOCATIONS_LIST}/>*/}
                <OnlineSearchField label={'Bin'} id={PIFK_BIN_LOCATION.BIN_ABS_ENTRY}
                                   options={{
                                       method: PI_ERP_METHODS.GET_BIN_LOCATIONS_LIST,
                                       payload: {id: _.get(model, 'selected_warehouse')}
                                   }}
                                   ref={ref}
                                   onChange={formController}
                                   defaultKey={_.get(form, PIFK_BIN_LOCATION.BIN_ABS_ENTRY)}/>
                <TextField label="Allocated" name={PIFK_BIN_LOCATION.QUANTITY} className="form-field-width"
                           onChange={formController} value={_.get(form, PIFK_BIN_LOCATION.QUANTITY) ?? null}/>
                <PrimaryButton className="bg-primary !text-[12px] !h-[25px]" text="Add" onClick={onAddHandler}/>
            </div>
        </>
    )
}

export default BinLocationAllocationReceiptTable