import _ from "lodash";
import {Box} from "@dladio/core-ui"
import {ARCNFK} from "../../../const/form-field.const";
import {useRef} from "react";
import {isArray} from "@dladio/utils";
import {useDispatch} from "react-redux";
import {ARCN_STORE_PATH} from "../../../const/local-store.const";
import OnlineSearchField from "../../Common/OnlineSearchField/OnlineSearchField";
import {ARCN_ERP_METHODS} from "../../../const/method.const";
import {middleWarningToast} from "../../../service/toast.service";
import {ARCN_FORM_STORE_KEYS} from "../../../const/form-store.const";
import {useForm, useLocalStore} from "@dladio/hooks";
import {PrimaryButton, TextField} from "@fluentui/react"
import {exeMethod, getFormValue, getLocalStoreValue, setFormValue, setLocalStoreValue} from "../actions/actions";

const ItemSelection = ({parentRef}: any) => {

    const ref: any = useRef()
    const dispatch: any = useDispatch()
    const {setStore} = useLocalStore(ARCN_STORE_PATH.PAGE_LOADING)
    const {form, setForm, formController} = useForm(ARCN_FORM_STORE_KEYS.CREDIT_NOTE)
    const onChangeItemHandler = (e: any, key: any) => {
        setForm(ARCNFK.SELECTED_ITEM, key)
    }

    parentRef.current = ref?.current

    const onClickItemAdd = () => {

        if (_.get(form, ARCNFK.SELECTED_ITEM) && _.get(form, ARCNFK.QUANTITY) > 0) {
            setStore(true)
            let currentLineNumber = dispatch(getLocalStoreValue(ARCN_STORE_PATH.LINE_NO)) || 0
            dispatch(exeMethod({
                method: ARCN_ERP_METHODS.GENERATE_ITEM_LINE,
                body: {
                    [ARCNFK.SELECTED_ITEM]: _.get(form, ARCNFK.SELECTED_ITEM),
                    [ARCNFK.QUANTITY]: _.get(form, ARCNFK.QUANTITY),
                    [ARCNFK.DISCOUNT]: _.get(form, ARCNFK.DISCOUNT) ? _.get(form, ARCNFK.DISCOUNT) : 0,
                    [ARCNFK.CARD_CODE]: _.get(form, ARCNFK.CARD_CODE),
                    [ARCNFK.LINE_NUM]: currentLineNumber,
                }

            })).then((line: any) => {
                let currentTableLines = dispatch(getFormValue(ARCN_FORM_STORE_KEYS.TABLE_LINE_FORM))
                const lines = [line, ...isArray(currentTableLines) ? currentTableLines : []]
                dispatch(setFormValue(ARCN_FORM_STORE_KEYS.TABLE_LINE_FORM, '', lines))
                ref?.current?.clearSearch()
                dispatch(setFormValue(ARCN_FORM_STORE_KEYS.CREDIT_NOTE, '', {
                    ...form,
                    [ARCNFK.SELECTED_ITEM]: '',
                    [ARCNFK.QUANTITY]: 0,
                    [ARCNFK.DISCOUNT]: 0
                }))

                dispatch(setLocalStoreValue(ARCN_STORE_PATH.LINE_NO, '', currentLineNumber + 1))

            }).catch((e: any) => {
                console.log(e)

            }).finally(() => {
                setStore(false)

            })
        } else {
            middleWarningToast('Warning', 'Please select an item and quantity')
        }
    }

    return (
        <Box className={'mt-2'}>
            <div className="flex items-end gap-2 p-2">
                <OnlineSearchField ref={ref} id={ARCN_ERP_METHODS.GET_ITEMS} label={'Item'}
                                   defaultKey={_.get(form, ARCNFK.SELECTED_ITEM)}
                                   options={{method: ARCN_ERP_METHODS.GET_ITEMS, payload: {}}}
                                   onChange={onChangeItemHandler}/>
                <TextField type={'number'} value={_.get(form, ARCNFK.QUANTITY)} label="Quantity" name={ARCNFK.QUANTITY}
                           className="form-field-width"
                           onChange={formController}/>
                <TextField value={_.get(form, ARCNFK.DISCOUNT)} label="Discount" name={ARCNFK.DISCOUNT}
                           className="form-field-width"
                           onChange={formController}/>
                <PrimaryButton className="bg-primary !text-[12px] !h-[25px]" text="Add"
                               onClick={onClickItemAdd}/>
            </div>
        </Box>
    )
}

export default ItemSelection