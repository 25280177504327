import {ComboBox} from "@fluentui/react"
import {useDerive} from "@dladio/service"
import {isArray} from "@dladio/utils";
import {useBoolean} from "@fluentui/react-hooks";

const AutoCompleteDropDown = ({
                                  label = '',
                                  method,
                                  staticOptions,
                                  body,
                                  defaultKey,
                                  dataTestId,
                                  onChange = () => {
                                  }
                              }: any) => {
    const [allowFreeform, {toggle: toggleAllowFreeform}] = useBoolean(true);

    let {value, isFetching} = useDerive('exec', {
        options: {
            method: method,
            body: body
        },
        path: 'message'
    })

    value = isArray(value) ? value : []

    return (
        <ComboBox
            className="form-field-width"
            data-testid={dataTestId}
            label={label}
            onChange={onChange}
            defaultSelectedKey={defaultKey}
            options={staticOptions ?? value} allowFreeform={allowFreeform}
        />
    )
}

export default AutoCompleteDropDown