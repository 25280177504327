import {POFK, POFK_LOGISTIC} from "../../../const/form-field.const";
import {array, object, string} from 'yup';

let purchaseOrderSchema: any = object({
    mainForm: object().shape({
        [POFK.SUPPLIER_CODE]: string().required('This field is required'),
    }),
    logistic: object().shape({
        [POFK_LOGISTIC.SHIPPING_TYPE]: string().required('This field is required'),
    }),
    itemLines: array().required("select at least one item").typeError("select at least one item").min(1, 'Select at least one item')
});

export default purchaseOrderSchema

