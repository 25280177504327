import {PivotItem} from "@fluentui/react"
import {DefaultPivot} from "@dladio/core-ui"
import LogisticDetails from "./LogisticDetails";
import SelectedItemsTable from "./SelectedItemsTable"

const Pivot = () => {
    return (
        <DefaultPivot>
            <PivotItem headerText="Contents">
                <SelectedItemsTable/>
            </PivotItem>
            <PivotItem headerText="Logistics">
                <LogisticDetails/>
            </PivotItem>
        </DefaultPivot>
    )
}

export default Pivot

