import _ from "lodash";
import {buttonStyles} from "@fluentui/react/lib/components/Nav/Nav.styles";
import {Panel, PanelType} from '@fluentui/react/lib/Panel';
import {useLocalStore} from "@dladio/hooks";
import {DefaultButton, PrimaryButton} from "@fluentui/react";
import {GRPO_STORE_PATH} from "../../../../../const/local-store.const";
import GRPOFreightTable from "./GRPOFreightTable/GRPOFreightTable";
import {useDispatch} from "react-redux";
import {exeMethod, setFormValue} from "../../../../PurchaseOrder/actions/actions";
import {PO_ERP_METHODS} from "../../../../../const/method.const";
import {GRPO_FORM_STORE_KEYS} from "../../../../../const/form-store.const";
import {middleErrorToast} from "../../../../../service/toast.service";
import {useEffect} from "react";
import {getFreightDetails} from "../../../actions/actions";
import {useAppBridgeSelector} from "@dladio/app-bridge";

const GRPOFreightEditPanel = () => {

    const {model: freightModel, setStore} = useLocalStore(GRPO_STORE_PATH.FREIGHT_EDIT_PANEL);
    const dispatch: any = useDispatch()

    const {model} = useAppBridgeSelector();

    // const model = { //TODO: This is use for testing
    //     params: {
    //         id: 502
    //     }
    // }

    useEffect(() => {
        if (_.get(model, 'params.id')) {
            dispatch(getFreightDetails(_.get(model, 'params.id')));
        }
    }, [_.get(model, 'params.id')]);

    const onSaveHandler = () => setStore('is-open', false)
    const onRenderFooterContent =
        () => (
            <div className={'flex gap-2'}>
                <PrimaryButton onClick={onSaveHandler} styles={buttonStyles}>
                    Submit
                </PrimaryButton>
                <DefaultButton onClick={onClickCancelHandler}>Clear</DefaultButton>
            </div>
        )

    const onClickCancelHandler = () => {
        setStore('is-open', false)
        dispatch(exeMethod({
            method: PO_ERP_METHODS.GET_FREIGHT,
            body: {}

        })).then((data: any) => {
            dispatch(setFormValue(GRPO_FORM_STORE_KEYS.FREIGHT_FORM, '', data))

        }).catch((error: any) => {
            console.log(error)
            middleErrorToast('error', 'Cannot load freight data')
        })
    }

    return (
        <div>
            <Panel
                type={PanelType.extraLarge}
                isOpen={_.get(freightModel, 'is-open')}
                onDismiss={() => setStore('is-open', false)}
                headerText="GRPO Freight"
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooterContent}
                isFooterAtBottom={true}
            >
                <GRPOFreightTable/>
            </Panel>
        </div>
    )

}
export default GRPOFreightEditPanel