import _ from "lodash";
import React, {ReactElement, useEffect, useRef, useState} from "react";
import {Box, Grid, LabelOnLeft, TitleBar, TupleCard, TupleCardLine} from "@dladio/core-ui";
import {Spinner, TextField} from "@fluentui/react";
import SupplierDetails from "./component/SupplierDetails";
import StatusAndDateSection from "./component/StatusAndDateSection";
import DefaultPivotSection from "./component/DefaultPivotSection";
import CommandBar from "./component/CommandBar";
import DocumentActionSection from "./component/DocumentActionSection";
import Summary from "./component/Summary";
import {clearAllFieldAction, clearFormAction, getFormValue, setFormValue} from "../PurchaseOrder/actions/actions";
import {GRPO_ERP_METHODS, PO_ERP_METHODS} from "../../const/method.const";
import {GRPO_FORM_STORE_KEYS} from "../../const/form-store.const";
import {middleErrorToast} from "../../service/toast.service";
import {GRPOFK, GRPOFK_LOGISTIC} from "../../const/form-field.const";
import {AppDispatch} from "../../store/store";
import {useDispatch} from "react-redux";
import {useForm} from "@dladio/hooks";
import {exeMethod} from "./actions/actions";
import {useAppBridgeSelector} from "@dladio/app-bridge";

const GoodsReceiptPurchaseOrder: React.FC = () => {

    const dispatch: AppDispatch = useDispatch();
    const {form, formController, setForm} = useForm(GRPO_FORM_STORE_KEYS.GRPO_DOCUMENT_LINE_FROM);
    const ref: any = useRef();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const {model} = useAppBridgeSelector();

    // const model = { //TODO: This is use for testing
    //     params: {
    //         id: 502
    //     }
    // }


    useEffect(() => {

        let mainForm: any = dispatch(getFormValue(GRPO_FORM_STORE_KEYS.GRPO_DOCUMENT_LINE_FROM))
        ref?.current?.clearSearch()

        if (!_.get(model, 'params.id')) {
            dispatch(clearFormAction(GRPO_FORM_STORE_KEYS.GRPO_DOCUMENT_LINE_FROM, [GRPOFK.ORDER_DISCOUNT,
                GRPOFK.DISCOUNT, GRPOFK.CONTACT_PERSON, GRPOFK.QUANTITY, GRPOFK.REFNO, GRPOFK.SELECTED_ITEM,
                GRPOFK.SHIPPING_INSTRACTION, 'lines'], mainForm))
            dispatch(clearAllFieldAction(GRPO_FORM_STORE_KEYS.LOGISTIC_FORM))
        }

        dispatch(exeMethod({
            method: PO_ERP_METHODS.GET_LOGISTIC,
            body: {
                "supplier_id": _.get(mainForm, GRPOFK.SUPPLIER_CODE)
            }

        })).then((res: any) => {
            dispatch(setFormValue(GRPO_FORM_STORE_KEYS.LOGISTIC_FORM, GRPOFK_LOGISTIC.SHIP_TO_ADDRESS,
                _.get(res, 'ship_to') ?? ''))
            dispatch(setFormValue(GRPO_FORM_STORE_KEYS.LOGISTIC_FORM, GRPOFK_LOGISTIC.PAY_TO_ADDRESS,
                _.get(res, 'pay_to') ?? ''))
            dispatch(setFormValue(GRPO_FORM_STORE_KEYS.LOGISTIC_FORM, GRPOFK_LOGISTIC.DISPLAY_ADDRESS,
                _.get(res, 'company_address') ?? ''))

        }).catch((err: any) => {
            console.error(err)
        })

    }, [_.get(form, GRPOFK.SUPPLIER_CODE)]);

    useEffect(() => {

        if (!_.get(model, 'params.id')) {
            dispatch(exeMethod({
                method: GRPO_ERP_METHODS.GET_FREIGHT,
                body: {}

            })).then((data: any) => {
                dispatch(setFormValue(GRPO_FORM_STORE_KEYS.FREIGHT_FORM, '', data))

            }).catch((error: any) => {
                console.log(error)
                middleErrorToast('error', 'Cannot load freight data')
            })
        }

    }, [_.get(form, GRPOFK.SUPPLIER_CODE)]);

    useEffect(() => {
        if (_.get(model, 'params.id')) {
            dispatch(exeMethod({
                method: GRPO_ERP_METHODS.GRPO_COPY_FROM,
                body: {
                    id: _.get(model, 'params.id')
                }
            })).then((res: any) => {
                console.log("Res ======================>", res)
                setForm(res);
                setIsLoading(false);
            }).catch((error: any) => {
                console.log("Error ====================> ", error)
            })
        } else {
            setIsLoading(false);
        }
    }, [_.get(model, 'params.id')]);

    const supplierDetailsMarkup: ReactElement = (
        <>
            {
                _.get(model, 'params.id') ? (
                    <>
                        <TupleCard title="Supplirer Details">
                            <TupleCardLine title="Supplier" value={_.get(form, 'CardName')}/>
                            <TupleCardLine title="Contact Person" value={_.get(form, 'ContactPersonName')}/>
                            <TupleCardLine title="Supplier Ref.No" value={_.get(form, 'NumAtCard')}/>
                        </TupleCard>
                    </>
                ) : <SupplierDetails/>
            }
        </>
    );

    return (
        <>
            <div className="bg-[#f0f0f0]">
                <TitleBar title="Welcome" subTitle={`Helios Marketing Document Template`}/>

                {/* All actions goes here. Eg - Save, Print, Email an etc... */}
                <CommandBar setIsLoading={setIsLoading}/>
                {
                    isLoading ?
                        <>
                            <div className={'flex h-[80vh] bg-white pt-52'}>
                                <Spinner label={'Processing...'} className={'m-auto'}/>
                            </div>
                        </> :
                        <>
                            <Grid>
                                {supplierDetailsMarkup}
                                <StatusAndDateSection/>
                            </Grid>
                            <DocumentActionSection/>
                            <DefaultPivotSection/>

                            <Grid className="grid-cols-3">
                                <Box>
                                    <div className="pt-3 px-2 pb-1 flex flex-col gap-2">
                                        <LabelOnLeft label="Shipping Instructions">
                                            <TextField name={GRPOFK.COMMENTS} value={_.get(form, 'Comments', '')}
                                                       onChange={formController}
                                                       className="w-[350px]"
                                                       multiline rows={6}/>
                                        </LabelOnLeft>
                                    </div>
                                </Box>
                                <Summary/>
                            </Grid>
                        </>
                }
            </div>
        </>
    )
}
export default GoodsReceiptPurchaseOrder