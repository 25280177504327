import {AppDispatch, RootState} from "../../../../../../../store/store";
import {setLocalStoreModel} from "@dladio/hooks/dist/store/slices/local-store";
import {GRPO_FORM_STORE_KEYS} from "../../../../../../../const/form-store.const";
import {GRPO_STORE_PATH} from "../../../../../../../const/local-store.const";

export const getBatchSetupLine = () => {

    return (dispatch: AppDispatch, getState: RootState) => {
        if (getState()?.localStore?.model?.GRPO_batch_setup_table_line?.BatchNumbers) {
            const documentLineBatchNumbers = getState().localStore.model.GRPO_batch_setup_table_line.BatchNumbers.filter((item: any) => (
                item.BaseLineNumber === getState()?.localStore?.model?.GRPO_table_line_edite_panel?.LineNum
            ))

            dispatch(setLocalStoreModel({
                path: GRPO_FORM_STORE_KEYS.BATCH_SETUP_TABLE_LINE + ".DocumentLineBatchNumbers",
                model: documentLineBatchNumbers
            }))
        }
    }
}

export const getBinAllocationLines = (index: number) => {
    return (dispatch: AppDispatch, getState: RootState) => {
        if (getState()?.localStore?.model?.GRPO_bin_allocation_table?.DocumentLinesBinAllocations) {
            const currentSelectedBinLocation: Array<any> = getState()?.localStore?.model?.GRPO_bin_allocation_table?.DocumentLinesBinAllocations.filter((item: any) => (
                item.SerialAndBatchNumbersBaseLine === index
            ))
            dispatch(setLocalStoreModel({
                path: GRPO_STORE_PATH.BIN_ALLOCATION_TABLE + ".selectedBatchBinAllocation",
                model: currentSelectedBinLocation
            }))
        }
    }
}

export const deleteBinAllocationLines = (index: number) => {
    return (dispatch: AppDispatch, getState: RootState) => {
        if (getState()?.localStore?.model?.GRPO_bin_allocation_table?.DocumentLinesBinAllocations) {
            const currentSelectedBinLocation: Array<any> = getState()?.localStore?.model?.GRPO_bin_allocation_table?.DocumentLinesBinAllocations.filter((item: any) => (
                item.SerialAndBatchNumbersBaseLine !== index
            ))
            dispatch(setLocalStoreModel({
                path: GRPO_STORE_PATH.BIN_ALLOCATION_TABLE + ".selectedBatchBinAllocation",
                model: []
            }))
            dispatch(setLocalStoreModel({
                path: GRPO_STORE_PATH.BIN_ALLOCATION_TABLE + ".DocumentLinesBinAllocations",
                model: currentSelectedBinLocation
            }))
        }
    }
}