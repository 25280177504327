import {createStandaloneToast} from "@chakra-ui/react";
import {Spinner, SpinnerSize} from "@fluentui/react";

const toast = createStandaloneToast();

const middleErrorToast = (title: string, description = '') => {
    toast({
        title,
        description,
        status: 'error',
        duration: 2500,
        isClosable: true,
    })
}

const middleSuccessToast = (title: string, description = '') => {
    toast({
        title,
        description,
        status: 'success',
        duration: 2500,
        isClosable: true
    })
}

const middleWarningToast = (title: string, description = '') => {
    toast({
        title,
        description,
        status: 'warning',
        duration: 2500,
        isClosable: true
    })
}

const LoadingToast = ({className, style}: any) => {
    return (
        <div className={`flex justify-center align-middle content-center items-center ${className}`} style={style}>
            {/*<Spinner className={'font-bold'} color={'red'}/>*/}
            <Spinner label="Loading..." size={SpinnerSize.large} />
        </div>
    )
}

export {
    middleErrorToast,
    middleSuccessToast,
    middleWarningToast,
    LoadingToast
}