import _ from "lodash";
import useForm from "@dladio/hooks/dist/hooks/useForm";
import {AppDispatch} from "../../../../../../store/store";
import {useDispatch} from "react-redux";
import {useLocalStore} from "@dladio/hooks";
import {object, string} from "yup";
import React from "react";
import {GRPO_STORE_PATH} from "../../../../../../const/local-store.const";
import {GRPO_ERP_METHODS} from "../../../../../../const/method.const";
import AutoCompleteDropDown from "../../../../../PurchaseOrder/Components/AutoCompleteDropDown/AutoCompleteDropDown";
import {GRPO_FORM_STORE_KEYS} from "../../../../../../const/form-store.const";
import {GRPO_BIN_ALLOCATION_FK} from "../../../../../../const/form-field.const";
import {getBinAllocationLines} from "./actions/actions";
import {PrimaryButton, TextField} from "@fluentui/react";
import {middleErrorToast, middleSuccessToast} from "../../../../../../service/toast.service";

const BinLocationAllocationReceiptTable: React.FC = () => {

    const {model} = useLocalStore(GRPO_FORM_STORE_KEYS.TABLE_LINE_EDIT_PANEL);
    const {form, setForm, formController, clearForm} = useForm(GRPO_FORM_STORE_KEYS.BIN_ALLOCATION_TABLE_FORM);
    const {
        model: binAllocationModel,
        setStore: binAllocationSetStore
    } = useLocalStore(GRPO_STORE_PATH.BIN_ALLOCATION_TABLE);
    const {model: batchNumbersModel} = useLocalStore(GRPO_FORM_STORE_KEYS.BATCH_SETUP_TABLE_LINE);
    const onSelectBinLocation = (e: any, {key, text}: any) => {
        setForm(GRPO_BIN_ALLOCATION_FK.BIN_LOCATION, key);
        setForm(GRPO_BIN_ALLOCATION_FK.BIN_LOCATION_NAME, text);
    }
    const dispatch: AppDispatch = useDispatch();
    const onClickItemAdd = () => {

        binAllocationTableFormSchema.validate(form, {abortEarly: false}).then(() => {
            const currentLineIndex = _.get(binAllocationModel, GRPO_STORE_PATH.CURRENT_SELECTED_LINE);
            const currentLineQty = _.get(binAllocationModel, `${GRPO_STORE_PATH.CURRENT_SELECTED_LINE}.Quantity`);
            const batchList = _.get(batchNumbersModel, "BatchNumbers");
            if (currentLineIndex && batchList) {
                const batchIndex = batchList.findIndex((item: any) => (
                    item?.BatchNumber === _.get(binAllocationModel, `${GRPO_STORE_PATH.CURRENT_SELECTED_LINE}.BatchNumber`)
                ));
                const {BinAbsEntry, Quantity, BinAbsEntryName}: any = form
                if (currentLineQty >= Quantity) {
                    if (batchIndex !== -1) {
                        const documentLineBinLocations: Array<any> = _.cloneDeep(
                            _.get(binAllocationModel, GRPO_STORE_PATH.DOCUMENT_LINE_BIN_LOCATIONS, [])
                        )

                        const batchLineQty = _.sumBy(_.get(binAllocationModel, 'selectedBatchBinAllocation', []), (item: any) => (
                            Number(item.Quantity)
                        ));
                        const newTotalQty = batchLineQty + Number(Quantity);
                        if (newTotalQty <= Number(currentLineQty)) {

                            const existingIndex = documentLineBinLocations.findIndex(item =>
                                item.BinAbsEntry === BinAbsEntry &&
                                item.BaseLineNumber === _.get(binAllocationModel, `${GRPO_STORE_PATH.CURRENT_SELECTED_LINE}.BaseLineNumber`) &&
                                item.SerialAndBatchNumbersBaseLine === batchIndex
                            );

                            if (existingIndex !== -1) {
                                const existingItem = documentLineBinLocations[existingIndex];
                                existingItem.Quantity = Number(existingItem.Quantity) + Number(Quantity);
                                documentLineBinLocations.splice(existingIndex, 1);
                                documentLineBinLocations.push(existingItem);
                            } else {
                                const newItem = {
                                    BinAbsEntry: BinAbsEntry,
                                    BinAbsEntryName: BinAbsEntryName,
                                    Quantity: Number(Quantity),
                                    BaseLineNumber: _.get(binAllocationModel, `${GRPO_STORE_PATH.CURRENT_SELECTED_LINE}.BaseLineNumber`),
                                    SerialAndBatchNumbersBaseLine: batchIndex
                                };
                                documentLineBinLocations.push(newItem);
                            }

                            binAllocationSetStore(GRPO_STORE_PATH.DOCUMENT_LINE_BIN_LOCATIONS, documentLineBinLocations.reverse());
                            dispatch(getBinAllocationLines(_.get(binAllocationModel, 'current_select_index')));

                            middleSuccessToast("Successful", "Line added successfully");
                        } else {

                            middleErrorToast("Validation Error", "Maximum quantity allowed");
                        }
                    }
                } else {
                    middleErrorToast("Validation Error", "[error]");
                }

            } else {
                middleErrorToast("Validation Error", "Please select batch");
            }
            clearForm()
        }).catch((errors: any) => {
            console.log("**********", errors)
            let errorsModel: any = {}
            for (let error of errors?.inner) {
                middleErrorToast("Validation Error", error?.message);
                errorsModel[error?.path] = error?.message
                // console.log(error)
            }
            setForm('Error_msg', errorsModel);
        })
    }

    return (
        <>
            <div className="flex items-start gap-2 p-2">
                <AutoCompleteDropDown isDisabled={_.get(model, 'batch_allocation_is_disable')} label={'Bin Location'}
                                      method={GRPO_ERP_METHODS.GET_BIN_LOCATION}
                                      defaultKey={_.get(form, GRPO_BIN_ALLOCATION_FK.BIN_LOCATION)}
                                      errorMessage={_.get(form, `Error_msg.${GRPO_BIN_ALLOCATION_FK.BIN_LOCATION}`)}
                                      onChange={onSelectBinLocation} body={{whs_code: _.get(model, 'line.DfltWH')}}/>
                <TextField label="Allocated" disabled={_.get(model, 'batch_allocation_is_disable')}
                           errorMessage={_.get(form, `Error_msg.${GRPO_BIN_ALLOCATION_FK.QUANTITY}`)}
                           value={_.get(form, GRPO_BIN_ALLOCATION_FK.QUANTITY)}
                           onChange={formController} name={GRPO_BIN_ALLOCATION_FK.QUANTITY}
                           className="form-field-width"/>
                <PrimaryButton disabled={_.get(model, 'batch_allocation_is_disable')} onClick={onClickItemAdd}
                               className="bg-primary self-end !text-[12px] !h-[25px]"
                               text="Add"/>
            </div>
        </>
    )
}

export default BinLocationAllocationReceiptTable;

const binAllocationTableFormSchema: any = object({
    [GRPO_BIN_ALLOCATION_FK.BIN_LOCATION]: string().required("Please select bin location"),
    [GRPO_BIN_ALLOCATION_FK.QUANTITY]: string().required("Please enter allocated amount")
});