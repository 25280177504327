import React from "react";
import LogisticDetails from "./LogisticDetails";
import SelectedItemsTable from "./SelectedItemsTable";
import {DefaultPivot, PivotItem} from "@dladio/core-ui";

const MainPivot = () => {
    return (
        <>
            <DefaultPivot>
                <PivotItem headerText="Contents">
                    <SelectedItemsTable/>
                </PivotItem>
                <PivotItem headerText="Logistics">
                    <LogisticDetails/>
                </PivotItem>
            </DefaultPivot>
        </>
    )
}

export default MainPivot

const config = {
    header_fields: [
        {
            key: 'user',
            label: 'User',
            name: 'user'
        },
        {
            key: 'email',
            label: 'Email',
            name: 'email'
        }
    ],
    is_search_visible: true,
    height: 'sm',
    title: "Items"
}

const model = [
    {user: "A geno", email: "sample@gamil.com"},
    {user: "FD bunning", email: "sample2@gmail.com"},

]