import _ from "lodash";
import useForm from "@dladio/hooks/dist/hooks/useForm";
import {TextField} from "@fluentui/react";
import {PI_ERP_METHODS} from "../../../const/method.const";
import AutoCompleteDropDown from "./AutoCompleteDropDown/AutoCompleteDropDown";
import {PIFK, PIFK_LOGISTIC} from "../../../const/form-field.const";
import {Box, LabelOnLeft, Stack} from "@dladio/core-ui";
import {PURCHASE_INVOICE_FORM_STORE_KEYS} from "../../../const/form-store.const";

const LogisticDetails = () => {
    const {form, setForm, formController} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.LOGISTIC_FORM);
    const {form: baseForm} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.PURCHASE_INVOICE);
    const companyAddress: string = (_.get(form, 'company_address.CompnyName') ? _.get(form, 'company_address.CompnyName') : '') + ' ' + (_.get(form, 'company_address.CompnyAddr') ? _.get(form, 'company_address.CompnyAddr')?.replace(/\r/g, '') : '')

    const onChangeShippingType = (e: any, {key, text}: any) => {
        setForm(PIFK.SHIPPING_TYPE, key)
    }

    return (
        <>
            <Box className="[&>div]:h-[350px]">
                <Stack className={'flex-col p-3 gap-3'}>
                    <LabelOnLeft label="Ship To">
                        <Stack>
                            <TextField name={PIFK.SHIP_TO_ADDRESS}
                                       className="w-[286px] ring-none pt-1 disabled:text-black-800"
                                // disabled={true}
                                       multiline={true}
                                       rows={5}
                                       resizable={false}
                                // onChange={formController}
                                       value={companyAddress}
                            />
                        </Stack>
                    </LabelOnLeft>
                    <LabelOnLeft label="Pay To">
                        <Stack className={'flex-col'}>
                            {/*/!*<AutoCompleteDropDown/>*!/ //commented out future use*/}
                            <Stack className={''}>
                                <TextField name={PIFK.PAY_TO_ADDRESS}
                                           className="w-[286px] pt-1"
                                           multiline={true}
                                           rows={5}
                                           resizable={false}
                                           readOnly={true}
                                    // onChange={formController}
                                           value={`${_.get(form, PIFK_LOGISTIC.PAY_TO_ADDRESS)?.Address ?? ''} \n ${_.get(form, PIFK_LOGISTIC.PAY_TO_ADDRESS)?.Street ?? ''} \n ${_.get(form, PIFK_LOGISTIC.PAY_TO_ADDRESS)?.City ?? ''}\n ${_.get(form, PIFK_LOGISTIC.PAY_TO_ADDRESS)?.State ?? ''}\n ${_.get(form, PIFK_LOGISTIC.PAY_TO_ADDRESS)?.Country ?? ''}`}
                                />
                            </Stack>
                        </Stack>
                    </LabelOnLeft>
                    <LabelOnLeft label="Shipping Type">
                        {/*<AutoCompleteDropDown className={'w-[286px]'} method={PI_ERP_METHODS.GET_SHIPPING_TYPE}*/}
                        {/*                      onChange={onChangeShippingType}*/}
                        {/*                      defaultValue={_.get(form, PIFK.SHIPPING_TYPE)}/>*/}
                        <TextField className={"w-[286px]"} name={PIFK.TRANSPORTATION_NAME}
                                   value={_.get(baseForm, PIFK.TRANSPORTATION_NAME)}/>
                    </LabelOnLeft>
                    {/*<LabelOnLeft label="Stamp No">*/}
                    {/*    <TextField className={"w-[286px]"} name={PIFK.STAMP_NO} value={_.get(baseForm, PIFK.STAMP_NO)}/>*/}
                    {/*</LabelOnLeft>*/}
                </Stack>

            </Box>
        </>
    )
}

export default LogisticDetails