import {
    DetailsHeader,
    DetailsList,
    DetailsListLayoutMode,
    IDetailsHeaderProps,
    IDetailsListStyles,
    TextField
} from "@fluentui/react";
import _ from "lodash";
import React from "react";
import {isArray} from "@dladio/utils";
import {useForm} from "@dladio/hooks";
import {FREIGHT_FORM_KEYS, PIFK_TBL_LINE_EDIT_FORM} from "../../../../../../const/form-field.const";
import AutoCompleteDropDown from "../../../AutoCompleteDropDown/AutoCompleteDropDown";
import {PURCHASE_INVOICE_FORM_STORE_KEYS} from "../../../../../../const/form-store.const";

const PIFreightTable = () => {
    const {form, setForm}: any = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.FREIGHT_FORM)

    const onChangeHandler = ([...args]: any, model: any, index: any, column: any) => {
        switch (_.get(column, 'fieldName')) {
            case FREIGHT_FORM_KEYS.REMARK: {
                let cloned_lines = _.cloneDeep(form)
                cloned_lines[index][FREIGHT_FORM_KEYS.REMARK] = args[0]?.target?.value
                setForm(cloned_lines)
                break
            }
            case FREIGHT_FORM_KEYS.DISTRIBUTE_METHOD: {
                let cloned_lines = _.cloneDeep(form)
                cloned_lines[index][FREIGHT_FORM_KEYS.DISTRIBUTE_METHOD] = _.get(args[1], 'key')
                setForm(cloned_lines)
                break
            }
            case FREIGHT_FORM_KEYS.NET_AMOUNT: {
                let cloned_lines = _.cloneDeep(form)
                let totalTaxAmount = +((+_.get(model, 'TaxRateWithoutFormat') / 100) * (+args[0]?.target?.value)).toFixed(2)
                let grossAmount = ((+args[0]?.target?.value) + totalTaxAmount).toFixed(2)
                cloned_lines[index][FREIGHT_FORM_KEYS.NET_AMOUNT] = args[0]?.target?.value
                cloned_lines[index][FREIGHT_FORM_KEYS.TOTAL_TAX_AMOUNT] = totalTaxAmount ? totalTaxAmount : 0
                cloned_lines[index][FREIGHT_FORM_KEYS.GROSS_AMOUNT] = grossAmount ? grossAmount : 0
                setForm(cloned_lines)
                break
            }
        }
    }

    const onRenderItemColumn = (model: any, index: any, column: any) => {
        const fieldContent = _.get(model, column.fieldName)

        // if (column.fieldName === FREIGHT_FORM_KEYS.REMARK) return (
        //     <div onChange={(...args: any) => {
        //         onChangeHandler([...args], model, index, column)
        //     }} className={'cursor-pointer'}><TextField value={_.get(model, FREIGHT_FORM_KEYS.REMARK)}/></div>)

        // if (column.fieldName === FREIGHT_FORM_KEYS.DISTRIBUTE_METHOD) return (
        //     <div className={'cursor-pointer'}><AutoCompleteDropDown staticOptions={distributeMethods}
        //                                                             defaultValue={fieldContent}
        //                                                             onChange={(...args: any) => {
        //                                                                 onChangeHandler([...args], model, index, column)
        //                                                             }}
        //     />
        //     </div>
        // )

        // if (column.fieldName === FREIGHT_FORM_KEYS.NET_AMOUNT) return (
        //     <div onChange={(...args: any) => {
        //         onChangeHandler([...args], model, index, column)
        //     }} className={'cursor-pointer'}><TextField value={_.get(model, FREIGHT_FORM_KEYS.NET_AMOUNT)}/></div>
        // )

        // if (column.fieldName === FREIGHT_FORM_KEYS.GROSS_AMOUNT) return (
        //     <div onChange={(...args: any) => {
        //         onChangeHandler([...args], model, index, column)
        //     }} className={'cursor-pointer'}><TextField disabled type={"number"}
        //                                                value={_.get(model, FREIGHT_FORM_KEYS.GROSS_AMOUNT)}/></div>
        // )

        // if (column.fieldName === FREIGHT_FORM_KEYS.TOTAL_TAX_AMOUNT) return (
        //     <div className={'cursor-pointer'}><TextField value={_.get(model, FREIGHT_FORM_KEYS.TOTAL_TAX_AMOUNT)}/>
        //     </div>
        // )

        return (
            <div className={'cursor-pointer'} onClick={() => {
            }}>
                {fieldContent}
            </div>
        )
    }

    const _onRenderDetailHeader: any = (detailsHeaderProps: IDetailsHeaderProps) => {
        if (detailsHeaderProps) {
            return (
                <DetailsHeader
                    {...detailsHeaderProps}
                />
            )
        }
        return null
    }

    return (
        <div>
            <DetailsList
                items={isArray(form) ? form : []}
                columns={_.get(config, 'header_fields')}
                compact={true}
                styles={gridStyles}
                setKey="none"
                onRenderItemColumn={onRenderItemColumn}
                onRenderDetailsHeader={_onRenderDetailHeader}
                isHeaderVisible={true}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={0}
                selectionPreservedOnEmptyClick={true}
            />
        </div>
    )
}

const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        backgroundColor: 'white',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                height: '70vh',
            },
        }
    },
    headerWrapper: {
        flex: '0 0 auto'
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden'
    }
};
export default PIFreightTable

const config: any = {
    header_fields: [
        {
            key: 1,
            fieldName: FREIGHT_FORM_KEYS.EXPENSE_NAME,
            name: 'ExpnsName',
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#f0f0f0'}},
        },
        {
            key: 2,
            fieldName: FREIGHT_FORM_KEYS.REMARK,
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Remark',
        },
        {
            key: 3,
            fieldName: FREIGHT_FORM_KEYS.VAT_CODE,
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'VAT Code',
        },
        {
            key: 4,
            fieldName: FREIGHT_FORM_KEYS.TAX_RATE,
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Tax',
        },
        {
            key: 5,
            fieldName: FREIGHT_FORM_KEYS.TOTAL_TAX_AMOUNT,
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Total Tax Amount',
        },
        {
            key: 6,
            fieldName: FREIGHT_FORM_KEYS.DISTRIBUTE_METHOD,
            minWidth: 200,
            maxWidth: 200,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Distrib. Method',
        },
        {
            key: 7,
            fieldName: FREIGHT_FORM_KEYS.NET_AMOUNT,
            minWidth: 200,
            maxWidth: 200,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'NetAmount',
        },

        // {
        //     key: 8,
        //     fieldName: 'Status',
        //     minWidth: 100,
        //     maxWidth: 100,
        //     styles: {root: {backgroundColor: '#f0f0f0'}},
        //     name: 'Status',
        // },
        {
            key: 10,
            fieldName: FREIGHT_FORM_KEYS.GROSS_AMOUNT,
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Gross Amount',
        }
    ],
    is_search_visible: true,
    height: 'sm',
    title: "Items"
}

const distributeMethods: any = [
    {"key": "N", "text": "NO"},
    {"key": "Q", "text": "Quantity"},
    {"key": "T", "text": "Row Total"},
    {"key": "V", "text": "Volume"},
    {"key": "W", "text": "Weight"}
]
