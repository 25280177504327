import _ from "lodash";
import {Box} from "@dladio/core-ui"
import {POFK} from "../../../const/form-field.const";
import {useRef} from "react";
import {isArray} from "@dladio/utils";
import {exeMethod, getFormValue, setFormValue} from "../actions/actions";
import {useDispatch} from "react-redux";
import {PO_ERP_METHODS} from "../../../const/method.const";
import OnlineSearchField from "../../Common/OnlineSearchField/OnlineSearchField";
import {middleWarningToast} from "../../../service/toast.service";
import {useForm, useLocalStore} from "@dladio/hooks";
import {PrimaryButton, TextField} from "@fluentui/react"
import {PURCHASE_ORDER_STORE_PATH} from "../../../const/local-store.const";
import {PERCHASE_ORDER_FORM_STORE_KEYS} from "../../../const/form-store.const";

const ItemSelection = ({parentRef}: any) => {

    const ref: any = useRef()
    const dispatch: any = useDispatch()
    const {model, setStore} = useLocalStore(PURCHASE_ORDER_STORE_PATH.PAGE_LOADING)
    const {form, setForm, formController} = useForm(PERCHASE_ORDER_FORM_STORE_KEYS.PURCHASE_ORDER)
    const onChangeItemHandler = (e: any, key: any) => {
        setForm(POFK.SELECTED_ITEM, key)
    }

    parentRef.current = ref?.current

    const onClickItemAdd = () => {

        if (_.get(form, POFK.SELECTED_ITEM) && _.get(form, POFK.QUANTITY) > 0) {
            setStore(true)
            dispatch(exeMethod({
                method: PO_ERP_METHODS.GENERATE_ITEM_LINE,
                body: {
                    [POFK.SELECTED_ITEM]: _.get(form, POFK.SELECTED_ITEM),
                    [POFK.QUANTITY]: _.get(form, POFK.QUANTITY),
                    [POFK.DISCOUNT]: _.get(form, POFK.DISCOUNT) ? _.get(form, POFK.DISCOUNT) : 0,
                    [POFK.SUPPLIER_CODE]: _.get(form, POFK.SUPPLIER_CODE),
                }

            })).then((line: any) => {
                let currentTableLines = dispatch(getFormValue(PERCHASE_ORDER_FORM_STORE_KEYS.TABLE_LINE_FORM))
                const lines = [line, ...isArray(currentTableLines) ? currentTableLines : []]
                dispatch(setFormValue(PERCHASE_ORDER_FORM_STORE_KEYS.TABLE_LINE_FORM, '', lines))
                ref?.current?.clearSearch()
                dispatch(setFormValue(PERCHASE_ORDER_FORM_STORE_KEYS.PURCHASE_ORDER, '', {
                    ...form,
                    [POFK.SELECTED_ITEM]: '',
                    [POFK.QUANTITY]: 0,
                    [POFK.DISCOUNT]: 0
                }))

            }).catch((e: any) => {
                console.log(e)

            }).finally(() => {
                setStore(false)

            })
        } else {
            middleWarningToast('Warning', 'Please select an item and quantity')
        }
    }

    return (
        <Box className={'mt-2'}>
            <div className="flex items-end gap-2 p-2">
                <OnlineSearchField ref={ref} id={PO_ERP_METHODS.GET_ITEMS} label={'Item'}
                                   defaultKey={_.get(form, POFK.SELECTED_ITEM)}
                                   options={{method: PO_ERP_METHODS.GET_ITEMS, payload: {}}}
                                   onChange={onChangeItemHandler}/>
                <TextField type={'number'} value={_.get(form, POFK.QUANTITY)} label="Quantity" name={POFK.QUANTITY}
                           className="form-field-width"
                           onChange={formController}/>
                <TextField value={_.get(form, POFK.DISCOUNT)} label="Discount" name={POFK.DISCOUNT}
                           className="form-field-width"
                           onChange={formController}/>
                <PrimaryButton disabled={model} className="bg-primary !text-[12px] !h-[25px]" text="Add"
                               onClick={onClickItemAdd}/>
            </div>
        </Box>
    )
}

export default ItemSelection