import _ from "lodash";
import {exeMethod, getFormValue, setFormValue} from "../../actions/actions";
import {useDispatch} from "react-redux";
import {LabelOnLeft} from "@dladio/core-ui";
import {buttonStyles} from "@fluentui/react/lib/components/Nav/Nav.styles";
import {PO_ERP_METHODS} from "../../../../const/method.const";
import OnlineSearchField from "../../../Common/OnlineSearchField/OnlineSearchField";
import {POFK_EDITE_FORM} from "../../../../const/form-field.const";
import {Panel, PanelType} from '@fluentui/react/lib/Panel';
import {middleErrorToast} from "../../../../service/toast.service";
import AutoCompleteDropDown from "../AutoCompleteDropDown/AutoCompleteDropDown";
import {useForm, useLocalStore} from "@dladio/hooks";
import {PURCHASE_ORDER_STORE_PATH} from "../../../../const/local-store.const";
import {PERCHASE_ORDER_FORM_STORE_KEYS} from "../../../../const/form-store.const";
import {DefaultButton, PrimaryButton, TextField} from "@fluentui/react";

const TableLineEditPanel = () => {

    const dispatch: any = useDispatch()
    const {model, setStore} = useLocalStore(PURCHASE_ORDER_STORE_PATH.TABLE_LINE_EDIT_PANEL)
    const {setStore: setLoadingState} = useLocalStore(PURCHASE_ORDER_STORE_PATH.PAGE_LOADING)
    const {form, setForm, formController} = useForm(PERCHASE_ORDER_FORM_STORE_KEYS.EDITE_PANEL_FORM)
    const onChangeItemHandler = (e: any, key: any) => {
        setForm(POFK_EDITE_FORM.SELECTED_ITEM, key)
    }
    const onChangeWhereHouseHandler = (e: any, {key, text}: any) => {
        setForm(POFK_EDITE_FORM.WHEREHOUSE_CODE, key)
    }
    const onSaveHandler = () => {

        if (_.get(form, POFK_EDITE_FORM.SELECTED_ITEM) && _.get(form, POFK_EDITE_FORM.QUANTITY)) {
            setLoadingState(true)
            setStore('is-open', false)

            dispatch(exeMethod({
                method: PO_ERP_METHODS.GENERATE_ITEM_LINE,
                body: {
                    [POFK_EDITE_FORM.SELECTED_ITEM]: _.get(form, POFK_EDITE_FORM.SELECTED_ITEM),
                    [POFK_EDITE_FORM.QUANTITY]: _.get(form, POFK_EDITE_FORM.QUANTITY),
                    [POFK_EDITE_FORM.DISCOUNT]: _.get(form, POFK_EDITE_FORM.DISCOUNT),
                    [POFK_EDITE_FORM.SUPPLIER_CODE]: _.get(form, POFK_EDITE_FORM.SUPPLIER_CODE),
                    [POFK_EDITE_FORM.WHEREHOUSE_CODE]: _.get(form, POFK_EDITE_FORM.WHEREHOUSE_CODE)
                }

            })).then((line: any) => {
                let clonedLines: any = _.cloneDeep(dispatch(getFormValue(PERCHASE_ORDER_FORM_STORE_KEYS.TABLE_LINE_FORM)))
                clonedLines[_.get(model, 'selected-line-index')] = line
                dispatch(setFormValue(PERCHASE_ORDER_FORM_STORE_KEYS.TABLE_LINE_FORM, '', clonedLines))

            }).catch((error: any) => {
                console.log(error)
                middleErrorToast('Error', 'Item Line cannot edite')

            }).finally(() => {
                setLoadingState(false)
            })

        } else {

        }
    }

    const editMarkUp: JSX.Element = (
        <div className="pt-3 px-2 pb-1 flex flex-col gap-2">
            <LabelOnLeft label="Item Name">
                <OnlineSearchField id={PO_ERP_METHODS.GET_ITEMS}
                                   defaultKey={_.get(form, POFK_EDITE_FORM.SELECTED_ITEM)}
                                   options={{method: PO_ERP_METHODS.GET_ITEMS, payload: {}}}
                                   onChange={onChangeItemHandler}/>
            </LabelOnLeft>
            <LabelOnLeft label="Quantity">
                <TextField className="form-field-width" value={_.get(form, POFK_EDITE_FORM.QUANTITY) ?? ''}
                           name={POFK_EDITE_FORM.QUANTITY}
                           onChange={formController}/>
            </LabelOnLeft>
            <LabelOnLeft label="Discount">
                <TextField className="form-field-width" value={_.get(form, POFK_EDITE_FORM.DISCOUNT) ?? ''}
                           name={POFK_EDITE_FORM.DISCOUNT}
                           onChange={formController}/>
            </LabelOnLeft>
            <LabelOnLeft label="Wherehouse">
                <AutoCompleteDropDown method={PO_ERP_METHODS.GET_WHEREHOUSE_LIST}
                                      classNames={'form-field-width'}
                                      defaultValue={_.get(form, POFK_EDITE_FORM.WHEREHOUSE_CODE)}
                                      onChange={onChangeWhereHouseHandler}/>
            </LabelOnLeft>
        </div>
    )

    const onRenderFooterContent =
        () => (
            <div className={'flex gap-2'}>
                <PrimaryButton onClick={onSaveHandler} styles={buttonStyles}>
                    Save
                </PrimaryButton>
                <DefaultButton onClick={() => setStore('is-open', false)}>Cancel</DefaultButton>
            </div>
        )

    return (
        <div>
            <Panel
                type={PanelType.custom}
                isOpen={_.get(model, 'is-open')}
                onDismiss={() => setStore('is-open', false)}
                headerText="Edit Line"
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooterContent}
                isFooterAtBottom={true}
                customWidth={"30vw"}
            >
                {editMarkUp}
            </Panel>
        </div>
    )

}
export default TableLineEditPanel