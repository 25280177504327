import React from "react";
import {isArray} from "@dladio/utils";
import {useForm, useLocalStore} from "@dladio/hooks";
import {PURCHASE_INVOICE_STORE_PATH} from "../../../../../../const/local-store.const";
import BinLocationAllocationReceiptTable from "./BinLocationAllocationReceiptTable";
import {PURCHASE_INVOICE_FORM_STORE_KEYS} from "../../../../../../const/form-store.const";
import BinLocationAllocationReceiptTableAction from "./BinLocationAllocationReceiptTableAction";

const BinAllocation = () => {
    const {form, setForm, formController} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.BIN_LOCATION_FORM)
    const {model, setStore} = useLocalStore(PURCHASE_INVOICE_STORE_PATH.TABLE_LINE_EDIT_PANEL)

    //filter array item by SerialAndBatchNumbersBaseLine
    const line = isArray(form) ? form?.filter((item: any) => {
        if (item.SerialAndBatchNumbersBaseLine === model.selected_batch_line_index) {
            return item
        }
    }) : []

    return (
        <>
            <div>
                <BinLocationAllocationReceiptTableAction/>
                <BinLocationAllocationReceiptTable model={line}/>
            </div>
        </>
    );

}

export default BinAllocation;