import React from "react";
import {Box, LabelOnLeft} from "@dladio/core-ui";
import {TextField} from "@fluentui/react";
import {GRPO_ERP_METHODS} from "../../../const/method.const";

import {GRPOFK} from "../../../const/form-field.const";
import {useForm} from "@dladio/hooks";
import {GRPO_FORM_STORE_KEYS} from "../../../const/form-store.const";
import AutoCompleteDropDown from "../../../components/AutoCompleteDropDown/AutoCompleteDropDown";
import _ from "lodash";

const SupplierDetails: React.FC = () => {
    const {form, setForm, formController} = useForm(GRPO_FORM_STORE_KEYS.GRPO_DOCUMENT_LINE_FROM)
    const onChangeSupplier = (e: any, {key, text}: any) => {
        setForm(GRPOFK.SUPPLIER_CODE, key)
        setForm(GRPOFK.SUPPLIER_NAME, text)
    }

    const onChangeContactPerson = (e: any, {key, text}: any) => {
        setForm(GRPOFK.CONTACT_PERSON, key)
    }

    return (
        <>
            <Box>
                <div className="pt-3 px-2 pb-1 flex flex-col gap-2">
                    <LabelOnLeft label="Supplier">
                        <AutoCompleteDropDown dataTestId={"supplier-combobox"} method={GRPO_ERP_METHODS.GET_SUPPIRES}
                                              onChange={onChangeSupplier}/>
                    </LabelOnLeft>
                    <LabelOnLeft label="Contact Person">
                        <AutoCompleteDropDown dataTestId={"contact-combobox"}
                                              method={GRPO_ERP_METHODS.GET_CONTACT_PERSON}
                                              onChange={onChangeContactPerson}
                                              body={{id: _.get(form, GRPOFK.SUPPLIER_CODE)}}/>
                    </LabelOnLeft>
                    <LabelOnLeft label="Supplier Ref.No">
                        <TextField name={GRPOFK.REFNO} onChange={formController}/>
                    </LabelOnLeft>
                </div>
            </Box>
        </>
    )
}
export default SupplierDetails