import _ from "lodash";
import {POFK} from "../../../const/form-field.const";
import {isArray} from "@dladio/utils";
import {useForm, useLocalStore} from "@dladio/hooks";
import {TupleCard, TupleCardLine} from "@dladio/core-ui";
import {FontIcon, Text, TextField} from "@fluentui/react";
import {PURCHASE_ORDER_STORE_PATH} from "../../../const/local-store.const";
import {PERCHASE_ORDER_FORM_STORE_KEYS} from "../../../const/form-store.const";

const Summary = () => {
    const {setStore} = useLocalStore(PURCHASE_ORDER_STORE_PATH.FREIGHT_EDIT_PANEL)
    const {model} = useLocalStore(PURCHASE_ORDER_STORE_PATH.SUPPLIRR_CURRENCY)
    const {form: freightForm} = useForm(PERCHASE_ORDER_FORM_STORE_KEYS.FREIGHT_FORM)
    const {form} = useForm(PERCHASE_ORDER_FORM_STORE_KEYS.TABLE_LINE_FORM)
    const {
        form: purchOrderForm,
        formController: formControllerPo
    } = useForm(PERCHASE_ORDER_FORM_STORE_KEYS.PURCHASE_ORDER)

    const totalBeforeDiscount: any = isArray(form) ? form?.reduce((prevValue: any, currentValue: any) => (
        prevValue + ((+currentValue?.Quantity * currentValue?.Price) - (currentValue?.DiscountAmount ? currentValue?.DiscountAmount : 0)
        )
    ), 0) : 0

    const freight: any = isArray(freightForm) ? freightForm.reduce((prevValue: any, currentValue: any) => (
        prevValue + (currentValue?.NetAmount ? +(currentValue?.NetAmount) : 0)
    ), 0) : 0

    const freightTax = isArray(freightForm) ? freightForm.reduce((prevValue: any, currentValue: any) => (
        prevValue + (currentValue?.TotalTaxAmount ? currentValue?.TotalTaxAmount : 0)
    ), 0) : 0

    const freightWithTax = isArray(freightForm) ? freightForm.reduce((prevValue: any, currentValue: any) => (
        prevValue + (currentValue?.GrossAmount ? +(currentValue?.GrossAmount) : 0)
    ), 0) : 0

    const Tax: any = isArray(form) ? form?.reduce((prevValue: any, currentValue: any) => (prevValue + (
            currentValue?.TaxAmount ? +currentValue?.TaxAmount : 0)
    ), 0) + (freightTax ? +freightTax : 0) : 0

    const orderDiscount: any = (+(totalBeforeDiscount ?? 0) * (+(_.get(purchOrderForm, POFK.ORDER_DISCOUNT) ?? 0) / 100))
        .toFixed(2)

    const total: any = isArray(form) ? form?.reduce((prevValue: any, currentValue: any) => (
        prevValue + (currentValue?.LineTotal ? +currentValue?.LineTotal : 0)
    ), 0) + freightWithTax - orderDiscount : 0
    const onClickFreightHandler = () => {
        setStore('is-open', true)
    }

    const discountTupleLineMarkup: any = (
        <div className={'grid grid-cols-12 gx-0 border-b'}>
            <div className={'col-span-1'}>
                <Text
                    className={'font-semibold'}
                    variant={'small'}>
                    Discount
                </Text>
            </div>
            <div className={'col-span-3'}>
                <div className={'grid grid-cols-5'}>
                    <div className={'col-span-4 ml-3'}>
                        <TextField name={POFK.ORDER_DISCOUNT} value={_.get(purchOrderForm, POFK.ORDER_DISCOUNT)}
                                   onChange={formControllerPo}/>
                    </div>
                    <div className={'flex justify-center items-center align-middle'}>
                        %
                    </div>
                </div>
            </div>

            <div onClick={onClickFreightHandler}
                 className={'flex  col-span-6 flex-row gap-2'}
            >
                <Text
                    className={''}
                    variant={'small'}>
                    {`${model ?? ''} ${isNaN(orderDiscount) ? `0.00` : orderDiscount}`}
                </Text>
            </div>
        </div>
    )

    const freightTupleLineMarkup = (
        <div className={'grid grid-cols-12 gx-0  border-b'}>
            <div className={'col-span-4'}>
                <Text
                    className={'font-semibold'}
                    variant={'small'}>
                    Freight
                </Text>
            </div>
            <div onClick={onClickFreightHandler}
                 className={'flex col-span-6 flex-row gap-2'}
            >
                <Text
                    className={''}
                    variant={'small'}>
                    {`${model ?? ''} ${freight.toFixed(2) ?? 0.00}`}
                </Text>
                <FontIcon
                    onClick={() => onClickFreightHandler()} className={''}
                    style={{fontSize: 10, cursor: 'pointer'}}
                    iconName="EditSolid12"
                />
            </div>
        </div>
    )

    return (

        <TupleCard title='Summary'>
            <TupleCardLine title="Total Item"
                           value={`${isArray(form) ? form?.length : 0}`}/>
            <TupleCardLine title="Total Before Discount"
                           value={`${model ?? ''} ${(totalBeforeDiscount ? totalBeforeDiscount : 0.00)?.toFixed(2)}`}/>
            {discountTupleLineMarkup}
            {freightTupleLineMarkup}
            <TupleCardLine title="Total Tax" value={`${model ?? ''} ${Tax?.toFixed(2)}`}/>
            <TupleCardLine title="Total" value={`${model ?? ''} ${total?.toFixed(2)}`}/>
        </TupleCard>
    )
}

export default Summary