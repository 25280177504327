import {POFK} from "../../../const/form-field.const";
import Summary from "./Summary";
import {useForm} from "@dladio/hooks";
import {TextField} from "@fluentui/react";
import {Grid, LabelOnLeft} from "@dladio/core-ui";
import {PERCHASE_ORDER_FORM_STORE_KEYS} from "../../../const/form-store.const";

const ShippingAndSummaryContainer = () => {
    const {formController} = useForm(PERCHASE_ORDER_FORM_STORE_KEYS.PURCHASE_ORDER)

    return (
        <div className={'grid grid-cols-2 mt-4'}>
            <Grid className="grid-cols-3">
                <div className="col-span-2 px-2 pb-2 bg-[#f0f0f0] w-full h-full">
                    <div className="px-2 pb-2 bg-white rounded-md pt-3 h-full flex flex-col gap-2">
                        <LabelOnLeft label="Shipping Instructions">
                            <TextField name={POFK.SHIPPING_INSTRACTION} onChange={formController} className="w-[350px]"
                                       multiline rows={6}/>
                        </LabelOnLeft>
                    </div>
                </div>
            </Grid>
            <div className={'w-full'}>
                <Summary/>
            </div>
        </div>
    )
}

export default ShippingAndSummaryContainer