import _ from "lodash";
import React from "react";
import {useDispatch} from "react-redux";
import {ARCN_STORE_PATH} from "../../../../../../const/local-store.const";
import {ARCN_ERP_METHODS, GRPO_ERP_METHODS} from "../../../../../../const/method.const";
import AutoCompleteDropDown from "../../../../../PurchaseOrder/Components/AutoCompleteDropDown/AutoCompleteDropDown";
import {ARCN_FORM_STORE_KEYS} from "../../../../../../const/form-store.const";
import {useForm, useLocalStore} from "@dladio/hooks";
import {PrimaryButton, TextField} from "@fluentui/react";
import {getLocalStoreValue, setLocalStoreValue} from "../../../../actions/actions";
import {
    ARCN_CREATE_BATCH_FORM,
    GRPO_BIN_ALLOCATION_FK, SELECT_BIN_FORM
} from "../../../../../../const/form-field.const";

const SelectBins = () => {
    const dispatch: any = useDispatch()
    const {form: itemLine} = useForm(ARCN_FORM_STORE_KEYS.EDITE_PANEL_FORM)
    const {model: selectedBatchIndex} = useLocalStore(ARCN_STORE_PATH.SELECTED_BATCH_LINE)
    const {form, setForm, formController} = useForm(ARCN_FORM_STORE_KEYS.SELECT_BINS)

    const onClickHandler = async () => {

        if (!_.get(form, 'code') || _.get(form, 'Quantity', 0) <= 0 || typeof selectedBatchIndex === 'undefined' || selectedBatchIndex === '') {
            return
        }

        let batchModel = await dispatch(getLocalStoreValue(ARCN_STORE_PATH.BATCH_MODEL))
        let clonedBatchModel: any = _.cloneDeep(batchModel)

        let batchNumberExistInBatchModel = _.get(clonedBatchModel[selectedBatchIndex], 'bins', [])?.findIndex((item: any) => (
            item.code === form.code
        )) || -1

        if (batchNumberExistInBatchModel > -1) {
            return
        }

        const qty = await getValidQuantity(dispatch, form?.Quantity, selectedBatchIndex)

        if (!qty) return

        clonedBatchModel[selectedBatchIndex]["bins"] = [
            ..._.get(clonedBatchModel[selectedBatchIndex], 'bins', false) ?
                clonedBatchModel[selectedBatchIndex]["bins"] : [], {...form, Quantity: qty}
        ]

        dispatch(setLocalStoreValue(ARCN_STORE_PATH.BATCH_MODEL, '', clonedBatchModel))
    }
    const onChangeBinHandler = (id: any, keyAndText: any) => {
        setForm({
            ...form,
            [SELECT_BIN_FORM.BIN_CODE]: _.get(keyAndText, 'key'),
            [SELECT_BIN_FORM.BIN_NAME]: _.get(keyAndText, 'text')
        })
    }
    return (
        <div className="flex items-end gap-2 p-2">
            <AutoCompleteDropDown isDisabled={typeof selectedBatchIndex === 'undefined' || selectedBatchIndex === ''}
                                  label={'Bin Location'}
                                  onChange={onChangeBinHandler}
                                  method={ARCN_ERP_METHODS.GET_BINS}
                                  defaultKey={_.get(form, 'BinAbsEntry')}
                                  body={{whs_code: _.get(itemLine, 'DfltWH')}}/>
            <TextField disabled={typeof selectedBatchIndex === 'undefined' || selectedBatchIndex === ''} label="Qty"
                       value={_.get(form, ARCN_CREATE_BATCH_FORM.QTY)}
                       name={ARCN_CREATE_BATCH_FORM.QTY} onChange={formController}
                       className="form-field-width"/>
            <PrimaryButton disabled={typeof selectedBatchIndex === 'undefined' || selectedBatchIndex === ''}
                           onClick={onClickHandler}
                           className="bg-primary !text-[12px] !h-[25px]" text="Add"/>
        </div>
    )
}

const getValidQuantity = async (dispatch: any, qty: any, selectedBatchIndex: any) => {
    const batchQuantity = await dispatch(getLocalStoreValue(ARCN_STORE_PATH.BATCH_MODEL))[selectedBatchIndex]["Quantity"]
    const currentbinsQuantity = await dispatch(getLocalStoreValue(ARCN_STORE_PATH.BATCH_MODEL))[selectedBatchIndex]["bins"]?.reduce((total: any, curr: any) => (total + (+curr.Quantity)), 0) || 0
    const availableQuantity = (+batchQuantity) - currentbinsQuantity

    if (availableQuantity >= qty) return qty
    else if (qty > availableQuantity) return availableQuantity
}
export default SelectBins