export enum PO_ERP_METHODS {
    GET_SUPPIRES = 'oneaccess.purchasing.api.form.get_suppliers',
    GET_CONTACT_PERSON = 'oneaccess.purchasing.api.form.get_contact_persons',
    GET_ITEMS = 'oneaccess.purchasing.api.form.get_items',
    GENERATE_ITEM_LINE = 'oneaccess.purchasing.api.purchasing_order.generate_item_line',
    GET_WHEREHOUSE_LIST = 'oneaccess.purchasing.api.purchasing_order.get_warehouse_list',
    GET_FREIGHT = 'oneaccess.purchasing.api.purchasing_order.get_freight',
    GET_LOGISTIC = 'oneaccess.purchasing.api.purchasing_order.get_logistics_details',
    GET_SHIPPING_TYPE = 'oneaccess.purchasing.api.form.get_shipping_type',
    SAVE = 'oneaccess.purchasing.api.purchasing_order.save',
    GET_DISTRIBUTION_METHODS = 'oneaccess.purchasing.api.purchasing_order.get_distribution_methods',
    GET_INITIAL_DATA = 'oneaccess.purchasing.api.purchasing_order.get_initial_data'
}

export enum GRPO_ERP_METHODS {
    GENERATE_ITEM_LINE = 'oneaccess.purchasing.api.goods_receipt_purchase_order.generate_item_line',
    GET_SUPPIRES = 'oneaccess.purchasing.api.form.get_suppliers',
    GET_CONTACT_PERSON = 'oneaccess.purchasing.api.form.get_contact_persons',
    GET_FREIGHT = 'oneaccess.purchasing.api.goods_receipt_purchase_order.get_freight',
    GET_BIN_LOCATION = 'oneaccess.purchasing.api.goods_receipt_purchase_order.get_bin_location',
    GENERATE_GRPO = 'oneaccess.purchasing.api.goods_receipt_purchase_order.generate_grpo',
    GRPO_COPY_FROM = 'oneaccess.purchasing.api.goods_receipt_purchase_order.copy_from',
    GET_FERIGHT_DETAILS = 'oneaccess.purchasing.api.goods_receipt_purchase_order.get_freight_details'
}

export enum PI_ERP_METHODS {
    GET_GRPO = 'oneaccess.purchasing.api.purchase_invoice.get_grpo_details',
    GET_SUPPLIERS = 'oneaccess.purchasing.api.form.get_suppliers',
    GET_CONTACT_PERSON = 'oneaccess.purchasing.api.form.get_contact_persons',
    GET_ITEMS = 'oneaccess.purchasing.api.form.get_items',
    GENERATE_ITEM_LINE = 'oneaccess.purchasing.api.purchase_invoice.generate_item_line',
    GET_LOGISTIC = 'oneaccess.purchasing.api.form.get_logistics_details',
    GET_WAREHOUSE_LIST ='oneaccess.purchasing.api.purchase_invoice.get_warehouse_list',
    GET_FREIGHT ='oneaccess.purchasing.api.purchase_invoice.get_freights',
    GET_SHIPPING_TYPE = 'oneaccess.purchasing.api.form.get_shipping_type',
    GET_BIN_LOCATIONS_LIST = 'oneaccess.purchasing.api.purchase_invoice.get_bin_locations_list',
    SAVE = 'oneaccess.purchasing.api.purchase_invoice.save',
    COPY_FROM_BY_TYPE = 'oneaccess.purchasing.api.purchase_invoice.copy_from_by_type',
}

export enum ARCN_ERP_METHODS {
    GET_CUSTOMER = 'oneaccess.sales.api.form.get_customer',
    GET_CONTACT_PERSON = 'oneaccess.sales.api.form.get_contact_persons',
    GET_BATCHES = 'oneaccess.sales.api.credit_note.get_batches',
    GET_BINS = 'oneaccess.sales.api.credit_note.get_bin_location',
    GET_ITEMS = 'oneaccess.sales.api.form.get_items',
    GENERATE_ITEM_LINE = 'oneaccess.sales.api.credit_note.generate_item_line',
    GET_WHEREHOUSE_LIST = 'oneaccess.sales.api.credit_note.get_warehouse_list',
    GET_SHIPPING_TYPE = 'oneaccess.sales.api.form.get_shipping_type',
    SAVE = 'oneaccess.sales.api.credit_note.save',
    GET_DISTRIBUTION_METHODS = 'oneaccess.sales.api.credit_note.get_distribution_methods',
    GET_INITIAL_DATA = 'oneaccess.sales.api.credit_note.get_initial_data',
    GET_CREDIT_NOTE_FROM_DOCTYPE = 'oneaccess.sales.api.credit_note.get_credit_note_from_doctype'
}


