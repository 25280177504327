import _ from "lodash";
import {erpMethodApi} from "@dladio/service";
import {setFormModel} from "@dladio/hooks/dist/store/slices/form-store";
import {ARCN_ERP_METHODS} from "../../../const/method.const";
import {setLocalStoreModel} from "@dladio/hooks/dist/store/slices/local-store";
import {ARCNFK, ARCNFK_LOGISTIC} from "../../../const/form-field.const";
import {ARCN_FORM_STORE_KEYS} from "../../../const/form-store.const";

const getStateSelect = (state: any, path: any) => _.get(state, path)

export const exeMethod = ({method, body}: any) => {
    return async (dispatch: any) => {
        const {error, data}: any = await dispatch(
            erpMethodApi.endpoints.execMethodApi.initiate({
                method: method,
                body: body
            })
        );
        if (error) throw error
        return data?.message
    };
};

export const exeMutation = ({method, body}: any) => {
    return async (dispatch: any) => {
        const {error, data}: any = await dispatch(
            erpMethodApi.endpoints.execMutationApi.initiate({
                method: method,
                body: body
            })
        );
        if (error) throw error
        return data?.message
    };
};
export const getFormValue = (path: any) => {
    return (dispatch: any, getState: any) => {
        return getStateSelect(_.get(getState(), 'formStore.model'), path)
    }
}

export const setFormValue = (path: any, field: any, value: any) => {
    return (dispatch: any) => {
        if (field) dispatch(setFormModel({path: [path, field], model: value}))
        else dispatch(setFormModel({path: path, model: value}))
    }
}

export const setLocalStoreValue = (path: any, key: any, value: any) => {
    return (dispatch: any) => {
        if (key) dispatch(setLocalStoreModel({path: path + '.' + key, model: value}))
        else dispatch(setLocalStoreModel({path: path, model: value}))

    }
}

export const getLocalStoreValue = (path: any) => {
    return (dispatch: any, getState: any) => {
        return getStateSelect(_.get(getState(), 'localStore.model'), path)
    }
}

export const clearAllFieldAction = (path: any) => {
    return (dispatch: any) => {
        dispatch(setFormModel({path: path, model: {}}))
    }
}

export const clearFormAction = (path: any, fields: any, form: any) => {
    return (dispatch: any) => {
        let _form = {...form}
        for (let key of fields) _form = {..._form, [key]: ''}
        dispatch(setFormModel({path: path, model: _form}))
    }
}


export const saveHandler = (model: any) => {
    return async (dispatch: any) => {
        const {data, error}: any = await dispatch(exeMutation({
            method: ARCN_ERP_METHODS.SAVE,
            body: model
        }))

        if (error) throw error
    }
}
export const setInitialDataByType = (id: any,type:any) => {
    return async (dispatch: any) => {
        dispatch(exeMethod({
            method: ARCN_ERP_METHODS.GET_CREDIT_NOTE_FROM_DOCTYPE,
            body: {
                id,
                type
            }

        })).then((res: any) => {

            let logisticModel: {} = {
                [ARCNFK_LOGISTIC.SHIP_TO_ADDRESS]: _.get(res, 'logistic.ship_to') ?? '',
                [ARCNFK_LOGISTIC.PAY_TO_ADDRESS]: _.get(res, 'logistic.pay_to') ?? '',
                [ARCNFK_LOGISTIC.DISPLAY_ADDRESS]: _.get(res, 'logistic.company_address') ?? ''
            }
            dispatch(setFormValue(ARCN_FORM_STORE_KEYS.CREDIT_NOTE, '', _.get(res,'generalDetails')))
            dispatch(setFormValue(ARCN_FORM_STORE_KEYS.TABLE_LINE_FORM, '', _.get(res,'itemLines')))
            dispatch(setFormValue(ARCN_FORM_STORE_KEYS.LOGISTIC_FORM, '', _.get(res,'logistic')))
            dispatch(setFormValue(ARCN_FORM_STORE_KEYS.FREIGHT_FORM, '', _.get(res, 'freight')))
            dispatch(setLocalStoreValue(ARCN_FORM_STORE_KEYS.CURRENCY, '', _.get(res, 'bpCurrency')))

        }).catch((err: any) => {
            console.error(err)
        })
    }
}


