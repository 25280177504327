import BinTable from "./BinTable";
import SelectBins from "./SelectBins";
import BatchTable from "./BatchTable";
import RowDetails from "./RowDetails";
import CreateBatchForm from "./CreateBatchForm";

const BinBatchPivotItem = () => {
    return (
        <div>
            <RowDetails/>
            <CreateBatchForm/>
            <BatchTable/>
            <SelectBins/>
            <BinTable/>
        </div>
    )
}

export default BinBatchPivotItem