import {PIFK} from "../../../const/form-field.const";
import Summary from "./Summary";
import {useForm} from "@dladio/hooks";
import {TextField} from "@fluentui/react";
import {Grid, LabelOnLeft} from "@dladio/core-ui";
import {PURCHASE_INVOICE_FORM_STORE_KEYS} from "../../../const/form-store.const";
import _ from "lodash";

const ShippingAndSummary = () => {
    const {form, formController} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.PURCHASE_INVOICE)

    return (
        <div className={'grid grid-cols-2 mt-4'}>
            <Grid className="grid-cols-3">
                <div className="col-span-2 px-2 pb-2 bg-[#f0f0f0] w-full h-full">
                    <div className="px-2 pb-2 bg-white rounded-md pt-3 h-full flex flex-col gap-2">
                        <LabelOnLeft label="Shipping Instructions">
                            <TextField name={PIFK.SHIPPING_INSTRUCTION}
                                // onChange={formController}
                                       className="w-[350px]"
                                       multiline rows={6} value={_.get(form, PIFK.SHIPPING_INSTRUCTION)}/>
                        </LabelOnLeft>
                    </div>
                </div>
            </Grid>
            <div className={'w-full'}>
                <Summary/>
            </div>
        </div>
    )
}

export default ShippingAndSummary