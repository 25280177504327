import {POFK, POFK_LOGISTIC} from "../../../const/form-field.const";
import {array, object, string, addMethod} from 'yup';
import Yup from 'yup'

addMethod(string, "binBatchAllocation", function (errorMessage) {
    return this.test(`bin-batch-allocation`, errorMessage, function (value) {
        const {path, createError} = this;

        return (
            validBinBatchAlocation(value) ||
            createError({path, message: errorMessage})
        );
    });
});


let CreditNoteSchema: any = object({
    mainForm: object().shape({
        [POFK.SUPPLIER_CODE]: string().required('This field is required'),
    }),
    logistic: object().shape({
        [POFK_LOGISTIC.SHIPPING_TYPE]: string().required('This field is required'),
    }),
    itemLines: array().required("select at least one item").typeError("select at least one item").min(1, 'Select at least one item')
    // itemLines: array().test('test-name', 'Validation failure message',
    //     function (value) {
    //         validBinBatchAlocation(value)
    //     }).required('Please fill out this field')

});


export default CreditNoteSchema

const validBinBatchAlocation = (value: any) => {

    return true
}