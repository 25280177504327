import _ from "lodash";
import React, {useRef} from "react";
import {buttonStyles} from "@fluentui/react/lib/components/Nav/Nav.styles";
import {useLocalStore} from "@dladio/hooks";
import {Panel, PanelType} from "@fluentui/react/lib/Panel";
import {GRPO_FORM_STORE_KEYS} from "../../../../../const/form-store.const";
import {DefaultButton, IconButton, Pivot, PivotItem, PrimaryButton} from "@fluentui/react";
import EditDocumentLine from "./EditDocumentLine";
import BinBatchAllocation from "./BinBatchAllocation/BinBatchAllocation";
import {GRPO_STORE_PATH} from "../../../../../const/local-store.const";
import {AppDispatch} from "../../../../../store/store";
import {useDispatch} from "react-redux";
import {showConfirmDialog} from "../../../../../store/reducers/confirm-dialog";
import {middleErrorToast} from "../../../../../service/toast.service";


const BatchesSetupPanel: React.FC = () => {
    const {model, setStore} = useLocalStore(GRPO_FORM_STORE_KEYS.TABLE_LINE_EDIT_PANEL)
    const {
        setStore: setBatchSetupStore
    } = useLocalStore(GRPO_FORM_STORE_KEYS.BATCH_SETUP_TABLE_LINE)
    const {
        setStore: binAllocationSetStore
    } = useLocalStore(GRPO_STORE_PATH.BIN_ALLOCATION_TABLE)
    const pivotItemRef = useRef<any>();
    const dispatch: AppDispatch = useDispatch();

    const onCloseHandler = () => {
        dispatch(showConfirmDialog({
            title: "Confirmation",
            subtitle: "Are you sure to cancel",
            onConfirm: () => {
                try {
                    setBatchSetupStore('BatchNumbers', [])
                    setBatchSetupStore('DocumentLineBatchNumbers', [])
                    binAllocationSetStore('DocumentLinesBinAllocations', [])
                    binAllocationSetStore('selectedBatchBinAllocation', [])
                    setStore('is-open', false)
                } catch (e) {
                    middleErrorToast("Error", "Internal error")
                }
            }
        }))
    }
    const onRenderFooterContent =
        () => (
            <div className={'flex gap-2'}>
                <PrimaryButton onClick={() => {
                    pivotItemRef.current?.onSaveHandler()
                }} styles={buttonStyles}>
                    Save
                </PrimaryButton>
                <DefaultButton onClick={onCloseHandler}>Cancel</DefaultButton>
            </div>
        );

    const onRenderHeaderContent = () => (
        <div className="flex items-center px-2 justify-between">
            <p className="text-xl font-semibold">Batch - Setup</p>
            <IconButton

                iconProps={{iconName: 'Cancel'}}
                ariaLabel="Close popup modal"
                onClick={onCloseHandler}
            />
        </div>
    )
    return (
        <>
            <Panel
                type={PanelType.custom}
                isOpen={_.get(model, 'is-open')}
                headerText="Bathces - Setup"
                hasCloseButton={false}
                onRenderFooterContent={onRenderFooterContent}
                onRenderHeader={onRenderHeaderContent}
                isFooterAtBottom={true}
                customWidth={"60vw"}
            >

                <Pivot>
                    <PivotItem headerText="Batch Setup">
                        <EditDocumentLine ref={pivotItemRef}/>
                    </PivotItem>
                    <PivotItem headerText="Bin Location Allocation - Receipt">
                        <BinBatchAllocation ref={pivotItemRef}/>
                    </PivotItem>
                </Pivot>
            </Panel>
        </>
    )
}

export default BatchesSetupPanel