import { Box, DefaultCommandBar, DefaultPivot, DefaultTable, Grid, LabelOnLeft, PivotItem, TitleBar, TupleCard, TupleCardLine } from "@dladio/core-ui";
import { ComboBox, DatePicker, IComboBoxOption, PrimaryButton, SelectableOptionMenuItemType, Text, TextField } from "@fluentui/react";

const MarketingDocumentTemplate: React.FunctionComponent = () => {

    return (
        <>
            <TitleBar title="Welcome" subTitle={`Helios Marketing Document Template`} />

            {/* All actions goes here. Eg - Save, Print, Email an etc... */}
            <DefaultCommandBar actions={[{
                key: 1,
                text: 'Back',
                iconProps: { iconName: 'Back' },
                onClick: () => { alert() },
            }]} />

            <Grid>
                <Box>
                    <div className="pt-3 px-2 pb-1 flex flex-col gap-2">
                        <LabelOnLeft label="Supplier">
                            <ComboBox
                                options={options}
                            />
                        </LabelOnLeft>
                        <LabelOnLeft label="Name">
                            <TextField className="form-field-width" />
                        </LabelOnLeft>
                        <LabelOnLeft label="Contact Person">
                            <ComboBox
                                options={options}
                            />
                        </LabelOnLeft>
                        <LabelOnLeft label="Supplier Ref.No">
                            <TextField className="form-field-width" />
                        </LabelOnLeft>
                    </div>
                </Box>

                <Box>
                    <div className="pt-3 px-2 flex flex-col h-[144px]">
                        <LabelOnLeft label="Status">
                            <p className="bg-green-500 font-semibold text-white text-[10px] px-2 py-1 rounded-sm !mb-3">OPEN</p>
                        </LabelOnLeft>
                        <LabelOnLeft label="Posting Date">
                            <DatePicker
                                className="form-field-width"
                                showWeekNumbers={true}
                                firstWeekOfYear={1}
                                showMonthPickerAsOverlay={true}
                                placeholder="Select a date..."
                                ariaLabel="Select a date"
                            />
                        </LabelOnLeft>
                        <LabelOnLeft label="Delivery Date">
                            <DatePicker
                                className="form-field-width"
                                showWeekNumbers={true}
                                firstWeekOfYear={1}
                                showMonthPickerAsOverlay={true}
                                placeholder="Select a date..."
                                ariaLabel="Select a date"
                            />
                        </LabelOnLeft>
                        <LabelOnLeft label="Document Date">
                            <DatePicker
                                className="form-field-width"
                                showWeekNumbers={true}
                                firstWeekOfYear={1}
                                showMonthPickerAsOverlay={true}
                                placeholder="Select a date..."
                                ariaLabel="Select a date"
                            />
                        </LabelOnLeft>
                    </div>
                </Box>
            </Grid>

            <Box>
                <div className="flex items-end gap-2 p-2">
                    <TextField label="Item" className="form-field-width" />
                    <TextField label="Quantity" className="form-field-width" />
                    <PrimaryButton className="bg-primary !text-[12px] !h-[25px]" text="Add" />
                </div>
            </Box>

            <DefaultPivot>
                <PivotItem headerText="Contents">
                    <DefaultTable config={config} model={model} />
                </PivotItem>
            </DefaultPivot>

            <Grid className="grid-cols-3">
                <Box className="col-span-2">
                    <div className="pt-3 px-2 pb-1 flex flex-col gap-2">
                        <LabelOnLeft label="Shipping Instructions">
                            <TextField className="w-[350px]" multiline rows={6} />
                        </LabelOnLeft>
                    </div>
                </Box>

                <TupleCard title='Basic Information'>
                    <TupleCardLine title="Email" value="info@genovese.com.au" />
                    <TupleCardLine title="Website" value="www.genovese.com.au" />
                    <TupleCardLine title="Phone number" value="0393833300" />
                    <TupleCardLine title="Call Cycle Period" value="One Month and Two Weeks" />
                    <TupleCardLine title="Last Invoice Date" value="" />
                    <TupleCardLine title="Credit App Received" value="" />
                </TupleCard>

            </Grid >
        </>
    )
}

const options: IComboBoxOption[] = [
    { key: 'Header1', text: 'First heading', itemType: SelectableOptionMenuItemType.Header },
    { key: 'A', text: 'Option A' },
    { key: 'B', text: 'Option B' },
    { key: 'C', text: 'Option C' },
    { key: 'D', text: 'Option D' },
    { key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider },
    { key: 'Header2', text: 'Second heading', itemType: SelectableOptionMenuItemType.Header },
    { key: 'E', text: 'Option E' },
    { key: 'F', text: 'Option F', disabled: true },
    { key: 'G', text: 'Option G' },
    { key: 'H', text: 'Option H' },
    { key: 'I', text: 'Option I' },
    { key: 'J', text: 'Option J' },
];

const config = {
    header_fields: [
        {
            key: 'user',
            label: 'User',
            name: 'user'
        },
        {
            key: 'email',
            label: 'Email',
            name: 'email'
        }
    ],
    is_search_visible: true,
    height: 'sm',
    title: "Items"
}

const model = [
    { user: "A geno", email: "sample@gamil.com" },
    { user: "FD bunning", email: "sample2@gmail.com" },

]

export default MarketingDocumentTemplate;