import React from "react";
import {PIFK} from "../../../const/form-field.const";
import useForm from "@dladio/hooks/dist/hooks/useForm";
import {DatePicker} from "@fluentui/react";
import {Box, LabelOnLeft} from "@dladio/core-ui";
import {PURCHASE_INVOICE_FORM_STORE_KEYS} from "../../../const/form-store.const";
import _ from "lodash";

const DocumentStatus: React.FunctionComponent = () => {

    const {form, setForm, formController} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.PURCHASE_INVOICE);

    const onChangePostingDate = (value: any) => {
        setForm(PIFK.POSTING_DATE, value)
    }

    const onChangeDeliveryDate = (value: any) => {
        setForm(PIFK.DELIVERY_DATE, value)
    }

    const onChangeDocDate = (value: any) => {
        setForm(PIFK.DOCUMENT_DATE, value)
    }

    return (
        <>
            <Box>
                <div className="pt-3 px-2 pb-1 flex flex-col">
                    {/*<LabelOnLeft label="Status">*/}
                    {/*    <p className="bg-green-500 font-semibold text-white text-[10px] px-2 py-1 !mb-3 rounded-lg">OPEN</p>*/}
                    {/*</LabelOnLeft>*/}
                    <LabelOnLeft label="Posting Date">
                        <DatePicker
                            className="form-field-width"
                            showWeekNumbers={true}
                            firstWeekOfYear={1}
                            showMonthPickerAsOverlay={true}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            // onSelectDate={onChangePostingDate}
                            value={_.get(form, PIFK.POSTING_DATE) ? new Date(_.get(form, PIFK.POSTING_DATE)) : new Date()}
                        />
                    </LabelOnLeft>
                    <LabelOnLeft label="Delivery Date">
                        <DatePicker
                            className="form-field-width"
                            showWeekNumbers={true}
                            firstWeekOfYear={1}
                            showMonthPickerAsOverlay={true}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            // onSelectDate={onChangeDeliveryDate}
                            value={_.get(form, PIFK.DELIVERY_DATE) ? new Date(_.get(form, PIFK.DELIVERY_DATE)) : new Date()}
                        />
                    </LabelOnLeft>
                    <LabelOnLeft label="Document Date">
                        <DatePicker
                            className="form-field-width"
                            showWeekNumbers={true}
                            firstWeekOfYear={1}
                            showMonthPickerAsOverlay={true}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            // onSelectDate={onChangeDocDate}
                            value={_.get(form, PIFK.DOCUMENT_DATE) ? new Date(_.get(form, PIFK.DOCUMENT_DATE)) : new Date()}
                        />
                    </LabelOnLeft>
                </div>
            </Box>
        </>
    )
}

export default DocumentStatus