import React from "react";
import {Box, LabelOnLeft} from "@dladio/core-ui";
import {IComboBoxOption, SelectableOptionMenuItemType, Shimmer, TextField} from "@fluentui/react";
import useForm from "@dladio/hooks/dist/hooks/useForm";
import {PIFK} from "../../../const/form-field.const";
import {PURCHASE_INVOICE_FORM_STORE_KEYS} from "../../../const/form-store.const";
import _ from "lodash";
import {PI_ERP_METHODS} from "../../../const/method.const";
import OnlineSearchField from "../../Common/OnlineSearchField/OnlineSearchField";
import {PURCHASE_INVOICE_STORE_PATH} from "../../../const/local-store.const";
import {useLocalStore} from "@dladio/hooks";

const SupplierDetails: React.FC = () => {

    const {form, setForm, formController} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.PURCHASE_INVOICE);
    const {model, setStore} = useLocalStore(PURCHASE_INVOICE_STORE_PATH.PAGE_LOADING);

    const onChangeSupplier = (id: any, key: any) => {
        setForm(PIFK.SUPPLIER_CODE, key)
    }
    const onChangeContactPerson = (id: any, key: any) => {
        // setForm(PIFK.CONTACT_PERSON_CODE, key)
    }


    return (
        <>
            <Box>
                <div className="pt-3 px-2 pb-1 flex flex-col gap-2">
                    <LabelOnLeft label="Supplier">
                        {/*<OnlineSearchField id={PIFK.SUPPLIER_CODE} options={{method: PI_ERP_METHODS.GET_SUPPLIERS}}*/}
                        {/*                   onChange={onChangeSupplier}*/}
                        {/*                   defaultKey={_.get(form, PIFK.SUPPLIER_CODE) ?? ''}/>*/}
                        <TextField id={PIFK.SUPPLIER_CODE}
                            // onChange={onChangeSupplier}
                                   value={_.get(form, PIFK.CARD_NAME) ?? ''} className={'form-field-width'}/>
                    </LabelOnLeft>
                    <LabelOnLeft label="Contact Person">
                        <OnlineSearchField id={PIFK.CONTACT_PERSON_CODE}
                                           options={{
                                               method: PI_ERP_METHODS.GET_CONTACT_PERSON,
                                               payload: {id: _.get(form, PIFK.SUPPLIER_CODE)}
                                           }}
                                           onChange={onChangeContactPerson}
                                           defaultKey={_.get(form, PIFK.CONTACT_PERSON_CODE) ?? ''}/>

                    </LabelOnLeft>
                    <LabelOnLeft label="Supplier Ref.No">
                        <TextField name={PIFK.SUPPLIER_REF} onChange={formController} className="form-field-width"
                                   value={_.get(form, PIFK.SUPPLIER_REF)}/>
                    </LabelOnLeft>
                </div>
            </Box>
        </>
    )
}

export default SupplierDetails


const options: IComboBoxOption[] = [
    {key: 'Header1', text: 'First heading', itemType: SelectableOptionMenuItemType.Header},
    {key: 'A', text: 'Option A'},
    {key: 'B', text: 'Option B'},
    {key: 'C', text: 'Option C'},
    {key: 'D', text: 'Option D'},
    {key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider},
    {key: 'Header2', text: 'Second heading', itemType: SelectableOptionMenuItemType.Header},
    {key: 'E', text: 'Option E'},
    {key: 'F', text: 'Option F', disabled: true},
    {key: 'G', text: 'Option G'},
    {key: 'H', text: 'Option H'},
    {key: 'I', text: 'Option I'},
    {key: 'J', text: 'Option J'},
];