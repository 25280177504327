import {useDispatch, useSelector} from "react-redux";
import {DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton} from "@fluentui/react";
import {hideConfirmDialog} from "../../../store/reducers/confirm-dialog";

const ConfirmDialog = () => {

    const dispatch = useDispatch()
    const {visible, model} = useSelector((state: any) => state.confirmDialog)

    const dialogContentProps = {
        type: DialogType.normal,
        title: model?.title,
        subText: model?.subtitle,
    };

    const confirmHandler = () => {
        model?.onConfirm()
        dispatch(hideConfirmDialog())
    }

    return (
        // @ts-ignore
        <Dialog
            hidden={!visible}
            onDismiss={() => dispatch(hideConfirmDialog())}
            dialogContentProps={dialogContentProps}
        >
            <DialogFooter>
                <PrimaryButton onClick={confirmHandler} text="Yes"/>
                <DefaultButton onClick={() => dispatch(hideConfirmDialog())} text="Cancel"/>
            </DialogFooter>
        </Dialog>
    )
}

export default ConfirmDialog