import {
    Icon,
    DetailsList,
    DetailsHeader,
    IDetailsListStyles,
    IDetailsHeaderProps,
    DetailsListLayoutMode
} from "@fluentui/react";
import _ from "lodash";
import React from "react";
import {isArray} from "@dladio/utils";
import {PIFK_BATCH_LOCATION} from "../../../../../../const/form-field.const";
import {useForm, useLocalStore} from "@dladio/hooks";
import {PURCHASE_INVOICE_STORE_PATH} from "../../../../../../const/local-store.const";
import {PURCHASE_INVOICE_FORM_STORE_KEYS} from "../../../../../../const/form-store.const";

const BatchSetupTable = ({model}: any) => {
    const {form, setForm} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.BATCH_LOCATION_FORM)
    const {model: storeModel, setStore} = useLocalStore(PURCHASE_INVOICE_STORE_PATH.TABLE_LINE_EDIT_PANEL)

    const onClickCellHandler = (model: any, index: number, column: any) => {

        switch (column?.fieldName) {
            case 'Action':
                let lines = isArray(form) ? _.cloneDeep(form) : []
                lines.splice(index, 1)
                setForm(lines)
                break;
            default:
                setStore('selected_batch_line_index', index)
        }
    }

    const onRenderItemColumn = (model: any, index: any, column: any) => {
        const fieldContent = _.get(model, column.fieldName)

        if (column.fieldName === 'Action') return (
            <div className={'cursor-pointer'} onClick={() => onClickCellHandler(model, index, column)}><Icon
                iconName="Delete"/></div>)

        return (
            <div className={'cursor-pointer'} onClick={() => {
                onClickCellHandler(model, index, column)
            }}>
                {fieldContent}
            </div>
        )
    }

    const _onRenderDetailHeader: any = (detailsHeaderProps: IDetailsHeaderProps) => {
        if (detailsHeaderProps) {
            return (
                <DetailsHeader
                    {...detailsHeaderProps}
                />
            )
        }
        return null
    }

    return (
        <>
            <div className={"overflow-auto h-lg"}>
                <DetailsList
                    items={model}
                    columns={config.header_fields}
                    compact={true}
                    styles={gridStyles}
                    setKey="none"
                    onRenderItemColumn={onRenderItemColumn}
                    onRenderDetailsHeader={_onRenderDetailHeader}
                    isHeaderVisible={true}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={1}
                    selectionPreservedOnEmptyClick={true}
                />
            </div>
        </>
    )
}

export default BatchSetupTable

const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                // height: ,
            },
        }
    },
    headerWrapper: {
        flex: '0 0 auto'
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
    }
};

const config: any = {
    header_fields: [
        {
            key: 1,
            fieldName: PIFK_BATCH_LOCATION.BATCH_NUMBER,
            name: 'Batch',
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#ffffff'}},
        },
        {
            key: 2,
            fieldName: PIFK_BATCH_LOCATION.QUANTITY,
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#ffffff'}},
            name: 'Qty',
        },
        {
            key: 3,
            fieldName: 'Action',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#ffffff'}},
            name: 'Action',
        }
    ],
    is_search_visible: true,
    height: 'sm',
    title: "Items"
}