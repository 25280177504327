import _ from "lodash";
import {erpMethodApi} from "@dladio/service";
import {AppDispatch} from "../../../store/store";
import {GRPO_ERP_METHODS} from "../../../const/method.const";
import {middleErrorToast} from "../../../service/toast.service";
import {GRPO_FORM_STORE_KEYS} from "../../../const/form-store.const";
import {setFormModel} from "@dladio/hooks/dist/store/slices/form-store";
export const exeMethod = ({method, body}: any) => {
    return async (dispatch: AppDispatch) => {
        const {error, data}: any = await dispatch(
            erpMethodApi.endpoints.execMutationApi.initiate({
                method: method,
                body: body
            })
        );
        if (error) throw error
        return data?.message
    };
};

export const getFreightDetails = (id: number) => {

    return async (dispatch: AppDispatch) => {
        try {
            const {error: getFreightServiceErr, data: getFreightService}: any = await dispatch(
                erpMethodApi.endpoints.execMutationApi.initiate({
                    method: GRPO_ERP_METHODS.GET_FREIGHT
                })
            );

            if (getFreightServiceErr) throw new Error(getFreightServiceErr.message);

            const {error, data}: any = await dispatch(
                erpMethodApi.endpoints.execMutationApi.initiate({
                    method: GRPO_ERP_METHODS.GET_FERIGHT_DETAILS,
                    body: {
                        id: id
                    }
                })
            );

            if (error) throw new Error(error.message);

            const filteredFreight = _.get(getFreightService, 'message', [])
                .filter((item: any) => !_.get(data, 'message', [])
                    .some((line: any) => item.ExpnsCode === line.ExpnsCode));

            filteredFreight.push(..._.get(data, 'message'));
            dispatch(setFormModel({
                path: GRPO_FORM_STORE_KEYS.FREIGHT_FORM,
                model: filteredFreight
            }));
        } catch (e: any) {
            console.log(e);
            middleErrorToast("Internal Error", e.message);
        }
    }
}