import {
    DetailsHeader,
    DetailsList,
    DetailsListLayoutMode,
    Icon,
    IDetailsHeaderProps,
    IDetailsListStyles
} from "@fluentui/react";
import _ from "lodash";
import React from "react";
import {useForm} from "@dladio/hooks";
import {isArray} from "@dladio/utils";
import {PIFK_BIN_LOCATION} from "../../../../../../const/form-field.const";
import {PURCHASE_INVOICE_FORM_STORE_KEYS} from "../../../../../../const/form-store.const";

interface IProps {
    model: Array<any>
}

const BinLocationAllocationReceiptTable: React.FC<IProps> = ({model}: IProps) => {

    const {form, setForm} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.BIN_LOCATION_FORM)

    const onClickCellHandler = (model: any, index: number, column: any) => {

        if (column?.fieldName === 'Action') {
            let lines = isArray(form) ? _.cloneDeep(form) : []
            lines.splice(index, 1)
            setForm(lines)
        }
    }
    const onRenderItemColumn = (model: any, index: any, column: any) => {
        const fieldContent = _.get(model, column.fieldName)

        if (column.fieldName === 'Action') return (
            <div className={'cursor-pointer'} onClick={() => onClickCellHandler(model, index, column)}><Icon
                iconName="Delete"/></div>)

        return (
            <div className={'cursor-pointer'} onClick={() => {
                onClickCellHandler(model, index, column)
            }}>
                {fieldContent}
            </div>
        )
    }

    const _onRenderDetailHeader: any = (detailsHeaderProps: IDetailsHeaderProps) => {
        if (detailsHeaderProps) {
            return (
                <DetailsHeader
                    {...detailsHeaderProps}
                />
            )
        }
        return null
    }

    return (
        <>
            <div className={"overflow-auto h-lg"}>
                <DetailsList
                    items={model}
                    columns={config.header_fields}
                    compact={true}
                    styles={gridStyles}
                    setKey="none"
                    onRenderItemColumn={onRenderItemColumn}
                    onRenderDetailsHeader={_onRenderDetailHeader}
                    isHeaderVisible={true}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={0}
                    selectionPreservedOnEmptyClick={true}
                />
            </div>
        </>
    )
}

export default BinLocationAllocationReceiptTable

const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                // height: ,
            },
        }
    },
    headerWrapper: {
        flex: '0 0 auto'
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
    }
};

const config: any = {
    header_fields: [
        {
            key: 1,
            fieldName: PIFK_BIN_LOCATION.BIN_ABS_ENTRY,
            name: 'Bin Location',
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#ffffff'}},
        },
        {
            key: 2,
            fieldName: PIFK_BIN_LOCATION.QUANTITY,
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#ffffff'}},
            name: 'Allocated',
        },
        {
            key: 3,
            fieldName: 'Action',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#ffffff'}},
            name: 'Action',
        }
    ],
    is_search_visible: true,
    height: 'sm',
    title: "Items"
}