import _ from "lodash";
import {useDispatch} from "react-redux";
import PIFreightTable from "./PIFreightTable/PIFreightTable";
import {buttonStyles} from "@fluentui/react/lib/components/Nav/Nav.styles";
import {useLocalStore} from "@dladio/hooks";
import {PI_ERP_METHODS} from "../../../../../const/method.const";
import {middleErrorToast} from "../../../../../service/toast.service";
import {Panel, PanelType} from '@fluentui/react/lib/Panel';
import {exeMethod, setFormValue} from "../../../../PurchaseOrder/actions/actions";
import {PURCHASE_INVOICE_STORE_PATH} from "../../../../../const/local-store.const";
import {DefaultButton, PrimaryButton} from "@fluentui/react";
import {PURCHASE_INVOICE_FORM_STORE_KEYS} from "../../../../../const/form-store.const";

const PIFreightEditPanel = () => {

    const dispatch: any = useDispatch()
    const {model, setStore} = useLocalStore(PURCHASE_INVOICE_STORE_PATH.FREIGHT_EDIT_PANEL)

    const onClickCancelHandler = () => {
        setStore('is-open', false)
    }

    const onRenderFooterContent =
        () => (
            <div className={'flex gap-2'}>
                {/*<PrimaryButton*/}
                {/*    onClick={onSaveHandler}*/}
                {/*    styles={buttonStyles}>*/}
                {/*    Save*/}
                {/*</PrimaryButton>*/}
                <DefaultButton
                    onClick={onClickCancelHandler}
                >Close</DefaultButton>
            </div>
        )

    return (
        <div>
            <Panel
                type={PanelType.extraLarge}
                isOpen={_.get(model, 'is-open')}
                onDismiss={() => setStore('is-open', false)}
                headerText="PI Freight"
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooterContent}
                isFooterAtBottom={true}
            >
                <PIFreightTable/>
            </Panel>
        </div>
    )

}
export default PIFreightEditPanel