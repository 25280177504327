import _ from "lodash";
import useForm from "@dladio/hooks/dist/hooks/useForm";
import {TextField} from "@fluentui/react";
import {PO_ERP_METHODS} from "../../../const/method.const";

import {GRPOFK, GRPOFK_LOGISTIC} from "../../../const/form-field.const";
import {Box, LabelOnLeft, Stack} from "@dladio/core-ui";
import {GRPO_FORM_STORE_KEYS} from "../../../const/form-store.const";
import AutoCompleteDropDown from "../../../components/AutoCompleteDropDown/AutoCompleteDropDown";

const LogisticDetails = () => {

    const {form, setForm} = useForm(GRPO_FORM_STORE_KEYS.LOGISTIC_FORM);
    const {form: copToPoFom} = useForm(GRPO_FORM_STORE_KEYS.GRPO_DOCUMENT_LINE_FROM);
    const companyAddress: string = (_.get(form, 'company_address.CompnyName') ?
        _.get(form, 'company_address.CompnyName') : '') + ' ' + (_.get(form, 'company_address.CompnyAddr') ?
        _.get(form, 'company_address.CompnyAddr')?.replace(/\r/g, '') : '')
    const onChangeShippingType = (e: any, {key, text}: any) => {
        setForm(GRPOFK_LOGISTIC.SHIPPING_TYPE, key)
    }
    console.log("*****************************+++++++++++++++++++++++", _.get(copToPoFom, 'TransportationCode'))
    return (
        <>
            <Box className="[&>div]:h-[350px]">
                <Stack className={'flex-col p-3 gap-3'}>
                    <LabelOnLeft label="Ship To">
                        <Stack>
                            <TextField name={GRPOFK_LOGISTIC.SHIP_TO_ADDRESS}
                                       className="w-[286px] ring-none pt-1 disabled:text-black-800 form-field-width"
                                       multiline={true}
                                       rows={5}
                                       resizable={false}
                                       readOnly={true}
                                       value={companyAddress}
                            />
                        </Stack>
                    </LabelOnLeft>
                    <LabelOnLeft label="Pay To">
                        <Stack className={'flex-col'}>
                            <Stack className={''}>
                                <TextField name={GRPOFK_LOGISTIC.PAY_TO_ADDRESS}
                                           className="w-[286px] pt-1 form-field-width"
                                           multiline={true}
                                           rows={5}
                                           resizable={false}
                                           readOnly={true}
                                           value={`${_.get(form, GRPOFK_LOGISTIC.PAY_TO_ADDRESS)?.Address ?? ''} \n 
                                           ${_.get(form, GRPOFK_LOGISTIC.PAY_TO_ADDRESS)?.Street ?? ''} \n 
                                           ${_.get(form, GRPOFK_LOGISTIC.PAY_TO_ADDRESS)?.City ?? ''}\n 
                                           ${_.get(form, GRPOFK_LOGISTIC.PAY_TO_ADDRESS)?.State ?? ''}\n 
                                           ${_.get(form, GRPOFK_LOGISTIC.PAY_TO_ADDRESS)?.Country ?? ''}`}
                                />
                            </Stack>
                        </Stack>
                    </LabelOnLeft>
                    <LabelOnLeft label="Shipping Type">
                        <AutoCompleteDropDown method={PO_ERP_METHODS.GET_SHIPPING_TYPE}
                                              defaultKey={_.get(copToPoFom, 'TransportationCode')}
                                              body={{id: _.get(form, GRPOFK.SUPPLIER_CODE)}}
                                              onChange={onChangeShippingType}/>
                    </LabelOnLeft>
                </Stack>
            </Box>
        </>
    )
}

export default LogisticDetails