import _ from "lodash";
import {useForm} from "@dladio/hooks";
import {TextField} from "@fluentui/react";
import {useDispatch} from "react-redux";
import {LabelOnLeft} from "@dladio/core-ui";
import {ARCN_EDIT_FORM} from "../../../../../const/form-field.const";
import OnlineSearchField from "../../../../Common/OnlineSearchField/OnlineSearchField";
import {ARCN_ERP_METHODS} from "../../../../../const/method.const";
import AutoCompleteDropDown from "../../AutoCompleteDropDown/AutoCompleteDropDown";
import {ARCN_FORM_STORE_KEYS} from "../../../../../const/form-store.const";
import {useAppBridgeSelector} from "@dladio/app-bridge";

const ItemLineEditForm = () => {

    const {form, setForm, formController} = useForm(ARCN_FORM_STORE_KEYS.EDITE_PANEL_FORM)
    const {model: appBridgeModel} = useAppBridgeSelector();
    const id = _.get(appBridgeModel, 'params.id',)
    const dispatch: any = useDispatch()
    const onChangeItemHandler = (e: any, key: any, text: any) => {
        setForm('ItemName', text)
        // dispatch(setLocalStoreValue(ARCN_STORE_PATH.BATCH_MODEL, '', []))
        setForm(ARCN_EDIT_FORM.SELECTED_ITEM, key)
    }
    const onChangeWhereHouseHandler = (e: any, {key, text}: any) => {
        // dispatch(setLocalStoreValue(ARCN_STORE_PATH.BATCH_MODEL, '', []))
        setForm(ARCN_EDIT_FORM.WHEREHOUSE_CODE, key)
        setForm('WhsName', text)
    }

    const copyFromMarkUp = (
        <div className="pt-3 px-2 pb-1 flex flex-col gap-2">
            <LabelOnLeft label="Item Name">
                <TextField className="form-field-width"  disabled value={_.get(form, 'ItemName')}/>
            </LabelOnLeft>
            <LabelOnLeft label="Quantity">
                <TextField disabled className="form-field-width" value={_.get(form, ARCN_EDIT_FORM.QUANTITY)}/>
            </LabelOnLeft>
            <LabelOnLeft label="Discount">
                <TextField disabled className="form-field-width" value={_.get(form, ARCN_EDIT_FORM.DISCOUNT) ?? ''}
                           name={ARCN_EDIT_FORM.DISCOUNT}/>
            </LabelOnLeft>
            <LabelOnLeft label="Wherehouse">
                <TextField disabled className="form-field-width" value={_.get(form, 'WhsName') ?? ''}/>
            </LabelOnLeft>
        </div>
    )

    const editMarkUp: JSX.Element = (
        <div className="pt-3 px-2 pb-1 flex flex-col gap-2">
            <LabelOnLeft label="Item Name">
                <OnlineSearchField id={ARCN_ERP_METHODS.GET_ITEMS}
                                   defaultKey={_.get(form, ARCN_EDIT_FORM.SELECTED_ITEM)}
                                   options={{method: ARCN_ERP_METHODS.GET_ITEMS, payload: {}}}
                                   onChange={onChangeItemHandler}/>
            </LabelOnLeft>
            <LabelOnLeft label="Quantity">
                <TextField className="form-field-width" value={_.get(form, ARCN_EDIT_FORM.QUANTITY) ?? ''}
                           name={ARCN_EDIT_FORM.QUANTITY}
                           onChange={formController}/>
            </LabelOnLeft>
            <LabelOnLeft label="Discount">
                <TextField className="form-field-width" value={_.get(form, ARCN_EDIT_FORM.DISCOUNT) ?? ''}
                           name={ARCN_EDIT_FORM.DISCOUNT}
                           onChange={formController}/>
            </LabelOnLeft>
            <LabelOnLeft label="Wherehouse">
                <AutoCompleteDropDown method={ARCN_ERP_METHODS.GET_WHEREHOUSE_LIST}
                                      classNames={'form-field-width'}
                                      defaultValue={_.get(form, ARCN_EDIT_FORM.WHEREHOUSE_CODE)}
                                      onChange={onChangeWhereHouseHandler}/>
            </LabelOnLeft>
        </div>
    )

    return (
        <div>
            {id ? copyFromMarkUp : editMarkUp}
        </div>
    )

}
export default ItemLineEditForm