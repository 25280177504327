import _ from "lodash";
import React from "react";
import {useForm} from "@dladio/hooks";
import TupleCard from "../../../../../../components/TupleCard/TupleCard";
import TupleCardLine from "../../../../../../components/TupleCard/TupleCardLine";
import {ARCN_FORM_STORE_KEYS} from "../../../../../../const/form-store.const";

const RowDetails = () => {
    const {form} = useForm(ARCN_FORM_STORE_KEYS.EDITE_PANEL_FORM)

    return (
        <div>
            <div className="flex justify-start mt-2">
                <div className="w-6/12">
                    <TupleCard title={"Row from document"}>
                        <TupleCardLine title={"Item Code"} value={_.get(form, 'ItemCode')}/>
                        <TupleCardLine title={"Item Name"} value={_.get(form, 'ItemName')}/>
                        <TupleCardLine title={"Quantity"}
                                       value={_.get(form, 'Quantity')}/>
                        <TupleCardLine title={"Warehouse"}
                                       value={_.get(form, 'WhsName')}/>
                    </TupleCard>
                </div>
            </div>
        </div>
    )
}

export default RowDetails