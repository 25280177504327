import {
    Icon,
    DetailsList,
    DetailsHeader,
    IDetailsListStyles,
    IDetailsHeaderProps,
    DetailsListLayoutMode
} from "@fluentui/react";
import _ from "lodash";
import {Box} from "@dladio/core-ui";
import {PIFK} from "../../../const/form-field.const";
import {isArray} from "@dladio/utils";
import {useDispatch} from "react-redux";
import {setFormValue} from "../actions/actions";
import {useForm, useLocalStore} from "@dladio/hooks";
import {PURCHASE_INVOICE_STORE_PATH} from "../../../const/local-store.const";
import {PURCHASE_INVOICE_FORM_STORE_KEYS} from "../../../const/form-store.const";

const SelectedItemsTable = () => {
    const dispatch: any = useDispatch()
    const {form, setForm} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.PURCHASE_INVOICE)
    const {model, setStore} = useLocalStore(PURCHASE_INVOICE_STORE_PATH.TABLE_LINE_EDIT_PANEL)


    const onClickCellHandler = (model: any, index: number, column: any) => {

        switch (column?.fieldName) {
            case 'Action':
                let lines = isArray(_.get(form, 'DocumentLines')) ? _.cloneDeep(_.get(form, 'DocumentLines')) : []
                lines.splice(index, 1)
                setForm('DocumentLines', lines)
                break;
            default:
                setStore('is_open', true)
                setStore('selected_line_index', index)
                setStore('selected_warehouse', _.get(form, 'DocumentLines')[index].WarehouseCode)
                dispatch(setFormValue(PURCHASE_INVOICE_FORM_STORE_KEYS.TABLE_LINE_EDIT_FORM, '', model))
                dispatch(setFormValue(PURCHASE_INVOICE_FORM_STORE_KEYS.BATCH_LOCATION_FORM, '', _.get(form, 'DocumentLines')[index].BatchNumbers ?? []))
                dispatch(setFormValue(PURCHASE_INVOICE_FORM_STORE_KEYS.BIN_LOCATION_FORM, '', _.get(form, 'DocumentLines')[index]?.DocumentLinesBinAllocations ?? []))
                break;
        }
    }

    return (<Table config={config} model={_.get(form, 'DocumentLines') ?? []} onClickCellHandler={onClickCellHandler}/>)
}

const Table = ({config, model, onClickCellHandler}: any) => {

    const onRenderItemColumn = (model: any, index: any, column: any) => {
        const fieldContent = _.get(model, column.fieldName)

        if (column.fieldName === 'Action') return (
            <div className={'cursor-pointer'} onClick={() => onClickCellHandler(model, index, column)}><Icon
                iconName="Delete"/></div>)

        return (
            <div className={'cursor-pointer'} onClick={() => {
                // onClickCellHandler(model, index, column)
            }}>
                {fieldContent ?? <p className={'text-white hover:text-[#f0f0f0] cursor-pointer'}>-</p>}
            </div>
        )
    }

    const _onRenderDetailHeader: any = (detailsHeaderProps: IDetailsHeaderProps) => {
        if (detailsHeaderProps) {
            return (
                <DetailsHeader
                    {...detailsHeaderProps}
                />
            )
        }
        return null
    }

    return (
        <Box>
            <div className={"overflow-auto "}>
                <DetailsList
                    items={model}
                    columns={_.get(config, 'header_fields')}
                    compact={true}
                    styles={gridStyles}
                    setKey="none"
                    onRenderItemColumn={onRenderItemColumn}
                    onRenderDetailsHeader={_onRenderDetailHeader}
                    isHeaderVisible={true}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={0}
                    selectionPreservedOnEmptyClick={true}
                />
            </div>
        </Box>
    )
}
const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        backgroundColor: 'white',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                height: '250px',
            },
        }
    },
    headerWrapper: {
        flex: '0 0 auto'
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
    }
};

export default SelectedItemsTable

const config = {
    header_fields: [
        {
            key: 1,
            fieldName: PIFK.ITEM_CODE,
            name: 'Item Code',
            minWidth: 100,
            maxWidth: 100,
            styles: {root: {backgroundColor: '#f0f0f0'}},
        },
        {
            key: 2,
            fieldName: PIFK.ITEM_NAME,
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Item Name',
        },
        {
            key: 3,
            fieldName: PIFK.QUANTITY,
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Quantity',
        },
        {
            key: 4,
            fieldName: PIFK.DISCOUNT,
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Discount',
        },
        {
            key: 5,
            fieldName: PIFK.UNIT_PRICE,
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Unit Price',
        },
        {
            key: 6,
            fieldName: PIFK.LINE_CURRENCY,
            minWidth: 90,
            maxWidth: 90,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Currency',
        },
        {
            key: 7,
            fieldName: PIFK.WAREHOUSE_NAME,
            minWidth: 200,
            maxWidth: 200,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Warehouse',
        },

        {
            key: 8,
            fieldName: PIFK.GST_CODE,
            minWidth: 100,
            maxWidth: 100,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'GST Code',
        },
        {
            key: 9,
            fieldName: PIFK.TAX_RATE,
            minWidth: 100,
            maxWidth: 100,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'TaxRate',
        },
        {
            key: 10,
            fieldName: PIFK.LINE_TOTAL,
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Line Total',
        },
        // {
        //     key: 11,
        //     fieldName: PIFK.ACTION,
        //     minWidth: 150,
        //     maxWidth: 150,
        //     styles: {root: {backgroundColor: '#f0f0f0'}},
        //     name: 'Action',
        // }
    ],
    is_search_visible: true,
    height: 'sm',
    title: "Items"
}