import {erpMethodApi, erpResourceApi, SERVICE} from "@dladio/service";
import _ from "lodash";

interface IKeyValuePair {
    key: string,
    text: string
}

export const executeMethod = (method: any, payload: any) => {
    return async (dispatch: any) => {
        let {data} = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({
            method,
            body: payload
        }));

        return _.get(data, 'message') ? _.get(data, 'message') : []
    }
}