import _ from "lodash";
import React from "react";
import {useLocalStore} from "@dladio/hooks";
import {GRPO_STORE_PATH} from "../../../../../../const/local-store.const";
import {
    DetailsHeader,
    DetailsList,
    DetailsListLayoutMode,
    Icon,
    IDetailsHeaderProps,
    IDetailsListStyles
} from "@fluentui/react";
import {AppDispatch} from "../../../../../../store/store";
import {useDispatch} from "react-redux";
import {showConfirmDialog} from "../../../../../../store/reducers/confirm-dialog";

interface IProps {
    model: Array<any>
}

const BinLocationAllocationReceiptTable: React.FC<IProps> = ({model}) => {

    const {
        model: binAllocationModel,
        setStore: binAllocationSetStore
    } = useLocalStore(GRPO_STORE_PATH.BIN_ALLOCATION_TABLE);
    const dispatch: AppDispatch = useDispatch();
    const onClickCellHandler = (model: any, index: number, column: any) => {

        if (column?.fieldName === 'Action') {
            dispatch(showConfirmDialog({
                title: "Confirmation",
                subtitle: "Are you sure to delete this item",
                onConfirm: () => {
                    let lines: Array<any> = _.cloneDeep(_.get(binAllocationModel, 'selectedBatchBinAllocation'));
                    lines.splice(index, 1);
                    binAllocationSetStore('selectedBatchBinAllocation', lines);
                    const filteredBinAllocations: Array<any> = _.cloneDeep(_.get(binAllocationModel, 'DocumentLinesBinAllocations')).filter((item: any) => (
                        item.BinAbsEntry !== model.BinAbsEntry
                    ));
                    binAllocationSetStore('DocumentLinesBinAllocations', filteredBinAllocations);
                }
            }))
        }
    }
    const onRenderItemColumn = (model: any, index: any, column: any) => {
        const fieldContent = _.get(model, column.fieldName)

        if (column.fieldName === 'Action') return (
            <div className={'cursor-pointer'} onClick={() => onClickCellHandler(model, index, column)}><Icon
                iconName="Delete"/></div>);

        return (
            <div className={'cursor-pointer'} onClick={() => {
                onClickCellHandler(model, index, column);
            }}>
                {fieldContent}
            </div>
        )
    }

    const _onRenderDetailHeader: any = (detailsHeaderProps: IDetailsHeaderProps) => {
        if (detailsHeaderProps) {
            return (
                <DetailsHeader
                    {...detailsHeaderProps}
                />
            )
        }
        return null
    }

    return (
        <>
            <div className={"overflow-auto h-lg"}>
                <DetailsList
                    items={_.get(binAllocationModel, GRPO_STORE_PATH.SELECTED_BATCH_BIN_ALLOCATION, [])}
                    columns={config.header_fields}
                    compact={true}
                    styles={gridStyles}
                    setKey="none"
                    onRenderItemColumn={onRenderItemColumn}
                    onRenderDetailsHeader={_onRenderDetailHeader}
                    isHeaderVisible={true}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={0}
                    selectionPreservedOnEmptyClick={true}
                />
            </div>
        </>
    )
}

export default BinLocationAllocationReceiptTable

const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                // height: ,
            },
        }
    },
    headerWrapper: {
        flex: '0 0 auto'
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
    }
};

const config: any = {
    header_fields: [
        {
            key: 1,
            fieldName: 'BinAbsEntryName',
            name: 'Bin Location',
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#ffffff'}},
        },
        {
            key: 2,
            fieldName: 'Quantity',
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#ffffff'}},
            name: 'Allocated',
        },
        {
            key: 3,
            fieldName: 'Action',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#ffffff'}},
            name: 'Action',
        }
    ],
    is_search_visible: true,
    height: 'sm',
    title: "Items"
}