import React from "react";
import {DefaultPivot, PivotItem} from "@dladio/core-ui";
import DocumentSection from "./DocumentSection";
import LogisticDetails from "./LogisticDetails";


const DefaultPivotSection: React.FC = () => {

    return (
        <>
            <DefaultPivot>
                <PivotItem headerText="Contents">
                    <DocumentSection/>
                </PivotItem>
                <PivotItem headerText="Logistics">
                    <LogisticDetails/>
                </PivotItem>
            </DefaultPivot>
        </>
    )
}


export default DefaultPivotSection