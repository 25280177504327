import {
    DetailsHeader,
    DetailsList,
    DetailsListLayoutMode,
    Icon,
    IDetailsHeaderProps,
    IDetailsListStyles
} from "@fluentui/react";
import _ from "lodash";
import React from "react";
import {useLocalStore} from "@dladio/hooks";
import {GRPO_FORM_STORE_KEYS} from "../../../../../../const/form-store.const";
import {isArray} from "@dladio/utils";
import {deleteBinAllocationLines, getBatchSetupLine, getBinAllocationLines} from "./actions/actions";
import {AppDispatch} from "../../../../../../store/store";
import {useDispatch} from "react-redux";
import {GRPO_STORE_PATH} from "../../../../../../const/local-store.const";
import {middleErrorToast, middleSuccessToast} from "../../../../../../service/toast.service";
import {showConfirmDialog} from "../../../../../../store/reducers/confirm-dialog";

const BatchSetupTable = () => {

    const {model: tableLineModel, setStore} = useLocalStore(GRPO_FORM_STORE_KEYS.BATCH_SETUP_TABLE_LINE);
    const {
        setStore: binAllocationSetStore
    } = useLocalStore(GRPO_STORE_PATH.BIN_ALLOCATION_TABLE);
    const {setStore: tableLineEditPanelSetStore} = useLocalStore(GRPO_FORM_STORE_KEYS.TABLE_LINE_EDIT_PANEL);
    const dispatch: AppDispatch = useDispatch();

    const onClickCellHandler = (model: any, index: number, column: any) => {
        if (column?.fieldName === 'Action') {
            try {
                dispatch(showConfirmDialog({
                    title: "Confirmation",
                    subtitle: "Are you sure to delete this item",
                    onConfirm: () => {
                        const line: Array<any> = _.get(tableLineModel, 'BatchNumbers');
                        let lines = isArray(line) ? _.cloneDeep(line) : [];
                        lines.splice(index, 1);
                        setStore('BatchNumbers', lines);
                        dispatch(deleteBinAllocationLines(index));
                        dispatch(getBatchSetupLine());
                        tableLineEditPanelSetStore('batch_allocation_is_disable', true)
                        middleSuccessToast("Successful", "Line deleted successfully")
                    }
                }))
            } catch (e) {
                middleErrorToast("Error", "Something went wrong")
            }
        } else {
            binAllocationSetStore(GRPO_STORE_PATH.CURRENT_SELECTED_LINE, model);
            binAllocationSetStore('current_select_index', index);
            tableLineEditPanelSetStore('batch_allocation_is_disable', false);
            dispatch(getBinAllocationLines(index));
        }
    }

    const onRenderItemColumn = (model: any, index: any, column: any) => {
        const fieldContent = _.get(model, column.fieldName);

        if (column.fieldName === 'Action') return (
            <div className={'cursor-pointer'} onClick={() => onClickCellHandler(model, index, column)}><Icon
                iconName="Delete"/></div>)

        return (
            <div className={'cursor-pointer'} onClick={() => {
                onClickCellHandler(model, index, column);
            }}>
                {fieldContent}
            </div>
        )
    }

    const _onRenderDetailHeader: any = (detailsHeaderProps: IDetailsHeaderProps) => {
        if (detailsHeaderProps) {
            return (
                <DetailsHeader
                    {...detailsHeaderProps}
                />
            )
        }
        return null
    }

    return (
        <>
            <div className={"overflow-auto h-lg"}>
                <DetailsList
                    items={_.get(tableLineModel, 'DocumentLineBatchNumbers') || []}
                    columns={config.header_fields}
                    compact={true}
                    styles={gridStyles}
                    setKey="none"
                    onRenderItemColumn={onRenderItemColumn}
                    onRenderDetailsHeader={_onRenderDetailHeader}
                    isHeaderVisible={true}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={1}
                    selectionPreservedOnEmptyClick={true}
                />
            </div>
        </>
    )
}

export default BatchSetupTable

const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                // height: ,
            },
        }
    },
    headerWrapper: {
        flex: '0 0 auto'
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
    }
};

const config: any = {
    header_fields: [
        {
            key: 1,
            fieldName: 'BatchNumber',
            name: 'Batch',
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#ffffff'}},
        },
        {
            key: 2,
            fieldName: 'Quantity',
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#ffffff'}},
            name: 'Qty',
        },
        {
            key: 4,
            fieldName: 'Action',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#ffffff'}},
            name: 'Action',
        }
    ],
    is_search_visible: true,
    height: 'sm',
    title: "Items"
}