import _ from "lodash";
import {useDerive} from "@dladio/service";
import {VirtualizedComboBox} from "@fluentui/react";
import React, {useEffect, useState} from "react";

interface IProps {
    label?: string
    id: string
    onValueNotFound?: any
    onChange: any
    options: IOptions
    required?: boolean
    defaultKey?: string | number | null,
    className?: string,
    theme?: 'default' | 'table',
    disabled?: boolean
}

interface IOptions {
    method?: string
    body?: {}
}

const themes = {
    'table': {
        root: {height: '16px', fontSize: "12px", lineHeight: 'none'},
        input: {height: '12px'},
        optionsContainerWrapper: {lineHeight: 'none'}
    },
    'default': {}
}

const AutoCompleteTextField = (
    {
        id,
        options,
        label='',
        defaultKey,
        required = false,
        onChange,
        onValueNotFound,
        className,
        theme = 'default',
        disabled = false
    }: IProps) => {
    const [selectedKey, setSelectedKey] = useState<any>()

    const {value} = useDerive('exec', {
        options: options,
        path: 'message'
    })

    useEffect(() => {
        if (defaultKey) getSelectedKey()
        else setSelectedKey('')
    }, [value, defaultKey])

    const onSelect = (e: any, option: any, index?: number, value?: string) => {
        if (onValueNotFound && !option?.key) onValueNotFound(value)
        onChange(id, option?.key ?? '', option?.text ?? '',option)
        setSelectedKey(option?.key)
    }

    const getSelectedKey = () => {
        const res: any = value?.find((line: any) => line?.key == defaultKey || line?.text == defaultKey)
        setSelectedKey(_.get(res, 'key'))
    }

    const textFieldMarkup = (
        <VirtualizedComboBox
            styles={_.get(themes, theme)}
            className={className + ` ${theme == 'table' ? '[&>div]:after:content-none text-sm h-[17px]' : ''}`}
            selectedKey={selectedKey}
            label={label}
            required={required}
            allowFreeform
            autoComplete="on"
            options={value}
            onChange={onSelect}
            useComboBoxAsMenuWidth
            disabled={disabled}
        />
    )
    return (textFieldMarkup)
}

export default React.memo(AutoCompleteTextField)