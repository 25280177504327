import _ from "lodash";
import Pivot from "./Components/Pivot"
import {useForm} from "@dladio/hooks";
import {useDispatch} from "react-redux";
import ItemSelection from "./Components/ItemSelection"
import SupplierDetails from "./Components/SupplierDetails"
import {PO_ERP_METHODS} from "../../const/method.const";
import { dispatchAction } from "@dladio/app-bridge";
import {middleErrorToast} from "../../service/toast.service";
import {useEffect, useRef} from "react";
import {showConfirmDialog} from "../../store/reducers/confirm-dialog";
import purchaseOrderSchema from "./schema/schema";
import StatusAndDateDetails from "./Components/StatusAndDateDetails"
import {POFK, POFK_LOGISTIC} from "../../const/form-field.const";
import {PURCHASE_ORDER_STORE_PATH} from "../../const/local-store.const";
import ShippingAndSummaryContainer from "./Components/ShippingAndSummaryContainer";
import {PERCHASE_ORDER_FORM_STORE_KEYS} from "../../const/form-store.const";
import {DefaultCommandBar, Grid, TitleBar} from "@dladio/core-ui"
import {
    clearAllFieldAction,
    clearFormAction,
    exeMethod,
    getFormValue,
    saveHandler,
    setFormValue, setLocalStoreValue
} from "./actions/actions";

const PurchaseOrder = () => {

    const {form} = useForm(PERCHASE_ORDER_FORM_STORE_KEYS.PURCHASE_ORDER)
    const dispatch: any = useDispatch()
    const ref: any = useRef()
    const supplierRef: any = useRef()
    const supplierCode: any = _.get(form, POFK.SUPPLIER_CODE)

    useEffect(() => {
        init()
    }, [supplierCode])

    const init = () => {
        let mainForm: any = dispatch(getFormValue(PERCHASE_ORDER_FORM_STORE_KEYS.PURCHASE_ORDER))

        ref?.current?.clearSearch()

        dispatch(clearFormAction(PERCHASE_ORDER_FORM_STORE_KEYS.PURCHASE_ORDER, [
            POFK.ORDER_DISCOUNT,
            POFK.DISCOUNT,
            POFK.CONTACT_PERSON,
            POFK.QUANTITY,
            POFK.REFNO,
            POFK.SELECTED_ITEM,
            POFK.SHIPPING_INSTRACTION
        ], mainForm))
        dispatch(clearAllFieldAction(PERCHASE_ORDER_FORM_STORE_KEYS.LOGISTIC_FORM))
        dispatch(clearAllFieldAction(PERCHASE_ORDER_FORM_STORE_KEYS.TABLE_LINE_FORM))
        dispatch(clearAllFieldAction(PERCHASE_ORDER_FORM_STORE_KEYS.FREIGHT_FORM))

        dispatch(exeMethod({
            method: PO_ERP_METHODS.GET_INITIAL_DATA,
            body: {
                "supplier_id": _.get(mainForm, POFK.SUPPLIER_CODE)
            }

        })).then((res: any) => {
            let logisticModel: {} = {
                [POFK_LOGISTIC.SHIP_TO_ADDRESS]: _.get(res, 'logistic.ship_to') ?? '',
                [POFK_LOGISTIC.PAY_TO_ADDRESS]: _.get(res, 'logistic.pay_to') ?? '',
                [POFK_LOGISTIC.DISPLAY_ADDRESS]: _.get(res, 'logistic.company_address') ?? ''
            }
            dispatch(setFormValue(PERCHASE_ORDER_FORM_STORE_KEYS.LOGISTIC_FORM, '', logisticModel))
            dispatch(setFormValue(PERCHASE_ORDER_FORM_STORE_KEYS.FREIGHT_FORM, '', _.get(res, 'freight')))
            dispatch(setLocalStoreValue(PURCHASE_ORDER_STORE_PATH.SUPPLIRR_CURRENCY, '', _.get(res, 'bpCurrency')))

        }).catch((err: any) => {
            console.error(err)
        })
    }

    return (
        <div className={'bg-[#f0f0f0]'}>
            <TitleBar title="Welcome" subTitle={`Helios Marketing Document`}/>

            <DefaultCommandBar actions={[{
                key: 1,
                text: 'Back',
                iconProps: {iconName: 'Back'},
                onClick: () => {
                    dispatchAction({
                        action: 'navigate',
                        payload: -1
                    },"https://helios.dlad.io/")
                },
            },
                {
                    key: 2,
                    text: 'Save',
                    iconProps: {iconName: 'save'},
                    onClick: () => {
                        dispatch(
                            showConfirmDialog({
                                title: "Confirmation Required!",
                                subtitle: "Are you sure you want to continue with the actions",
                                onConfirm: () => {
                                    dispatch(setFormValue(PERCHASE_ORDER_FORM_STORE_KEYS.ERROR_FORM, '', {}))
                                    let model = {
                                        mainForm: dispatch(getFormValue(PERCHASE_ORDER_FORM_STORE_KEYS.PURCHASE_ORDER)),
                                        logistic: dispatch(getFormValue(PERCHASE_ORDER_FORM_STORE_KEYS.LOGISTIC_FORM)),
                                        freight: dispatch(getFormValue(PERCHASE_ORDER_FORM_STORE_KEYS.FREIGHT_FORM)),
                                        itemLines: dispatch(getFormValue(PERCHASE_ORDER_FORM_STORE_KEYS.TABLE_LINE_FORM))
                                    }

                                    purchaseOrderSchema.validate(model, {abortEarly: false}).then(() => {

                                        dispatch(saveHandler(model)).then(() => {
                                            dispatch(clearAllFieldAction(PERCHASE_ORDER_FORM_STORE_KEYS.PURCHASE_ORDER))
                                            dispatch(clearAllFieldAction(PERCHASE_ORDER_FORM_STORE_KEYS.FREIGHT_FORM))
                                            dispatch(clearAllFieldAction(PERCHASE_ORDER_FORM_STORE_KEYS.TABLE_LINE_FORM))
                                            supplierRef?.current?.clearSearch()
                                        })

                                    }).catch((errors: any) => {
                                        let errorsModel: any = {}
                                        for (let error of errors.inner) {
                                            errorsModel[error?.path] = error?.message
                                            console.log(error)
                                        }

                                        if (_.get(errorsModel, 'itemLines')) middleErrorToast('Error', 'Please select at least one item')
                                        if (_.get(errorsModel, 'logistic.shipping_type')) middleErrorToast('Error', 'Please select shipping type')
                                        dispatch(setFormValue(PERCHASE_ORDER_FORM_STORE_KEYS.ERROR_FORM, '', errorsModel))
                                    })
                                }
                            })
                        );
                    }
                }
            ]}/>

            <Grid>
                <SupplierDetails parentRef={supplierRef}/>
                <StatusAndDateDetails/>
            </Grid>

            {_.get(form, POFK.SUPPLIER_CODE) && (
                <>
                    <ItemSelection parentRef={ref}/>
                    <Pivot/>
                    <ShippingAndSummaryContainer/>
                </>
            )}

        </div>
    )
}

export default PurchaseOrder