import React from "react";
import AutoCompleteDropDown from "../../PurchaseOrder/Components/AutoCompleteDropDown/AutoCompleteDropDown";
import {GRPO_ERP_METHODS, PO_ERP_METHODS} from "../../../const/method.const";
import {PrimaryButton, TextField} from "@fluentui/react";
import {GRPOFK} from "../../../const/form-field.const";
import _ from "lodash";
import {exeMethod} from "../../PurchaseOrder/actions/actions";
import {isArray} from "@dladio/utils";
import {middleErrorToast, middleWarningToast} from "../../../service/toast.service";
import {useDispatch} from "react-redux";
import {useForm, useLocalStore} from "@dladio/hooks";
import {GRPO_STORE_PATH} from "../../../const/local-store.const";
import {GRPO_FORM_STORE_KEYS} from "../../../const/form-store.const";
import {AppDispatch} from "../../../store/store";
import {Box} from "@dladio/core-ui";
import {useAppBridgeSelector} from "@dladio/app-bridge";
// import {setSummaryDetails} from "./Panel/EditDocumentLinePanel/BinBatchAllocation/actions/actions";

const DocumentActionSection: React.FC = () => {

    const dispatch: AppDispatch = useDispatch();
    const {setStore} = useLocalStore(GRPO_STORE_PATH.PAGE_LOADING);
    const {form, setForm, formController} = useForm(GRPO_FORM_STORE_KEYS.GRPO_DOCUMENT_LINE_FROM);
    const {model} = useAppBridgeSelector();
    const onChangeItemHandler = (e: any, {key, text}: any) => {
        setForm(GRPOFK.SELECTED_ITEM, key);
    }

    const onClickItemAdd = () => {

        if (_.get(form, GRPOFK.SELECTED_ITEM) && _.get(form, GRPOFK.QUANTITY)) {
            setStore(true);
            dispatch(exeMethod({
                method: GRPO_ERP_METHODS.GENERATE_ITEM_LINE,
                body: {
                    [GRPOFK.SELECTED_ITEM]: _.get(form, GRPOFK.SELECTED_ITEM),
                    [GRPOFK.QUANTITY]: _.get(form, GRPOFK.QUANTITY),
                    [GRPOFK.DISCOUNT]: _.get(form, GRPOFK.DISCOUNT),
                    [GRPOFK.SUPPLIER_CODE]: _.get(form, GRPOFK.SUPPLIER_CODE),
                }

            })).then((line: any) => {
                const tableLines: Array<any> = _.get(form, 'lines')
                const item = _.cloneDeep(line);
                if (!_.find(tableLines, {ItemCode: line.ItemCode})) {
                    delete item.cmd;
                    item.LineNum = _.get(form, "LineNum", 0);
                    const lines = [item, ...isArray(_.get(form, 'lines')) ? _.get(form, 'lines') : []];
                    setForm('lines', lines.reverse());

                    if (_.get(form, "LineNum")) {
                        setForm('LineNum', _.get(form, "LineNum") + 1);
                    } else {
                        setForm('LineNum', 1);
                    }
                } else {
                    middleWarningToast('Warning', 'This item already exist');
                }

            }).catch((e: any) => {
                console.log(e);
                middleErrorToast('Error', 'Item Line cannot add');

            }).finally(() => {
                setStore(false);

            })
        } else {
            middleWarningToast('Warning', 'Please select an item and quantity');
        }
    }

    return (
        <>
            <Box>
                <div className="flex items-end gap-2 p-2">
                    <AutoCompleteDropDown isDisabled={!_.has(model, 'params.id')} label={'Item'}
                                          method={PO_ERP_METHODS.GET_ITEMS}
                                          onChange={onChangeItemHandler}/>
                    <TextField disabled={!_.has(model, 'params.id')} label="Quantity" name={GRPOFK.QUANTITY}
                               className="form-field-width"
                               onChange={formController}/>
                    <TextField disabled={!_.has(model, 'params.id')} label="Discount" name={GRPOFK.DISCOUNT}
                               className="form-field-width"
                               onChange={formController}/>
                    <PrimaryButton disabled={!_.has(model, 'params.id')} className="bg-primary !text-[12px] !h-[25px]"
                                   text="Add" onClick={onClickItemAdd}/>
                </div>
            </Box>
        </>
    )
}

export default DocumentActionSection