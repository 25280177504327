import {Text} from "@fluentui/react"
import {Stack} from "@dladio/core-ui";

interface IProps {
    title: string
    value: any
    className?: string
}

const TupleCardLine = ({title, value, className}: IProps) => {
    return (
        <Stack className={`bg-transparent border-b ${className}`}>
            <Stack className={"w-1/3 bg-transparent"}>
                <Text className="font-semibold" variant={'small'}>{title}</Text>
            </Stack>
            <Stack className={"flex-grow bg-transparent"}>
                <Text variant={"small"}>{value}</Text>
            </Stack>
        </Stack>
    )
}

export default TupleCardLine