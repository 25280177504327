import ItemLineEditForm from "./ItemLineEditForm";
import BinBatchPivotItem from "./BinBatchPivotItem/BinBatchPivotItem";
import {Pivot, PivotItem} from "@fluentui/react";

const PanelPivot = () => {

    return (
        <Pivot>
            <PivotItem alwaysRender={false} headerText="Edit line">
                <ItemLineEditForm/>
            </PivotItem>
            <PivotItem alwaysRender={false} headerText="Bin Location Allocation - Receipt">
                <BinBatchPivotItem/>
            </PivotItem>
        </Pivot>
    )
}

export default PanelPivot