import _ from "lodash"
import {useDispatch} from "react-redux"
import {SearchField} from "@dladio/core-ui"
import {executeMethod} from "./actions/action";
import React, {useEffect, useImperativeHandle, useRef, useState} from "react"

interface IProps {
    label?: string
    id: string
    onChange: any
    options: IOptions
    required?: boolean
    defaultKey?: string | number | null,
    keyValuePair?: { key: string, text: string }
}

interface IOptions {
    method: string
    payload?: any
}

const OnlineSearchField = React.forwardRef(({
                                                label,
                                                id,
                                                options: {method = '', payload = {}},
                                                defaultKey,
                                                required = false,
                                                onChange,
                                                keyValuePair = {key: 'key', text: 'text'}
                                            }: IProps, ref: any) => {

    const dispatch = useDispatch()
    const [list, setList] = useState([])
    const [selectedKey, setSelectedKey] = useState<any>()
    const searchFieldRef: any = useRef(null)

    useEffect(() => {
        dispatch(executeMethod(method, payload) as any).then((res: any) => {
            setList(res)
        })
    }, [method, payload])

    useEffect(() => {
        if (defaultKey) getSelectedKey()
    }, [list, defaultKey])

    useImperativeHandle(ref, () => ({
        clearSearch() {
            searchFieldRef.current?.clearFiled()
        }
    }));

    const onSelect = ({key, text}: any) => {
        onChange(id, key ?? '', text ?? '')
        setSelectedKey(key)
    }

    const getSelectedKey = () => {
        const res: any = list?.find((line: any) => line?.key === defaultKey || line?.text === defaultKey)
        setSelectedKey(_.get(res, 'key'))
    }

    const textFieldMarkup = (
        <SearchField
            ref={searchFieldRef}
            required={required}
            label={label}
            options={list}
            defaultValue={selectedKey}
            onChange={onSelect}
        />
    )
    return (<>
        {textFieldMarkup}
    </>)
})

export default React.memo(OnlineSearchField)