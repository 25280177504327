import {
    clearAllFieldAction,
    clearFormAction,
    exeMethod, exeMutation,
    getFormValue,
    setFormValue
} from "../PurchaseOrder/actions/actions";
import {
    useForm,
    useLocalStore
} from "@dladio/hooks";
import {Box, DefaultCommandBar, Grid, TitleBar,} from "@dladio/core-ui";
import {PIFK, PIFK_LOGISTIC} from "../../const/form-field.const";
import _ from "lodash";
import Pivot from "./Components/Pivot";
import {Spinner} from "@fluentui/react";
import ItemSelection from "./Components/ItemSelection";
import {useDispatch} from "react-redux";
import {saveHandler} from "./actions/actions";
import DocumentStatus from "./Components/DocumentStatus";
import {setFormModel} from "@dladio/hooks/dist/store/slices/form-store";
import SupplierDetails from "./Components/SupplierDetails";
import {PI_ERP_METHODS} from "../../const/method.const";
import {middleErrorToast} from "../../service/toast.service";
import ShippingAndSummary from "./Components/ShippingAndSummary";
import React, {useEffect} from "react";
import {showConfirmDialog} from "../../store/reducers/confirm-dialog";
import purchaseInvoiceSchema from "./Schema/schema";
import {dispatchAction, useAppBridgeSelector} from "@dladio/app-bridge";
import {PURCHASE_INVOICE_STORE_PATH} from "../../const/local-store.const";
import {PURCHASE_INVOICE_FORM_STORE_KEYS} from "../../const/form-store.const";

const PurchaseInvoice: React.FunctionComponent = () => {

    const dispatch: any = useDispatch()
    const {form, setForm} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.PURCHASE_INVOICE)
    const {model: store, setStore} = useLocalStore(PURCHASE_INVOICE_STORE_PATH.PAGE_LOADING)
    const {model}: any = useAppBridgeSelector()

    console.log('model', model)

    const documentId: any = _.get(model, ['params', 'id'])
    const documentType: any = _.get(model, ['params', 'type'])

    //commented out for now. Because it does not need for now
    // useEffect(() => {
    //     if (!documentId) {
    //         dispatch(exeMethod({
    //             method: PI_ERP_METHODS.GET_FREIGHT,
    //             body: {}
    //         })).then((data: any) => {
    //             dispatch(setFormValue(PURCHASE_INVOICE_FORM_STORE_KEYS.FREIGHT_FORM, '', data))
    //
    //         }).catch((error: any) => {
    //             console.log(error)
    //             middleErrorToast('error', 'Cannot load freight data')
    //         })
    //     }
    //
    // }, [_.get(form, PIFK.SUPPLIER_CODE)])


    useEffect(() => {
        if (!documentId) {
            let mainForm: any = dispatch(getFormValue(PURCHASE_INVOICE_FORM_STORE_KEYS.PURCHASE_INVOICE))
            dispatch(clearFormAction(PURCHASE_INVOICE_FORM_STORE_KEYS.PURCHASE_INVOICE, [PIFK.ORDER_DISCOUNT, PIFK.DISCOUNT, PIFK.CONTACT_PERSON_CODE, PIFK.QUANTITY,  PIFK.SELECTED_ITEM, PIFK.SHIPPING_INSTRUCTION, 'lines'], mainForm))
            dispatch(clearAllFieldAction(PURCHASE_INVOICE_FORM_STORE_KEYS.LOGISTIC_FORM))

            dispatch(exeMethod({
                method: PI_ERP_METHODS.GET_LOGISTIC,
                body: {
                    "supplier_id": _.get(mainForm, PIFK.SUPPLIER_CODE)
                }

            })).then((res: any) => {
                dispatch(setFormValue(PURCHASE_INVOICE_FORM_STORE_KEYS.LOGISTIC_FORM, PIFK_LOGISTIC.SHIP_TO_ADDRESS, _.get(res, 'ship_to') ?? ''))
                dispatch(setFormValue(PURCHASE_INVOICE_FORM_STORE_KEYS.LOGISTIC_FORM, PIFK_LOGISTIC.PAY_TO_ADDRESS, _.get(res, 'pay_to') ?? ''))
                dispatch(setFormValue(PURCHASE_INVOICE_FORM_STORE_KEYS.LOGISTIC_FORM, PIFK_LOGISTIC.DISPLAY_ADDRESS, _.get(res, 'company_address') ?? ''))

            }).catch((err: any) => {
                console.error(err)
            })
        }

    }, [_.get(form, PIFK.SUPPLIER_CODE)])

    useEffect(() => {
        setStore('loading', true)
        if (documentId && documentType) {
            dispatch(exeMutation(
                {
                    method: PI_ERP_METHODS.COPY_FROM_BY_TYPE,
                    body: {
                        "id": documentId,
                        "type": documentType
                    }
                }
            )).then((data: any) => {
                setStore('loading', false)
                formMapper(data, dispatch)
            }).catch((error: any) => {
                setStore('loading', false)
                console.log(error)
            })
        }
    }, [documentId])


    return (
        <div className={'bg-[#f0f0f0]'}>
            <TitleBar title="Purchase Invoice" subTitle={`AP`}/>
            {/* All actions goes here. Eg - Save, Print, Email an etc... */}
            <DefaultCommandBar
                actions={[
                    {
                        key: 1,
                        text: "Back",
                        iconProps: {iconName: "Back"},
                        onClick: () => {
                            dispatchAction({
                                action: 'navigate',
                                payload: -1
                            },"https://helios.dlad.io/")
                        },
                    },
                    {
                        key: 2,
                        text: "Save",
                        iconProps: {iconName: "Save"},
                        onClick: () => {
                            dispatch(
                                showConfirmDialog({
                                    title: "Confirmation Required",
                                    subtitle: "Are you sure you want to save?",
                                    onConfirm: () => {
                                        let model: any = {
                                            mainForm: dispatch(getFormValue(PURCHASE_INVOICE_FORM_STORE_KEYS.PURCHASE_INVOICE)),
                                            logisticForm: dispatch(getFormValue(PURCHASE_INVOICE_FORM_STORE_KEYS.LOGISTIC_FORM)),
                                            freightForm: dispatch(getFormValue(PURCHASE_INVOICE_FORM_STORE_KEYS.FREIGHT_FORM)),
                                        }
                                        purchaseInvoiceSchema.validate(form, {abortEarly: false}).then(() => {
                                            dispatch(saveHandler(model)).then((data: any) => {
                                                dispatch(clearAllFieldAction(PURCHASE_INVOICE_FORM_STORE_KEYS.PURCHASE_INVOICE))
                                                dispatch(clearAllFieldAction(PURCHASE_INVOICE_FORM_STORE_KEYS.LOGISTIC_FORM))
                                                dispatch(clearAllFieldAction(PURCHASE_INVOICE_FORM_STORE_KEYS.FREIGHT_FORM))
                                            }).catch((err: any) => {
                                                console.error(err)
                                            })
                                        }).catch((err: any) => {
                                            console.error(err)
                                            middleErrorToast('error', 'Please fill the required fields')
                                        })

                                    },
                                })
                            )

                        }
                    }
                ]}
            />
            {store?.loading ? <Box>
                    <div className={'h-[80vh] bg-white pt-52'}>
                        <Spinner label={'Processing...'} className={'m-auto'}/>
                    </div>
                </Box>
                : <>
                    <Grid>
                        <SupplierDetails/>
                        <DocumentStatus/>
                    </Grid>
                    {/*<ItemSelection/>*/}
                    <Pivot/>
                    <ShippingAndSummary/>
                </>}

        </div>
    );
};

export default PurchaseInvoice;


const formMapper = (data: any, dispatch: any) => {
    const DocumentLines: any = _.get(data, 'DocumentLines', [])
    const additionalCharges: any = _.get(data, 'DocumentAdditionalExpenses', [])
    const addressObject: any = _.get(data, 'AddressExtension', {})

    dispatch(setFormValue(PURCHASE_INVOICE_FORM_STORE_KEYS.LOGISTIC_FORM, '', addressObject))
    dispatch(setFormModel({path: PURCHASE_INVOICE_FORM_STORE_KEYS.PURCHASE_INVOICE, model: _.get(data, 'base_form')}))
    dispatch(setFormValue(PURCHASE_INVOICE_FORM_STORE_KEYS.PURCHASE_INVOICE, 'DocumentLines', _.cloneDeep(DocumentLines)))
    dispatch(setFormValue(PURCHASE_INVOICE_FORM_STORE_KEYS.FREIGHT_FORM, '', additionalCharges))
}