import _ from "lodash";
import {POFK} from "../../../const/form-field.const";
import {useForm} from "@dladio/hooks";
import {useEffect} from "react";
import {DatePicker} from "@fluentui/react"
import {Box, LabelOnLeft} from "@dladio/core-ui"
import {PERCHASE_ORDER_FORM_STORE_KEYS} from "../../../const/form-store.const";

const StatusAndDateDetails = () => {
    const {form, setForm} = useForm(PERCHASE_ORDER_FORM_STORE_KEYS.PURCHASE_ORDER)
    const onChangeDateHandler = (key: any, value: any) => {
        setForm(key, value)
    }

    useEffect(() => {
        setForm(POFK.POSTING_DATE, new Date())
        setForm(POFK.DELIVERY_DATE, new Date())
        setForm(POFK.TAXDATE, new Date())

    }, [])

    return (
        <Box>
            <div className="pt-3 px-2 flex flex-col h-[110px]">
                <LabelOnLeft label="Posting Date">
                    <DatePicker
                        onSelectDate={(value) => {
                            onChangeDateHandler(POFK.POSTING_DATE, value)
                        }}
                        value={_.get(form, POFK.POSTING_DATE)}
                        className="form-field-width"
                        showWeekNumbers={true}
                        firstWeekOfYear={1}
                        showMonthPickerAsOverlay={true}
                        placeholder="Select a date..."
                        ariaLabel="Select a date"
                    />
                </LabelOnLeft>
                <LabelOnLeft label="Delivery Date">
                    <DatePicker
                        onSelectDate={(value) => {
                            onChangeDateHandler(POFK.DELIVERY_DATE, value)
                        }}
                        className="form-field-width"
                        value={_.get(form, POFK.DELIVERY_DATE)}
                        showWeekNumbers={true}
                        firstWeekOfYear={1}
                        showMonthPickerAsOverlay={true}
                        placeholder="Select a date..."
                        ariaLabel="Select a date"
                    />
                </LabelOnLeft>
                <LabelOnLeft label="Document Date">
                    <DatePicker
                        onSelectDate={(value) => {
                            onChangeDateHandler(POFK.TAXDATE, value)
                        }}
                        className="form-field-width"
                        value={_.get(form, POFK.TAXDATE)}
                        showWeekNumbers={true}
                        firstWeekOfYear={1}
                        showMonthPickerAsOverlay={true}
                        placeholder="Select a date..."
                        ariaLabel="Select a date"
                    />
                </LabelOnLeft>
            </div>
        </Box>
    )
}

export default StatusAndDateDetails

