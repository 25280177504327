import _ from "lodash";
import React from "react";
import {isArray} from "@dladio/utils";
import {useLocalStore} from "@dladio/hooks";
import {ARCN_STORE_PATH} from "../../../../../../const/local-store.const";
import {
    DetailsHeader,
    DetailsList,
    DetailsListLayoutMode,
    IDetailsHeaderProps,
    IDetailsListStyles,
    TextField
} from "@fluentui/react";

const DownPaymentTable = () => {

    const {model}: any = useLocalStore(ARCN_STORE_PATH.DOWN_PAYMENT)
    const {model: selectedDownPayments, setStore}: any = useLocalStore(ARCN_STORE_PATH.SELECTED_DOWN_PAYMENT)
    const allDownPayments = _.get(model, 'model', [])

    const onItemInvokedHandler: any = (item: any, index: any) => {
        let clonedSelectedDownPaymentModel = _.cloneDeep(selectedDownPayments ? selectedDownPayments : [])
        let alreadyExsist = clonedSelectedDownPaymentModel.findIndex((line: any) => (line?.DocEntry === item?.DocEntry))
        if (alreadyExsist === -1) setStore([{...clonedSelectedDownPaymentModel, ...item}])
    }
    const onRenderItemColumn = (model: any, index: any, column: any) => {
        const fieldContent = _.get(model, column.fieldName)

        if (column.fieldName === 'TotalTaxAmount') return (
            <div className={'cursor-pointer'}><TextField disabled value={_.get(model, 'TotalTaxAmount')}/></div>
        )

        return (
            <div className={'cursor-pointer'} onClick={() => {
            }}>
                {fieldContent}
            </div>
        )
    }

    const _onRenderDetailHeader: any = (detailsHeaderProps: IDetailsHeaderProps) => {
        if (detailsHeaderProps) {
            return (
                <DetailsHeader
                    {...detailsHeaderProps}
                />
            )
        }
        return null
    }

    return (
        <div>
            <DetailsList
                items={isArray(allDownPayments) ? allDownPayments : []}
                columns={_.get(config, 'header_fields')}
                compact={true}
                onItemInvoked={onItemInvokedHandler}
                styles={gridStyles}
                setKey="none"
                onRenderItemColumn={onRenderItemColumn}
                onRenderDetailsHeader={_onRenderDetailHeader}
                isHeaderVisible={true}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={2}
                selectionPreservedOnEmptyClick={true}
            />
        </div>
    )
}

const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        backgroundColor: 'white',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                height: '30vh',
            },
        }
    },
    headerWrapper: {
        flex: '0 0 auto'
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden'
    }
};
export default DownPaymentTable

const config: any = {
    header_fields: [
        {
            key: 1,
            fieldName: 'DocNumber',
            name: 'Document Number',
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#f0f0f0'}},
        },
        {
            key: 2,
            fieldName: 'Details',
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Details',
        },
        {
            key: 3,
            fieldName: 'Tax',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Tax',
        },
        {
            key: 4,
            fieldName: 'AmountToDrawFormatted',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Amount To Draw',
        },
        {
            key: 5,
            fieldName: 'GrossAmountToDrawFormatted',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Gross Amount To Draw',
        }
    ],
    is_search_visible: true,
    height: 'sm',
    title: "Items"
}
