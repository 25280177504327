import Box from "../Box/Box";


interface IProps {
    children: any
    title: string
}

const TupleCard = ({children, title}: IProps) => {
    return (
        <Box
            className={"[&>div]:border-t-2 [&>div]:border-t-[#0078d4] [&>div]:border-l-gray-300 [&>div]:border-l-[1px] " +
                "[&>div]:border-r-gray-300 [&>div]:border-r-[1px] [&>div]:border-b-gray-300 [&>div]:border-b-[1px]"}
            title={title}>
            {children}
        </Box>
    )
}

export default TupleCard