import React from "react";
import {useForm, useLocalStore} from "@dladio/hooks";
import {PrimaryButton, TextField} from "@fluentui/react";
import {PURCHASE_INVOICE_STORE_PATH} from "../../../../../../const/local-store.const";
import {PURCHASE_INVOICE_FORM_STORE_KEYS} from "../../../../../../const/form-store.const";
import {PIFK, PIFK_BATCH_LOCATION} from "../../../../../../const/form-field.const";
import _ from "lodash";
import {isArray} from "@dladio/utils";
import {clearAllFieldAction} from "../../../../../PurchaseOrder/actions/actions";
import {useDispatch} from "react-redux";

const BatchSetupTableAction: React.FC = () => {
    const {form: mainForm} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.PURCHASE_INVOICE)
    const {model, setStore} = useLocalStore(PURCHASE_INVOICE_STORE_PATH.TABLE_LINE_EDIT_PANEL)
    const {
        form: batchForm,
        formController: batchFormController,
        setForm: setBatchForm
    } = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.BATCH_LOCATION_FORM)
    const {form, setForm, formController} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.TEMP_ADD_BATCH_FORM)

    const dispatch: any = useDispatch()

    const onAddHandler = () => {
        const line = _.get(mainForm, `DocumentLines[${_.get(model, 'selected_line_index')}]`)

        const batch = {
            [PIFK_BATCH_LOCATION.BATCH_NUMBER]: _.get(form, PIFK_BATCH_LOCATION.BATCH_NUMBER),
            [PIFK_BATCH_LOCATION.QUANTITY]: _.get(form, PIFK_BATCH_LOCATION.QUANTITY),
            [PIFK_BATCH_LOCATION.ITEM_CODE]: _.get(line, 'ItemCode'),
            [PIFK_BATCH_LOCATION.BASE_LINE_NUMBER]: _.get(model, 'selected_line_index')
        }

        const batches = [...isArray(batchForm) ? batchForm : [], batch]
        setBatchForm(batches)
        dispatch(clearAllFieldAction(PURCHASE_INVOICE_FORM_STORE_KEYS.TEMP_ADD_BATCH_FORM))
    }


    return (
        <>
            <div className="flex items-end gap-2 p-2">
                <TextField label={"Batch"} name={'BatchNumber'} className="form-field-width"
                           onChange={formController} value={_.get(form, PIFK_BATCH_LOCATION.BATCH_NUMBER) ?? null}/>
                <TextField label="Qty" name={PIFK_BATCH_LOCATION.QUANTITY} className="form-field-width" type={'text'}
                           onChange={formController} value={_.get(form, PIFK_BATCH_LOCATION.QUANTITY) ?? null}/>
                <PrimaryButton className="bg-primary !text-[12px] !h-[25px]" text="Add" onClick={onAddHandler}/>
            </div>
        </>
    )
}

export default BatchSetupTableAction