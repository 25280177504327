import { Box, DefaultCommandBar, DefaultPivot, DefaultTable, Grid, PivotItem, Stack, StatCard, StatCardList, TitleBar, TupleCard, TupleCardLine } from "@dladio/core-ui";
import env from '../../../package.json'

const Template1 = () => {
    return (
        <>
            <TitleBar title="Welcome" subTitle={`Helios core theme v${env?.dependencies?.["@dladio/core-ui"]?.replace('^', '')}`} />
            <DefaultCommandBar actions={[{
                key: 1,
                text: 'Back',
                iconProps: { iconName: 'Back' },
                onClick: () => { alert() },
            }]} />
            <StatCardList title="General Overview" className={'grid grid-cols-6'} >
                <StatCard label='Business Partners' subtitle="Owned Business Partners" value={234} direction={'p'} color={'#106ebe'} />
                <StatCard label='New Business Partners' subtitle="2022 - 8" value={4234} direction={'n'} color={'#008272'} />
                <StatCard label='New Business Partners' subtitle="2022 Quarter - 3" value={565} direction={'p'} color={'#6264a7'} />
                <StatCard label='New Business Partners' subtitle="2022" value={111} direction={'p'} color={'#742774'} />
                <StatCard label='Business Partners' subtitle="August 2022" value={7987} direction={'n'} color={'#217346'} />
                <StatCard label='Overdue Tasks' subtitle="For user" value={234} direction={'p'} color={'#7719aa'} />
            </StatCardList>
            <DefaultPivot>
                <PivotItem headerText="Tuple Cards">
                    <Grid>
                        <TupleCard title='Account information'>
                            <TupleCardLine title="Account Owner" value="Pauline" />
                            <TupleCardLine title="BP Code" value="10178" />
                            <TupleCardLine title="BP Name" value="A Genovese & Sons Pty Ltd" />
                            <TupleCardLine title="Customer Type" value="Domestic Distributor" />
                            <TupleCardLine title="Freight Type" value="Pick up / Walk In" />
                            <TupleCardLine title="Created Date" value="01-10-2012" />
                        </TupleCard>
                        <TupleCard title='Basic Information'>
                            <TupleCardLine title="Email" value="info@genovese.com.au" />
                            <TupleCardLine title="Website" value="www.genovese.com.au" />
                            <TupleCardLine title="Phone number" value="0393833300" />
                            <TupleCardLine title="Call Cycle Period" value="One Month and Two Weeks" />
                            <TupleCardLine title="Last Invoice Date" value="" />
                            <TupleCardLine title="Credit App Received" value="" />
                        </TupleCard>
                    </Grid>
                </PivotItem>
                <PivotItem headerText="Default Table">
                    <DefaultCommandBar actions={[{
                        key: 1,
                        text: 'New',
                        iconProps: { iconName: 'Add' },
                        onClick: () => { alert() },
                    }]} />
                    <DefaultTable config={config} model={model} />
                </PivotItem>
            </DefaultPivot>
            <Stack>
                <Box title="Stack">
                    This is a text
                </Box>
                <Box title="Stack">
                    This is a text
                </Box>
                <Box title="Stack">
                    This is a text
                </Box>
            </Stack>
        </ >
    );
}


const config = {
    header_fields: [{
        key: 'user',
        label: 'User',
        name: 'user'
    }],
    is_search_visible: true,
    height: 'md',
    title: "Business Partners"
}

const model = [
    { user: "A geno", email: "keminda@" },
    { user: "FD bunning", email: "kupae@" },

]

export default Template1