import _ from "lodash";
import React from "react";
import {useForm} from "@dladio/hooks";
import {useDispatch} from "react-redux";
import {ARCN_STORE_PATH} from "../../../../../../const/local-store.const";
import {ARCN_ERP_METHODS} from "../../../../../../const/method.const";
import AutoCompleteTextField from "../../../../../../components/AutoCompleteTextField/AutoCompleteTextField";
import {ARCN_FORM_STORE_KEYS} from "../../../../../../const/form-store.const";
import {ARCN_CREATE_BATCH_FORM} from "../../../../../../const/form-field.const";
import {PrimaryButton, TextField} from "@fluentui/react";
import {getFormValue, getLocalStoreValue, setLocalStoreValue} from "../../../../actions/actions";

const CreateBatchForm = () => {
    const dispatch: any = useDispatch()
    const {form, setForm, formController} = useForm(ARCN_FORM_STORE_KEYS.CREATE_BATCH)
    const {form: itemDetails} = useForm(ARCN_FORM_STORE_KEYS.EDITE_PANEL_FORM)
    const onClickItemAdd = async () => {

        if (!_.get(form, ARCN_CREATE_BATCH_FORM.BATCH_NAME) || _.get(form, ARCN_CREATE_BATCH_FORM.QTY, 0) <= 0) {
            return
        }

        let currentBatchModel: any = (await dispatch(getLocalStoreValue(ARCN_STORE_PATH.BATCH_MODEL))) || []
        const clonedBatchModel: any = _.cloneDeep(currentBatchModel)

        let batchNumberExsistInBatchModel = currentBatchModel.findIndex((item: any) => (
            item.BatchNumber === form.BatchNumber
        ))

        if (batchNumberExsistInBatchModel < 0) {
            let qty = await getValidQuantity(dispatch, +_.get(form, ARCN_CREATE_BATCH_FORM.QTY), +_.get(form, ARCN_CREATE_BATCH_FORM.AVAILABLE_QTY))

            if (!qty) return

            if (_.isArray(clonedBatchModel)) {
                let newModel: any = [{...form, Quantity: qty}, ...clonedBatchModel]
                dispatch(setLocalStoreValue(ARCN_STORE_PATH.BATCH_MODEL, '', newModel))
            } else {
                dispatch(setLocalStoreValue(ARCN_STORE_PATH.BATCH_MODEL, '', [{...form, Quantity: qty}]))
            }
        }
    }

    const onChangeBatchHandler = (id: any, key: any, text: any, option: any) => {
        setForm(id, key)
        setForm(ARCN_CREATE_BATCH_FORM.AVAILABLE_QTY, _.get(option, 'BatchQuantity'))
    }

    return (
        <>
            <div className="flex items-end gap-2 p-2">
                <div className={'space-y-1'}>
                    <AutoCompleteTextField
                        className={'w-[280px]'}
                        label={'Select Batch'}
                        defaultKey={_.get(form, ARCN_CREATE_BATCH_FORM.BATCH_NAME)}
                        id={ARCN_CREATE_BATCH_FORM.BATCH_NAME}
                        onChange={onChangeBatchHandler}
                        options={{
                            method: ARCN_ERP_METHODS.GET_BATCHES,
                            body: {
                                whs_code: _.get(itemDetails, 'DfltWH'),
                                item_code: _.get(itemDetails, 'ItemCode'),
                                bp_code: _.get(itemDetails, 'CardCode')
                            }
                        }}
                    />
                </div>
                <TextField label="Available Qty" value={_.get(form, ARCN_CREATE_BATCH_FORM.AVAILABLE_QTY)}
                           name={'avilableQty'} disabled={true}
                           className="form-field-width"/>
                <TextField type={'number'} label="Qty" value={_.get(form, ARCN_CREATE_BATCH_FORM.QTY, 0)}
                           name={ARCN_CREATE_BATCH_FORM.QTY} onChange={formController}
                           className="form-field-width"/>
                <PrimaryButton className="bg-primary !text-[12px] !h-[25px]" onClick={onClickItemAdd} text="Add"/>
            </div>
        </>
    )
}

const getValidQuantity = async (dispatch: any, qty: any, availableBatchQty: any) => {
    const itemQuantity = await dispatch(getFormValue(ARCN_FORM_STORE_KEYS.EDITE_PANEL_FORM))["Quantity"]
    const currentBatchQuantity = await dispatch(getLocalStoreValue(ARCN_STORE_PATH.BATCH_MODEL))?.reduce((total: any, curr: any) => (total + (+curr.Quantity)), 0) || 0
    const itemavailableQuantity = (+itemQuantity) - currentBatchQuantity

    if (itemavailableQuantity >= qty) {
        if (availableBatchQty <= qty) {
            dispatch(setLocalStoreValue(ARCN_FORM_STORE_KEYS.CREATE_BATCH, ARCN_CREATE_BATCH_FORM.AVAILABLE_QTY, 0))
            return availableBatchQty
        } else {
            dispatch(setLocalStoreValue(ARCN_FORM_STORE_KEYS.CREATE_BATCH, ARCN_CREATE_BATCH_FORM.AVAILABLE_QTY, (availableBatchQty - qty)))
            return qty
        }
    } else if (qty > itemavailableQuantity) {
        if (itemavailableQuantity >= availableBatchQty) {
            dispatch(setLocalStoreValue(ARCN_FORM_STORE_KEYS.CREATE_BATCH, ARCN_CREATE_BATCH_FORM.AVAILABLE_QTY, 0))
            return availableBatchQty
        } else {
            dispatch(setLocalStoreValue(ARCN_FORM_STORE_KEYS.CREATE_BATCH, ARCN_CREATE_BATCH_FORM.AVAILABLE_QTY, (availableBatchQty - itemavailableQuantity)))
            return itemavailableQuantity
        }
    }
}
export default CreateBatchForm