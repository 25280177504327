import _ from "lodash";
import {ARCNFK} from "../../../const/form-field.const";
import {isArray} from "@dladio/utils";
import {useDispatch} from "react-redux";
import {LoadingToast} from "../../../service/toast.service";
import {ARCN_STORE_PATH} from "../../../const/local-store.const";
import {ARCN_FORM_STORE_KEYS} from "../../../const/form-store.const";
import {useForm, useLocalStore} from "@dladio/hooks";
import {getLocalStoreValue, setFormValue, setLocalStoreValue} from "../actions/actions";
import {
    DetailsListLayoutMode,
    DetailsHeader,
    IDetailsHeaderProps,
    IDetailsListStyles, DetailsList, Icon
} from "@fluentui/react";

const SelectedItemsTable = () => {

    const dispatch: any = useDispatch()
    const {model} = useLocalStore(ARCN_STORE_PATH.PAGE_LOADING)
    const {form, setForm} = useForm(ARCN_FORM_STORE_KEYS.TABLE_LINE_FORM)
    const {setStore} = useLocalStore(ARCN_STORE_PATH.TABLE_LINE_EDIT_PANEL)
    const onClickCellHandler = (model: any, index: number, column: any) => {
        if (column?.fieldName === 'Action') {
            let lines = isArray(form) ? _.cloneDeep(form) : []
            lines.splice(index, 1)
            setForm(lines)
            setBatches(dispatch, _.get(model, 'LineNum'))

        } else {
            dispatch(setLocalStoreValue(ARCN_STORE_PATH.BATCH_MODEL, '', _.get(model, 'batchModel')))
            dispatch(setLocalStoreValue(ARCN_STORE_PATH.BIN_BATCH_ALLOCATION_PANEL, 'is-open', true))
            dispatch(setFormValue(ARCN_FORM_STORE_KEYS.EDITE_PANEL_FORM, '', {...model}))
            dispatch(setLocalStoreValue(ARCN_STORE_PATH.SELECTED_LINE_NUMBER, '', _.get(model, 'LineNum')))
            setStore("selected-line-index", index)
        }
    }

    return (
        <>
            {
                !model ? <Table config={config} model={form ?? []}
                                onClickCellHandler={onClickCellHandler}/> :
                    <LoadingToast className={'w-full h-[300px] bg-white'}/>
            }
        </>
    )
}

const Table = ({config, model, onClickCellHandler}: any) => {

    const onRenderItemColumn = (model: any, index: any, column: any) => {
        const fieldContent = _.get(model, column.fieldName)

        if (column.fieldName === 'BinBatchAllocation') return (
            <div className={'cursor-pointer'} onClick={() => onClickCellHandler(model, index, column)}><Icon
                className={''}
                iconName="ArrowUpRight8"/></div>)

        if (column.fieldName === 'Action') return (
            <div className={'cursor-pointer'} onClick={() => onClickCellHandler(model, index, column)}><Icon
                iconName="Delete"/></div>)

        if (column.fieldName === "DisplayPrice") {
            const price: any = _.get(model, 'Price')
            if (price === '0' || price === 0) return (
                <div
                    className={'text-red-500 flex flex-row gap-2 font-bold cursor-pointer'}
                    onClick={() => {
                        onClickCellHandler(model, index, column)
                    }}>
                    {fieldContent}
                </div>

            )
            else return (
                <div
                    className={`cursor-pointer`}
                    onClick={() => {
                        onClickCellHandler(model, index, column)
                    }}>
                    {fieldContent}
                </div>
            )

        }

        return (
            <div className={'cursor-pointer'} onClick={() => {
                onClickCellHandler(model, index, column)
            }}>
                {fieldContent}
            </div>
        )
    }

    const _onRenderDetailHeader: any = (detailsHeaderProps: IDetailsHeaderProps) => {
        if (detailsHeaderProps) {
            return (
                <DetailsHeader
                    {...detailsHeaderProps}
                />
            )
        }
        return null
    }

    return (
        <div className={"overflow-auto px-2"}>
            <DetailsList
                items={model}
                columns={_.get(config, 'header_fields')}
                compact={true}
                styles={gridStyles}
                setKey="none"
                onRenderItemColumn={onRenderItemColumn}
                onRenderDetailsHeader={_onRenderDetailHeader}
                isHeaderVisible={true}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={0}
                selectionPreservedOnEmptyClick={true}
            />
        </div>
    )
}
const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        backgroundColor: 'white',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                height: '40vh',
            },
        }
    },
    headerWrapper: {
        flex: '0 0 auto'
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden'
    }
};

export default SelectedItemsTable


const config = {
    header_fields: [
        {
            key: 1,
            fieldName: ARCNFK.SELECTED_ITEM,
            name: 'Item Code',
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#f0f0f0'}},
        },
        {
            key: 2,
            fieldName: 'ItemName',
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Item Name',
        },
        {
            key: 3,
            fieldName: 'Quantity',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Quantity',
        },
        {
            key: 4,
            fieldName: 'discount',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Discount(%)',
        },
        {
            key: 5,
            fieldName: 'DisplayPrice',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Unit Price',
        },
        {
            key: 11,
            fieldName: 'Currency',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Currency',
        },
        {
            key: 6,
            fieldName: 'WhsName',
            minWidth: 200,
            maxWidth: 200,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Warehouse',
        },

        {
            key: 7,
            fieldName: 'GSTCode',
            minWidth: 100,
            maxWidth: 100,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'GST Code',
        },
        {
            key: 8,
            fieldName: 'TaxRate',
            minWidth: 100,
            maxWidth: 100,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Tax Rate(%)',
        },
        {
            key: 9,
            fieldName: 'DisplayLineTotal',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Line Total',
        },
        {
            key: 10,
            fieldName: 'Action',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Action',
        }
    ],
    is_search_visible: true,
    height: 'sm',
    title: "Items"
}

const setBatches = (dispatch: any, lineNumber: any) => {
    let currantBatches = dispatch(getLocalStoreValue(ARCN_STORE_PATH.BATCH_MODEL))
    let newBatchModel = []
    if (_.isArray(currantBatches)) {
        newBatchModel = currantBatches.filter((item: any) => (item.LINE_NO !== lineNumber))
    }
    dispatch(setLocalStoreValue(ARCN_STORE_PATH.BATCH_MODEL, '', newBatchModel))
}