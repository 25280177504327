import _ from "lodash";
import React from "react";
import TupleCard from "../../../../../../components/TupleCard/TupleCard";
import TupleCardLine from "../../../../../../components/TupleCard/TupleCardLine";
import BatchSetupTable from "./BatchSetupTable";
import BatchSetupTableAction from "./BatchSetupTableAction";
import {useForm, useLocalStore} from "@dladio/hooks";
import {PURCHASE_INVOICE_STORE_PATH} from "../../../../../../const/local-store.const";
import {PURCHASE_INVOICE_FORM_STORE_KEYS} from "../../../../../../const/form-store.const";

const BatchAllocation: React.FC = ({}) => {

    const {form, setForm, formController} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.BATCH_LOCATION_FORM)
    const {form: mainForm} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.PURCHASE_INVOICE)
    const {model, setStore} = useLocalStore(PURCHASE_INVOICE_STORE_PATH.TABLE_LINE_EDIT_PANEL)

    const line = _.get(mainForm, `DocumentLines[${_.get(model, 'selected_line_index')}]`)

    return (
        <>
            <div className="flex justify-start mt-2">
                <div className="w-6/12">
                    <TupleCard title={"Row from document"}>
                        <TupleCardLine title={"Item Number"} value={_.get(line, 'ItemCode')}/>
                        <TupleCardLine title={"Item Name"} value={_.get(line, 'ItemDescription')}/>
                        <TupleCardLine title={"Warehouse Code"} value={_.get(line, 'WarehouseCode')}/>
                        <TupleCardLine title={"Total Needed"}
                                       value={_.get(line, 'Quantity')}/>
                    </TupleCard>
                </div>
            </div>
            <div>
                <BatchSetupTableAction/>
                <BatchSetupTable model={form}/>
            </div>
        </>
    )
}

export default BatchAllocation