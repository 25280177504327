import {exeMutation} from "../../PurchaseOrder/actions/actions";
import {erpMethodApi} from "@dladio/service";
import {setFormModel} from "@dladio/hooks/dist/store/slices/form-store";
import {PI_ERP_METHODS} from "../../../const/method.const";

export const exeMethod = ({method, body}: any) => {
    return async (dispatch: any) => {
        const {error, data}: any = await dispatch(
            erpMethodApi.endpoints.execMutationApi.initiate({
                method: method,
                body: body
            })
        );
        if (error) throw error
        return data?.message
    };
};

export const setFormValue = (path: any, field: any, value: any) => {
    return (dispatch: any) => {
        if (field) dispatch(setFormModel({path: [path, field], model: value}))
        else dispatch(setFormModel({path: path, model: value}))
    }
}

export const saveHandler = (model: any) => {
    return async (dispatch: any) => {
        const {error, data} = await dispatch(exeMutation({
            method: PI_ERP_METHODS.SAVE,
            body: model
        }))
        if (error) throw error
        return data?.message
    }
}