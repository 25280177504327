export enum PURCHASE_ORDER_STORE_PATH {
    PAGE_LOADING = 'page-loading',
    TABLE_LINE_EDIT_PANEL = 'PO-table-line-edite-panel',
    FREIGHT_EDIT_PANEL = 'freight-edit-panel',
    SUPPLIRR_CURRENCY ='supplier_currency'
}

export enum PURCHASE_INVOICE_STORE_PATH {
    PAGE_LOADING = 'pi-page-loading',
    TABLE_LINE_EDIT_PANEL = 'pi-table-line-edite-panel',
    FREIGHT_EDIT_PANEL = 'pi-freight-edit-panel',
}

export enum GRPO_STORE_PATH {
    PAGE_LOADING = 'page-loading',
    FREIGHT_EDIT_PANEL = 'GRPO_freight_edit_panel',
    //****Parent****//
    BIN_ALLOCATION_TABLE = 'GRPO_bin_allocation_table',
    //----child key----//
    DOCUMENT_LINE_BIN_LOCATIONS = 'DocumentLinesBinAllocations',
    CURRENT_SELECTED_LINE = 'current_selected_line',
    SELECTED_BATCH_BIN_ALLOCATION = 'selectedBatchBinAllocation',
    //--------//
    FREIGHT_DETAILS = 'freight_details'
}

export enum ARCN_STORE_PATH {
    PAGE_LOADING = 'page-loading',
    TABLE_LINE_EDIT_PANEL = 'ar-credit-note-table-line-edite-panel',
    FREIGHT_EDIT_PANEL = 'ar-freight-edit-panel',
    DOWN_PAYMENT = 'ar-down-payment-edit-panel',
    SELECTED_DOWN_PAYMENT = 'ar-selected-down-payment',
    BIN_BATCH_ALLOCATION_PANEL = 'bin-batch-allocation-panel',
    SUPPLIRR_CURRENCY ='supplier_currency',
    BATCH_MODEL = 'ar-cn-batch-model',
    LINE_NO = 'LineNum',
    SELECTED_LINE_NUMBER = 'selected-item-LineNum',
    SELECTED_BATCH_LINE = 'selected-batch-LineNum'
}