import React from "react";
import {Box, LabelOnLeft} from "@dladio/core-ui";
import {DatePicker} from "@fluentui/react";
import {useForm} from "@dladio/hooks";
import {GRPO_FORM_STORE_KEYS} from "../../../const/form-store.const";
import {GRPOFK} from "../../../const/form-field.const";
import _ from "lodash";

const StatusAndDateSection: React.FC = () => {

    const {form, setForm} = useForm(GRPO_FORM_STORE_KEYS.GRPO_DOCUMENT_LINE_FROM)

    const onChangePostingDate = (value: any) => {
        setForm(GRPOFK.POSTING_DATE, value)
    }

    const onChangeDeliveryDate = (value: any) => {
        setForm(GRPOFK.DELIVERY_DATE, value)
    }

    const onChangeDocDate = (value: any) => {
        setForm(GRPOFK.DOCUMENT_DATE, value)
    }

    return (
        <>
            <Box>
                <div className="pt-3 px-2 flex flex-col">
                    {/*<LabelOnLeft label="Status">*/}
                    {/*    <p className="bg-green-500 font-semibold text-white text-[10px] px-2 py-1 rounded-sm !mb-3">OPEN</p>*/}
                    {/*</LabelOnLeft>*/}
                    <LabelOnLeft label="Posting Date">
                        <DatePicker
                            className="form-field-width"
                            showWeekNumbers={true}
                            onSelectDate={onChangePostingDate}
                            value={_.get(form, GRPOFK.POSTING_DATE) ? new Date(_.get(form, GRPOFK.POSTING_DATE)) : undefined}
                            firstWeekOfYear={1}
                            showMonthPickerAsOverlay={true}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                        />
                    </LabelOnLeft>
                    <LabelOnLeft label="Delivery Date">
                        <DatePicker
                            className="form-field-width"
                            onSelectDate={onChangeDeliveryDate}
                            value={_.get(form, GRPOFK.DELIVERY_DATE) ? new Date(_.get(form, GRPOFK.DELIVERY_DATE)) : undefined}
                            showWeekNumbers={true}
                            firstWeekOfYear={1}
                            showMonthPickerAsOverlay={true}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                        />
                    </LabelOnLeft>
                    <LabelOnLeft label="Document Date">
                        <DatePicker
                            className="form-field-width"
                            showWeekNumbers={true}
                            onSelectDate={onChangeDocDate}
                            value={_.get(form, GRPOFK.DOCUMENT_DATE) ? new Date(_.get(form, GRPOFK.DOCUMENT_DATE)) : undefined}
                            firstWeekOfYear={1}
                            showMonthPickerAsOverlay={true}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                        />
                    </LabelOnLeft>
                </div>
            </Box>
        </>
    )
}

export default StatusAndDateSection