import RouterConfigs from "./routes/routes.config";
import ConfirmDialog from "./pages/Common/ConfirmDialog/ConfirmDialog";
import {BrowserRouter} from "react-router-dom";
import {CoreUIProvider} from "@dladio/core-ui";
import FreightEditPanel from "./pages/PurchaseOrder/Components/Panel/FreightEditPanel";
import {FreightEditPanel as ArCnFreightEditPanel} from "./pages/ARCrediteNote/Components/Panel/FreightEditPanel";
import PIFreightEditPanel from "./pages/PurchaseInvoice/Components/Panels/PIFreightEditPanel/PIFreightEditPanel";
import EditTableLinePanel from "./pages/PurchaseInvoice/Components/Panels/EditTableLinePanel/EditTableLinePanel";
import TableLineEditPanel from "./pages/PurchaseOrder/Components/Panel/TableLineEditPanel";
import {AppBridgeProvider} from "@dladio/app-bridge"
import GRPOFreightEditPanel
    from "./pages/GoodsReceiptPurchaseOrder/component/Panel/GRPOFreightEditPanel/GRPOFreightEditPanel";
import BatchesSetupPanel
    from "./pages/GoodsReceiptPurchaseOrder/component/Panel/EditDocumentLinePanel/BatchesSetupPanel";
import BinBatchAllocationPanel from "./pages/ARCrediteNote/Components/Panel/BinBatchAllocation/BinBatchAllocationPanel";
import DownPaymentPanel from "./pages/ARCrediteNote/Components/Panel/DownPaymentPanel/DownPaymentPanel";


function App() {
    return (
        <AppBridgeProvider apiKey="mdt">
            <CoreUIProvider>
                <BrowserRouter>
                    <RouterConfigs/>
                </BrowserRouter>
                <BatchesSetupPanel/>
                <TableLineEditPanel/>
                <EditTableLinePanel/>
                <FreightEditPanel/>
                <ConfirmDialog/>
                <PIFreightEditPanel/>
                <GRPOFreightEditPanel/>
                <BinBatchAllocationPanel/>
                <FreightEditPanel/>
                <ArCnFreightEditPanel/>
                <DownPaymentPanel/>
            </CoreUIProvider>
        </AppBridgeProvider>
    );
}

export default App;
