import _ from "lodash";
import {isArray} from "@dladio/utils";
import {useForm, useLocalStore} from "@dladio/hooks";
import {TupleCard, TupleCardLine} from "@dladio/core-ui";
import {FontIcon, Text, TextField} from "@fluentui/react";
import {GRPO_STORE_PATH} from "../../../const/local-store.const";
import {GRPO_FORM_STORE_KEYS} from "../../../const/form-store.const";
import {GRPOFK} from "../../../const/form-field.const";
import {useEffect} from "react";

const Summary = () => {
    const {setStore} = useLocalStore(GRPO_STORE_PATH.FREIGHT_EDIT_PANEL)
    const {form: freightForm} = useForm(GRPO_FORM_STORE_KEYS.FREIGHT_FORM)
    const {form, setForm, formController} = useForm(GRPO_FORM_STORE_KEYS.GRPO_DOCUMENT_LINE_FROM)

    const freight: any = isArray(freightForm) ? freightForm.reduce((total: any, current: any) => {
        if (current.NetAmount) {
            let num = current.NetAmount
            num = num.replace(/\,/g, '')
            return total + Number(num)
        } else {
            return total + 0;
        }
    }, 0) : 0
    const totalBeforeDiscount: any = isArray(_.get(form, 'lines')) ?
        _.sumBy(_.get(form, 'lines'), 'LineTotal') : 0;

    const freightTax = isArray(freightForm) ? freightForm.reduce((prevValue: any, currentValue: any) => {
        console.log(currentValue)
        if (currentValue.TotalTaxAmount) {
            let num = currentValue.TotalTaxAmount
            num = num.replace(/\,/g, '')
            return prevValue + Number(num)
        } else {
            return prevValue + 0;
        }
    }, 0) : 0;

    const freightWithTax = isArray(freightForm) ? freightForm.reduce((prevValue: any, currentValue: any) => {
        // prevValue + (currentValue?.GrossAmount ? (currentValue?.GrossAmount) : 0)
        if (currentValue.GrossAmount) {
            let num = currentValue.GrossAmount
            num = num.replace(/\,/g, '')
            return prevValue + Number(num)
        } else {
            return prevValue + 0;
        }
    }, 0) : 0;

    const tax: any = isArray(_.get(form, 'lines')) ?
        _.get(form, 'lines').reduce((prevValue: any, currentValue: any) => {
            // prevValue + (currentValue?.TaxAmount ? +currentValue?.TaxAmount : 0)
            if (currentValue.TaxAmount) {
                let num = currentValue.TaxAmount
                num = num.replace(/\,/g, '')
                return prevValue + Number(num)
            } else {
                return prevValue + 0;
            }
        }, 0) + (Number(freightTax) ? Number(freightTax) : 0) : 0;

    const orderDiscount: any = (+(totalBeforeDiscount ?? 0) *
        (+(_.get(form, GRPOFK.ORDER_DISCOUNT) ?? 0) / 100)).toFixed(2);

    const total: any = isArray(_.get(form, 'lines')) ?
        _.get(form, 'lines').reduce((prevValue: any, currentValue: any) => {
            if (currentValue.LineTotal) {
                return prevValue + currentValue.LineTotal
            } else {
                return prevValue + 0;
            }
        }, 0) + freightWithTax - orderDiscount : 0;

    useEffect(() => {
        setForm(GRPOFK.DOC_TOTAL, total)
    }, [total])
    const onClickFreightHandler = () => {
        setStore('is-open', true)
    }

    const discountTupleLineMarkup: any = (
        <div className={'grid grid-cols-12 gx-0 border-b'}>
            <div className={'col-span-1'}>
                <Text
                    className={'font-semibold text-[#4a4a56]'}
                    variant={'small'}>
                    Discount
                </Text>
            </div>
            <div className={'col-span-3'}>
                <div className={'grid grid-cols-5'}>
                    <div className={'col-span-4 ml-3'}>
                        <TextField name={GRPOFK.ORDER_DISCOUNT} value={_.get(form, GRPOFK.ORDER_DISCOUNT)}
                                   onChange={formController}/>
                    </div>
                    <div className={'flex justify-center items-center align-middle'}>
                        %
                    </div>
                </div>
            </div>

            <div className={'flex  col-span-6 flex-row gap-2'}>
                <Text
                    className={'font-semibold text-[#4a4a56]'}
                    variant={'small'}>
                    AUD {isNaN(orderDiscount) ? `0.00` : orderDiscount}
                </Text>
            </div>
        </div>
    )

    const freightTupleLineMarkup = (
        <div className={'grid grid-cols-12 gx-0  border-b'}>
            <div className={'col-span-4'}>
                <Text
                    className={'font-semibold text-[#4a4a56]'}
                    variant={'small'}>
                    Freight
                </Text>
            </div>
            <div onClick={onClickFreightHandler}
                 className={'flex  col-span-6 flex-row gap-2'}
            >
                <Text
                    className={'font-semibold text-[#4a4a56]'}
                    variant={'small'}>
                    AUD {freight ?? 0.00}
                </Text>
                <FontIcon
                    onClick={() => onClickFreightHandler()} className={''}
                    style={{fontSize: 10, cursor: 'pointer'}}
                    iconName="EditSolid12"
                />
            </div>
        </div>
    )
    console.log("******", freightTax)
    return (

        <TupleCard title='Summary'>
            <TupleCardLine title="Total Items"
                           value={`${isArray(_.get(form, 'lines')) ? _.get(form, 'lines')?.length : 0}`}/>
            <TupleCardLine title="Total Before Discount"
                           value={`AUD ${(totalBeforeDiscount ? totalBeforeDiscount : 0.00)?.toFixed(2)}`}/>
            {discountTupleLineMarkup}
            {freightTupleLineMarkup}
            <TupleCardLine title="Total Tax" value={`AUD ${tax?.toFixed(2)}`}/>
            <TupleCardLine title="Total Payment Due" value={`AUD ${total?.toFixed(2)}`}/>
        </TupleCard>
    )
}

export default Summary