import _ from "lodash";
import Pivot from "./Components/Pivot"
import {ARCNFK} from "../../const/form-field.const";
import {useForm} from "@dladio/hooks";
import {useDispatch} from "react-redux";
import ItemSelection from "./Components/ItemSelection"
import CustomerDetails from "./Components/CustomerDetails"
import CreditNoteSchema from "./schema/schema";
import {ARCN_STORE_PATH} from "../../const/local-store.const";
import {ARCN_ERP_METHODS} from "../../const/method.const";
import {showConfirmDialog} from "../../store/reducers/confirm-dialog";
import StatusAndDateDetails from "./Components/StatusAndDateDetails"
import {ARCN_FORM_STORE_KEYS} from "../../const/form-store.const";
import ShippingAndSummaryContainer from "./Components/ShippingAndSummaryContainer";
import {useEffect, useRef, useState} from "react";
import {LoadingToast, middleErrorToast} from "../../service/toast.service";
import {DefaultCommandBar, Grid, TitleBar} from "@dladio/core-ui"
import {dispatchAction, useAppBridgeSelector} from "@dladio/app-bridge";
import {
    clearAllFieldAction,
    clearFormAction,
    exeMethod,
    getFormValue,
    saveHandler,
    setFormValue,
    setLocalStoreValue
} from "./actions/actions";

const ARCreditNote = () => {

    const ref: any = useRef()
    const supplierRef: any = useRef()
    const [loading, setLoading]: any = useState()
    const dispatch: any = useDispatch()
    const {model} = useAppBridgeSelector();
    const {form} = useForm(ARCN_FORM_STORE_KEYS.CREDIT_NOTE)
    const supplierCode: any = _.get(form, ARCNFK.CARD_CODE)
    const id = _.get(model, 'params.id',)
    const type = _.get(model, 'params.type',)

    useEffect(() => {
        if (supplierCode || id) {
            init()
        }
    }, [supplierCode, id, type])
    const init = async () => {
        setLoading(true)
        let mainForm: any = dispatch(getFormValue(ARCN_FORM_STORE_KEYS.CREDIT_NOTE))

        ref?.current?.clearSearch()

        dispatch(clearFormAction(ARCN_FORM_STORE_KEYS.CREDIT_NOTE, [
            ARCNFK.ORDER_DISCOUNT,
            ARCNFK.DISCOUNT,
            ARCNFK.CONTACT_PERSON,
            ARCNFK.QUANTITY,
            ARCNFK.REFNO,
            ARCNFK.SELECTED_ITEM,
            ARCNFK.SHIPPING_INSTRACTION
        ], mainForm))
        dispatch(clearAllFieldAction(ARCN_FORM_STORE_KEYS.LOGISTIC_FORM))
        dispatch(clearAllFieldAction(ARCN_FORM_STORE_KEYS.TABLE_LINE_FORM))
        dispatch(clearAllFieldAction(ARCN_FORM_STORE_KEYS.FREIGHT_FORM))
        dispatch(exeMethod({
            method: ARCN_ERP_METHODS.GET_INITIAL_DATA,
            body: {
                "customer_id": _.get(mainForm, ARCNFK.CARD_CODE), id, type
            }

        })).then((res: any) => {
            if (_.get(res, 'generalDetails')) dispatch(setFormValue(ARCN_FORM_STORE_KEYS.CREDIT_NOTE, '', _.get(res, 'generalDetails')))
            if (_.get(res, 'itemLines')) dispatch(setFormValue(ARCN_FORM_STORE_KEYS.TABLE_LINE_FORM, '', _.get(res, 'itemLines')))
            if (_.get(res, 'DownPayments')) dispatch(setLocalStoreValue(ARCN_STORE_PATH.DOWN_PAYMENT, 'model', _.get(res, 'DownPayments')))
            if (_.get(res, 'DownPayments')) dispatch(setFormValue(ARCN_FORM_STORE_KEYS.DOWN_PAYMENT, '', _.get(res, 'DownPayments')))
            dispatch(setFormValue(ARCN_FORM_STORE_KEYS.LOGISTIC_FORM, '', _.get(res, 'logistic')))
            dispatch(setFormValue(ARCN_FORM_STORE_KEYS.FREIGHT_FORM, '', _.get(res, 'freight')))
            dispatch(setLocalStoreValue(ARCN_FORM_STORE_KEYS.CURRENCY, '', _.get(res, 'bpCurrency')))

        }).catch((err: any) => {
            console.error(err)
        }).finally(() => setLoading(false))
    }

    const loadingMarkup = (
        <LoadingToast className={'w-full h-[100vh] bg-white'}/>
    )
    const pageMarkup = (
        <div className={'bg-[#f0f0f0]'}>
            <TitleBar title="Welcome" subTitle={`Helios Marketing Document`}/>

            <DefaultCommandBar actions={[{
                key: 1,
                text: 'Back',
                iconProps: {iconName: 'Back'},
                onClick: () => {
                    dispatchAction({
                        action: 'navigate',
                        payload: -1
                    }, "https://helios.dlad.io/")
                },
            },
                {
                    key: 2,
                    text: 'Save',
                    iconProps: {iconName: 'save'},
                    onClick: () => {
                        dispatch(
                            showConfirmDialog({
                                title: "Confirmation Required!",
                                subtitle: "Are you sure you want to continue with the actions",
                                onConfirm: () => {
                                    dispatch(setFormValue(ARCN_FORM_STORE_KEYS.ERROR_FORM, '', {}))
                                    let model = {
                                        mainForm: dispatch(getFormValue(ARCN_FORM_STORE_KEYS.CREDIT_NOTE)),
                                        logistic: dispatch(getFormValue(ARCN_FORM_STORE_KEYS.LOGISTIC_FORM)),
                                        freight: dispatch(getFormValue(ARCN_FORM_STORE_KEYS.FREIGHT_FORM)),
                                        itemLines: dispatch(getFormValue(ARCN_FORM_STORE_KEYS.TABLE_LINE_FORM)),
                                        DownPayments: dispatch(getFormValue(ARCN_FORM_STORE_KEYS.DOWN_PAYMENT))
                                    }

                                    CreditNoteSchema.validate(model, {abortEarly: false}).then(() => {
                                        setLoading(true)
                                        dispatch(saveHandler(model)).then(() => {
                                            dispatch(clearAllFieldAction(ARCN_FORM_STORE_KEYS.CREDIT_NOTE))
                                            dispatch(clearAllFieldAction(ARCN_FORM_STORE_KEYS.FREIGHT_FORM))
                                            dispatch(clearAllFieldAction(ARCN_FORM_STORE_KEYS.TABLE_LINE_FORM))
                                            supplierRef?.current?.clearSearch()
                                        }).catch((error: any) => {
                                            console.log(error)
                                        }).finally(() => setLoading(false))

                                    }).catch((errors: any) => {
                                        let errorsModel: any = {}
                                        for (let error of errors.inner) {
                                            errorsModel[error?.path] = error?.message
                                            console.log(error)
                                        }

                                        if (_.get(errorsModel, 'itemLines')) middleErrorToast('Error', 'Please select at least one item')
                                        if (_.get(errorsModel, 'logistic.shipping_type')) middleErrorToast('Error', 'Please select shipping type')
                                        dispatch(setFormValue(ARCN_FORM_STORE_KEYS.ERROR_FORM, '', errorsModel))
                                    })
                                }
                            })
                        );
                    }
                }
            ]}/>

            <Grid>
                <CustomerDetails parentRef={supplierRef}/>
                <StatusAndDateDetails/>
            </Grid>

            {_.get(form, ARCNFK.CARD_CODE) && (
                <>
                    <ItemSelection parentRef={ref}/>
                    <Pivot/>
                    <ShippingAndSummaryContainer/>
                </>
            )}

        </div>
    )
    return (
        <>
            {
                loading ? loadingMarkup : pageMarkup
            }
        </>

    )
}

export default ARCreditNote