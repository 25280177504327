import {Text} from "@fluentui/react";

interface IProps {
    children?: any
    title?: string
    className?: string
    style?: any
}

const Box = ({children, title, className, style}: IProps) => {

    const titleMarkup = title && (
        <Text className={'capitalize font-bold py-1'}
              variant={'medium'}>
            {title}
        </Text>
    )

    return (
        <div className={`${className} bg-[#f0f0f0]  w-full`} style={style}>
            <div className={'bg-white px-1 rounded-md'}>
                <div className={'flex justify-start align-middle'}>
                    {titleMarkup}
                </div>
                {children}
            </div>
        </div>
    )
}

export default Box