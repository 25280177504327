import React from "react";
import {PrimaryButton, TextField} from "@fluentui/react";
import {useForm, useLocalStore} from "@dladio/hooks";
import {GRPO_FORM_STORE_KEYS} from "../../../../../../const/form-store.const";
import {GRPO_BATCH_SETUP_FORM_KEYS} from "../../../../../../const/form-field.const";
import _ from "lodash";
import {middleSuccessToast, middleWarningToast} from "../../../../../../service/toast.service";
import {AppDispatch} from "../../../../../../store/store";
import {useDispatch} from "react-redux";
import {getBatchSetupLine} from "./actions/actions";

const BatchSetupTableAction: React.FC = () => {
    const {form, formController, clearForm} = useForm(GRPO_FORM_STORE_KEYS.BATCH_SETUP_TABLE_FORM)
    const {model: panelModel} = useLocalStore(GRPO_FORM_STORE_KEYS.TABLE_LINE_EDIT_PANEL)
    const {model, setStore} = useLocalStore(GRPO_FORM_STORE_KEYS.BATCH_SETUP_TABLE_LINE)
    const dispatch: AppDispatch = useDispatch()
    const onClickItemAdd = () => {
        const batch = _.get(form, GRPO_BATCH_SETUP_FORM_KEYS.BATCH);
        const qty = _.get(form, GRPO_BATCH_SETUP_FORM_KEYS.QTY);
        const tableLines: Array<any> = _.get(model, 'DocumentLineBatchNumbers')

        if (batch && qty) {
            const totalQty = _.sumBy(tableLines, 'Quantity');
            const lineQty = Number(_.get(panelModel, 'line.Quantity'));

            const newTotalQty = totalQty + Number(qty);
            if (!_.find(tableLines, {BatchNumber: batch})) {
                if (newTotalQty <= lineQty) {
                    const newItem = {
                        BatchNumber: batch,
                        Quantity: Number(qty),
                        BaseLineNumber: _.get(panelModel, 'LineNum')
                    };
                    setStore('BatchNumbers', tableLines ? [..._.get(model, "BatchNumbers"), newItem] : [newItem]);
                    dispatch(getBatchSetupLine())
                    middleSuccessToast("Successful", "Line added successfully")
                } else {
                    middleWarningToast('Warning', 'Allocation Limit Exceeded');
                }
            } else {
                middleWarningToast('Warning', 'This batch number already exist');
            }

            clearForm();
        } else {
            middleWarningToast('Warning', 'Please add a batch number and quantity');
        }
    };

    return (
        <>
            <div className="flex items-end gap-2 p-2">
                <TextField label="Batch" value={_.get(form, GRPO_BATCH_SETUP_FORM_KEYS.BATCH)}
                           name={GRPO_BATCH_SETUP_FORM_KEYS.BATCH} onChange={formController}
                           className="form-field-width"/>
                <TextField label="Qty" value={_.get(form, GRPO_BATCH_SETUP_FORM_KEYS.QTY)}
                           name={GRPO_BATCH_SETUP_FORM_KEYS.QTY} onChange={formController}
                           className="form-field-width"/>
                <PrimaryButton className="bg-primary !text-[12px] !h-[25px]" onClick={onClickItemAdd} text="Add"/>
            </div>
        </>
    )
}

export default BatchSetupTableAction