import {
    DetailsHeader,
    DetailsList,
    DetailsListLayoutMode,
    IDetailsHeaderProps,
    IDetailsListStyles,
    TextField
} from "@fluentui/react";
import _ from "lodash";
import {isArray} from "@dladio/utils";
import {useForm} from "@dladio/hooks";

import {GRPO_FORM_STORE_KEYS} from "../../../../../../const/form-store.const";
import AutoCompleteDropDown from "../../../../../../components/AutoCompleteDropDown/AutoCompleteDropDown";
import {PO_ERP_METHODS} from "../../../../../../const/method.const";
import React from "react";

const GRPOFreightTable = () => {

    const {form, setForm}: any = useForm(GRPO_FORM_STORE_KEYS.FREIGHT_FORM)

    const onChangeHandler = ([...args]: any, model: any, index: any, column: any) => {
        switch (_.get(column, 'fieldName')) {
            case 'remark': {
                let cloned_lines = _.cloneDeep(form)
                cloned_lines[index]['remark'] = args[0]?.target?.value
                setForm(cloned_lines)
                break
            }
            case 'DistrbMthd': {
                let cloned_lines = _.cloneDeep(form)
                cloned_lines[index]['DistrbMthd'] = _.get(args[1], 'key')
                setForm(cloned_lines)
                break
            }
            case 'NetAmount': {
                let cloned_lines = _.cloneDeep(form)
                let totalTaxAmount = +((+_.get(model, 'TaxRateWithoutFormat') / 100) * (+args[0]?.target?.value)).toFixed(2)
                let grossAmount = ((+args[0]?.target?.value) + totalTaxAmount).toFixed(2)
                cloned_lines[index]['NetAmount'] = args[0]?.target?.value
                cloned_lines[index]['TotalTaxAmount'] = totalTaxAmount ? totalTaxAmount : 0
                cloned_lines[index]['GrossAmount'] = grossAmount ? grossAmount : 0
                setForm(cloned_lines)
                break
            }
        }
    }

    const onRenderItemColumn = (model: any, index: any, column: any) => {
        const fieldContent = _.get(model, column.fieldName);
        let grossAmount = _.get(model, 'GrossAmount', '');
        grossAmount = grossAmount.replace(/\,/g, '');
        if (column.fieldName === 'remark') return (
            <div className={'cursor-pointer'}><TextField onChange={(...args: any) => {
                onChangeHandler([...args], model, index, column)
            }} value={_.get(model, 'remark')}/></div>)

        if (column.fieldName === 'DistrbMthd') return (
            <div className={'cursor-pointer'}><AutoCompleteDropDown method={PO_ERP_METHODS.GET_DISTRIBUTION_METHODS}
                                                                    defaultValue={fieldContent}
                                                                    onChange={(...args: any) => {
                                                                        onChangeHandler([...args], model, index, column)
                                                                    }}
            />
            </div>
        )

        if (column.fieldName === 'NetAmount') return (
            <div className={'cursor-pointer'}><TextField onChange={(...args: any) => {
                onChangeHandler([...args], model, index, column)
            }} value={_.get(model, 'NetAmount')}/></div>
        )

        if (column.fieldName === 'GrossAmount') return (

            <div className={'cursor-pointer'}><TextField onChange={(...args: any) => {
                onChangeHandler([...args], model, index, column)
            }} disabled type={"number"}
                                                         value={grossAmount.toString()}/></div>
        )

        if (column.fieldName === 'TotalTaxAmount') return (
            <div className={'cursor-pointer'}><TextField value={_.get(model, 'TotalTaxAmount')}/></div>
        )

        return (
            <div className={'cursor-pointer'} onClick={() => {
            }}>
                {fieldContent}
            </div>
        )
    }

    const _onRenderDetailHeader: any = (detailsHeaderProps: IDetailsHeaderProps) => {
        if (detailsHeaderProps) {
            return (
                <DetailsHeader
                    {...detailsHeaderProps}
                />
            )
        }
        return null
    }

    return (
        <div>
            <DetailsList
                items={isArray(form) ? form : []}
                columns={_.get(config, 'header_fields')}
                compact={true}
                styles={gridStyles}
                setKey="none"
                onRenderItemColumn={onRenderItemColumn}
                onRenderDetailsHeader={_onRenderDetailHeader}
                isHeaderVisible={true}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={0}
                selectionPreservedOnEmptyClick={true}
            />
        </div>
    )
}

const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        backgroundColor: 'white',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                height: '70vh',
            },
        }
    },
    headerWrapper: {
        flex: '0 0 auto'
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden'
    }
};
export default GRPOFreightTable

const config: any = {
    header_fields: [
        {
            key: 1,
            fieldName: 'ExpnsName',
            name: 'Expns Name',
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#f0f0f0'}},
        },
        {
            key: 2,
            fieldName: 'remark',
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Remark',
        },
        {
            key: 3,
            fieldName: 'VatGroupO',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'VAT Code',
        },
        {
            key: 4,
            fieldName: 'TaxRate',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Tax',
        },
        {
            key: 5,
            fieldName: 'TotalTaxAmount',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Total Tax Amount',
        },
        {
            key: 6,
            fieldName: 'DistrbMthd',
            minWidth: 200,
            maxWidth: 200,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Distrib. Method',
        },
        {
            key: 7,
            fieldName: 'NetAmount',
            minWidth: 200,
            maxWidth: 200,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'NetAmount',
        },

        // {
        //     key: 8,
        //     fieldName: 'Status',
        //     minWidth: 100,
        //     maxWidth: 100,
        //     styles: {root: {backgroundColor: '#f0f0f0'}},
        //     name: 'Status',
        // },
        {
            key: 10,
            fieldName: 'GrossAmount',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Gross Amount',
        }
    ],
    is_search_visible: true,
    height: 'sm',
    title: "Items"
}