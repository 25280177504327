import React, {forwardRef, useImperativeHandle} from "react";
import {useForm, useLocalStore} from "@dladio/hooks";
import {GRPO_FORM_STORE_KEYS} from "../../../../../../const/form-store.const";
import TupleCard from "../../../../../../components/TupleCard/TupleCard";
import TupleCardLine from "../../../../../../components/TupleCard/TupleCardLine";
import _ from "lodash";
import BatchSetupTableAction from "./BatchSetupTableAction";
import BatchSetupTable from "./BatchSetupTable";
import BinLocationAllocationReceiptTableAction from "./BinLocationAllocationReceiptTableAction";
import BinLocationAllocationReceiptTable from "./BinLocationAllocationReceiptTable";
import {isArray} from "@dladio/utils";
import {GRPO_STORE_PATH} from "../../../../../../const/local-store.const";
import {AppDispatch} from "../../../../../../store/store";
import {useDispatch} from "react-redux";
import {showConfirmDialog} from "../../../../../../store/reducers/confirm-dialog";
import {middleErrorToast} from "../../../../../../service/toast.service";

const BinBatchAllocation = forwardRef((props, ref) => {
    const {model, setStore} = useLocalStore(GRPO_FORM_STORE_KEYS.TABLE_LINE_EDIT_PANEL);
    const {form, setForm} = useForm(GRPO_FORM_STORE_KEYS.GRPO_DOCUMENT_LINE_FROM);
    const {
        model: batchSetupModel,
        setStore: setBatchSetupStore
    } = useLocalStore(GRPO_FORM_STORE_KEYS.BATCH_SETUP_TABLE_LINE);
    const {
        model: binAllocationModel,
        setStore: binAllocationSetStore
    } = useLocalStore(GRPO_STORE_PATH.BIN_ALLOCATION_TABLE);
    const dispatch: AppDispatch = useDispatch();

    useImperativeHandle(ref, () => ({
        onSaveHandler() {
            dispatch(showConfirmDialog({
                title: "Confirmation",
                subtitle: "Save batch bin setup allocation",
                onConfirm: () => {
                    try {
                        const filteredDocumentLines: Array<any> = isArray(_.get(form, "lines")) ?
                            _.get(form, "lines").filter((item: any) => (
                                item?.LineNum !== _.get(model, "line")?.LineNum
                            )) : []
                        const currentSelectedDocLine = _.cloneDeep(_.get(model, "line"))
                        currentSelectedDocLine.BatchNumbers = _.get(batchSetupModel, 'BatchNumbers')
                        currentSelectedDocLine.DocumentLinesBinAllocations = _.get(binAllocationModel, 'DocumentLinesBinAllocations')
                        setForm("lines", [...filteredDocumentLines, currentSelectedDocLine])
                        // console.log("************", currentSelectedDocLine)
                        setBatchSetupStore('BatchNumbers', [])
                        setBatchSetupStore('DocumentLineBatchNumbers', [])
                        binAllocationSetStore('DocumentLinesBinAllocations', [])
                        binAllocationSetStore('selectedBatchBinAllocation', [])
                        setStore('is-open', false)
                    } catch (e) {
                        middleErrorToast("Error", "Internal error")
                    }
                }
            }))
        }
    }))

    return (
        <>
            <div className="flex flex-col justify-between h-[60vh]">
                <div>
                    <div className="flex justify-start">
                        <div className="w-6/12">
                            <TupleCard title={"Row from document"}>
                                <TupleCardLine title={"Item Number"} value={_.get(model, 'line.ItemCode')}/>
                                <TupleCardLine title={"Item Name"} value={_.get(model, 'line.ItemName')}/>
                                <TupleCardLine title={"Whse Code"} value={_.get(model, 'line.DfltWH')}/>
                                <TupleCardLine title={"Total Needed"}
                                               value={_.get(model, 'line.Quantity')}/>
                            </TupleCard>
                        </div>
                    </div>
                    <BatchSetupTableAction/>
                    <BatchSetupTable/>
                </div>
                <div>
                    <BinLocationAllocationReceiptTableAction/>
                    <BinLocationAllocationReceiptTable model={[]}/>
                </div>
            </div>
        </>
    )
})

export default BinBatchAllocation;