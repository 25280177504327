import Template1 from "../examples/Template1/Template1";
import {useRoutes} from "react-router-dom";
import MarketingDocumentTemplate from "../examples/Template1/MarketingDocumentTemplate";
import PurchaseInvoice from "../pages/PurchaseInvoice/PurchaseInvoice";
import PurchaseOrder from "../pages/PurchaseOrder/PurchaseOrder";
import GoodsReceiptPurchaseOrder from "../pages/GoodsReceiptPurchaseOrder/GoodsReceiptPurchaseOrder";
import ARCreditNote from "../pages/ARCrediteNote/ARCreditNote";

const RouterConfigs = () => {
    const element = useRoutes([
        {
            path: "/",
            children: [
                {
                    index: true,
                    element: <Template1/>
                },
                {
                    path: "template",
                    element: <MarketingDocumentTemplate/>
                },
                {
                    path: "purchase-invoice",
                    element: <PurchaseInvoice/>
                },
                {
                    path: "purchase-order",
                    element: <PurchaseOrder/>
                },
                {
                    path: "goods-receipt-po",
                    element: <GoodsReceiptPurchaseOrder/>
                },
                {
                    path: "ar-credit-note",
                    element: <ARCreditNote/>
                }
            ]
        },
        {path: "*", element: <h1>page not found</h1>},
    ]);

    return element;
};

export default RouterConfigs;