import React from "react";
import {DefaultCommandBar} from "@dladio/core-ui";
import {useForm} from "@dladio/hooks";
import {GRPO_FORM_STORE_KEYS} from "../../../const/form-store.const";
import _ from "lodash";
import {AppDispatch} from "../../../store/store";
import {useDispatch} from "react-redux";
import {exeMethod} from "../actions/actions";
import {GRPO_ERP_METHODS} from "../../../const/method.const";
import {middleErrorToast, middleSuccessToast} from "../../../service/toast.service";
import documentLineSchema from "../schema/documentLineSchema";

const CommandBar = ({setIsLoading}: any) => {

    const dispatch: AppDispatch = useDispatch();
    const {form} = useForm(GRPO_FORM_STORE_KEYS.GRPO_DOCUMENT_LINE_FROM);
    const {form: freight} = useForm(GRPO_FORM_STORE_KEYS.FREIGHT_FORM);
    const {form: logistic} = useForm(GRPO_FORM_STORE_KEYS.LOGISTIC_FORM);

    // const {model} = useAppBridgeSelector();
    // const model = { //TODO: This is use for testing
    //     params: {
    //         id: 502
    //     }
    // }


    const onSaveHandler = () => {

        if (_.get(form, "lines")) {
            setIsLoading(true)
            documentLineSchema.validate({DocumentLines: _.get(form, 'lines')}).then(() => {
                const doc = _.cloneDeep(form);
                doc.AddressExtension = logistic;
                doc.DocumentAdditionalExpenses = freight;
                dispatch(exeMethod({
                    method: GRPO_ERP_METHODS.GENERATE_GRPO,
                    body: doc
                })).then((response: any) => {
                    setIsLoading(false);
                    middleSuccessToast("GRPO Created");
                }).catch((error: any) => {
                    setIsLoading(false);
                    middleErrorToast("Error", _.get(error, "data.message.error.message.value"));
                })
            }).catch((error: any) => {
                setIsLoading(false);
                console.log("***********************", error)
                middleErrorToast("", error.message)
            })
        } else {
            middleErrorToast("Please add items")
        }
    }

    return (
        <>
            <DefaultCommandBar actions={[{
                key: 1,
                text: 'Back',
                iconProps: {iconName: 'Back'},
                onClick: () => {

                },
            },
                {
                    key: 2,
                    text: 'Save',
                    iconProps: {iconName: 'Save'},
                    onClick: onSaveHandler,
                },
                // {
                //     key: 3,
                //     text: 'Copy From',
                //     iconProps: {iconName: 'Copy'},
                //     onClick: () => {
                //         alert()
                //     },
                // },
                {
                    key: 4,
                    text: 'Copy To',
                    iconProps: {iconName: 'Paste'},
                    onClick: () => {
                        alert()
                    },
                }
            ]}/>
        </>
    )
}

export default CommandBar