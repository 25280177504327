import _ from "lodash";
import useForm from "@dladio/hooks/dist/hooks/useForm";
import {TextField} from "@fluentui/react";
import {PO_ERP_METHODS} from "../../../const/method.const";
import AutoCompleteDropDown from "./AutoCompleteDropDown/AutoCompleteDropDown";
import {POFK, POFK_LOGISTIC} from "../../../const/form-field.const";
import {Box, LabelOnLeft, Stack} from "@dladio/core-ui";
import {PERCHASE_ORDER_FORM_STORE_KEYS} from "../../../const/form-store.const";

const LogisticDetails = () => {

    const {form, setForm, formController} = useForm(PERCHASE_ORDER_FORM_STORE_KEYS.LOGISTIC_FORM);
    const {form: errorForm} = useForm(PERCHASE_ORDER_FORM_STORE_KEYS.ERROR_FORM)
    const companyAddress: string = (_.get(form, 'company_address.CompnyName') ? _.get(form, 'company_address.CompnyName') : '') + ' ' + (_.get(form, 'company_address.CompnyAddr') ? _.get(form, 'company_address.CompnyAddr')?.replace(/\r/g, '') : '')
    const onChangeShippingType = (e: any, {key, text}: any) => {
        setForm(POFK_LOGISTIC.SHIPPING_TYPE, key)
    }

    return (
        <>
            <Box className="[&>div]:h-[350px]">
                <Stack className={'flex-col p-3 gap-3'}>
                    <LabelOnLeft label="Ship To">
                        <Stack>
                            <TextField name={POFK_LOGISTIC.SHIP_TO_ADDRESS}
                                       className="w-[286px] ring-none pt-1 disabled:text-black-800"
                                       multiline={true}
                                       rows={5}
                                       resizable={false}
                                       onChange={formController}
                                       value={companyAddress}
                            />
                        </Stack>
                    </LabelOnLeft>
                    <LabelOnLeft label="Pay To">
                        <Stack className={'flex-col'}>
                            <Stack className={''}>
                                <TextField name={POFK_LOGISTIC.PAY_TO_ADDRESS}
                                           className="w-[286px] pt-1"
                                           multiline={true}
                                           rows={5}
                                           resizable={false}
                                           readOnly={true}
                                           onChange={formController}
                                           value={`${_.get(form, POFK_LOGISTIC.PAY_TO_ADDRESS)?.Address ?? ''} \n ${_.get(form, POFK_LOGISTIC.PAY_TO_ADDRESS)?.Street ?? ''} \n ${_.get(form, POFK_LOGISTIC.PAY_TO_ADDRESS)?.City ?? ''}\n ${_.get(form, POFK_LOGISTIC.PAY_TO_ADDRESS)?.State ?? ''}\n ${_.get(form, POFK_LOGISTIC.PAY_TO_ADDRESS)?.Country ?? ''}`}
                                />
                            </Stack>
                        </Stack>
                    </LabelOnLeft>
                    <LabelOnLeft label="Shipping Type">
                        <div>
                            <AutoCompleteDropDown method={PO_ERP_METHODS.GET_SHIPPING_TYPE}
                                                  defaultValue={_.get(form, POFK_LOGISTIC.SHIPPING_TYPE)}
                                                  body={{id: _.get(form, POFK.SUPPLIER_CODE)}}
                                                  onChange={onChangeShippingType}/>
                            {_.get(errorForm, 'logistic.shipping_type') && (
                                <p className={'text-red-500 text-xs'}>{_.get(errorForm, 'logistic.shipping_type')}</p>)}
                        </div>
                    </LabelOnLeft>
                </Stack>
            </Box>
        </>
    )
}

export default LogisticDetails