import _ from "lodash";
import {ARCNFK} from "../../../const/form-field.const";
import {useForm} from "@dladio/hooks";
import {useEffect} from "react";
import {DatePicker} from "@fluentui/react"
import {Box, LabelOnLeft} from "@dladio/core-ui"
import {ARCN_FORM_STORE_KEYS} from "../../../const/form-store.const";

const StatusAndDateDetails = () => {
    const {form, setForm} = useForm(ARCN_FORM_STORE_KEYS.CREDIT_NOTE)
    const onChangeDateHandler = (key: any, value: any) => {
        setForm(key, value)
    }

    useEffect(() => {
        setForm(ARCNFK.POSTING_DATE, new Date())
        setForm(ARCNFK.DELIVERY_DATE, new Date())
        setForm(ARCNFK.TAXDATE, new Date())

    }, [])

    return (
        <Box>
            <div className="pt-3 px-2 flex flex-col h-[110px]">
                <LabelOnLeft label="Posting Date">
                    <DatePicker
                        onSelectDate={(value) => {
                            onChangeDateHandler(ARCNFK.POSTING_DATE, value)
                        }}
                        value={new Date(_.get(form, ARCNFK.POSTING_DATE))}
                        className="form-field-width"
                        showWeekNumbers={true}
                        firstWeekOfYear={1}
                        showMonthPickerAsOverlay={true}
                        placeholder="Select a date..."
                        ariaLabel="Select a date"
                    />
                </LabelOnLeft>
                <LabelOnLeft label="Delivery Date">
                    <DatePicker
                        onSelectDate={(value) => {
                            onChangeDateHandler(ARCNFK.DELIVERY_DATE, value)
                        }}
                        className="form-field-width"
                        value={new Date(_.get(form, ARCNFK.DELIVERY_DATE))}
                        showWeekNumbers={true}
                        firstWeekOfYear={1}
                        showMonthPickerAsOverlay={true}
                        placeholder="Select a date..."
                        ariaLabel="Select a date"
                    />
                </LabelOnLeft>
                <LabelOnLeft label="Document Date">
                    <DatePicker
                        onSelectDate={(value) => {
                            onChangeDateHandler(ARCNFK.TAXDATE, value)
                        }}
                        className="form-field-width"
                        value={new Date(_.get(form, ARCNFK.TAXDATE))}
                        showWeekNumbers={true}
                        firstWeekOfYear={1}
                        showMonthPickerAsOverlay={true}
                        placeholder="Select a date..."
                        ariaLabel="Select a date"
                    />
                </LabelOnLeft>
            </div>
        </Box>
    )
}

export default StatusAndDateDetails

