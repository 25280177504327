import React, {forwardRef, useImperativeHandle, useState} from "react";
import _ from "lodash";
import {Grid, LabelOnLeft} from "@dladio/core-ui";
import {Shimmer, TextField} from "@fluentui/react";
import {GRPOFK} from "../../../../../const/form-field.const";
import AutoCompleteDropDown from "../../../../../components/AutoCompleteDropDown/AutoCompleteDropDown";
import {GRPO_ERP_METHODS, PO_ERP_METHODS} from "../../../../../const/method.const";
import {exeMethod} from "../../../actions/actions";
import {useForm, useLocalStore} from "@dladio/hooks";
import {GRPO_FORM_STORE_KEYS} from "../../../../../const/form-store.const";
import {AppDispatch} from "../../../../../store/store";
import {useDispatch} from "react-redux";
import {middleErrorToast, middleSuccessToast} from "../../../../../service/toast.service";
import {showConfirmDialog} from "../../../../../store/reducers/confirm-dialog";

const EditDocumentLine = forwardRef((props, ref) => {
    const {model, setStore} = useLocalStore(GRPO_FORM_STORE_KEYS.TABLE_LINE_EDIT_PANEL)
    const {form, setForm} = useForm(GRPO_FORM_STORE_KEYS.GRPO_DOCUMENT_LINE_FROM)
    const {
        model: batchSetupModel,
        setStore: setBatchSetupModelStore
    } = useLocalStore(GRPO_FORM_STORE_KEYS.BATCH_SETUP_TABLE_LINE)
    const dispatch: AppDispatch = useDispatch()
    const [documentLines, setDocumentLines] = useState([])


    useImperativeHandle(ref, () => ({
        onSaveHandler() {
            dispatch(showConfirmDialog({
                title: "Confirmation: Batch Allocation Reset Required!",
                subtitle: "If you update any document line field, your batch allocation will be reset. " +
                    "Please note that you will need to perform batch allocation again to ensure accurate assignment.",
                onConfirm: () => {
                    try {
                        const documentLineIndex: number = _.get(model, 'LineNumber')
                        const allBatches: Array<any> | undefined = _.get(batchSetupModel, 'BatchNumbers')
                        setForm('lines', [...documentLines, _.get(model, 'line')])
                        if (allBatches) {
                            const filteredBatchNumber: Array<any> = allBatches.filter((item: any) => (
                                item.BaseLineNumber !== documentLineIndex
                            ))
                            setBatchSetupModelStore('BatchNumbers', filteredBatchNumber)
                        }
                        middleSuccessToast("Success", "Document line updated successfully")
                        setStore('is-open', false)
                    } catch (e) {
                        middleErrorToast("Error", "Internal error")
                    }
                }
            }))
        }
    }));

    const onItemNameChangeHandler = (e: any, {key, text}: any) => {
        setStore('is-loading', true)
        dispatch(exeMethod({
            method: GRPO_ERP_METHODS.GENERATE_ITEM_LINE,
            body: {
                [GRPOFK.SELECTED_ITEM]: key,
                [GRPOFK.QUANTITY]: _.get(form, GRPOFK.QUANTITY),
                [GRPOFK.DISCOUNT]: _.get(form, GRPOFK.DISCOUNT),
                [GRPOFK.SUPPLIER_CODE]: _.get(form, GRPOFK.SUPPLIER_CODE),
            }
        })).then((value: any) => {
            // Find the index of the item in the array
            const documentLines = _.cloneDeep(_.get(form, 'lines'))
            const index = documentLines.findIndex(function (item: any) {
                return item.ItemCode === _.get(model, 'line.ItemCode');
            });

            // Remove the item if found
            if (index !== -1) {
                documentLines.splice(index, 1);
            }
            setDocumentLines(documentLines)
            setStore('line', value)
        }).catch((error: any) => {
            console.log(error)
        }).finally(() => {
            setStore('is-loading', false)
        })
    }

    const onDiscountChangeHandler = (e: any, value: any) => {
        setForm(GRPOFK.DISCOUNT, value)
        const cloneModel = _.cloneDeep(_.get(model, 'line'))
        cloneModel.Discount = value
        cloneModel.LineTotal = lineTotalCalculation(Number(_.get(form, GRPOFK.QUANTITY)), Number(value), Number(_.get(model, 'line.UnitPrice')))
        const documentLines = _.cloneDeep(_.get(form, 'lines'))
        const index = documentLines.findIndex(function (item: any) {
            return item.ItemCode === _.get(model, 'line.ItemCode');
        });

        // Remove the item if found
        if (index !== -1) {
            documentLines.splice(index, 1);
        }
        setDocumentLines(documentLines)
        setStore('line', cloneModel)

    }

    const onQuantityChangeHandler = (e: any, value: any) => {
        setForm(GRPOFK.QUANTITY, value)
        const cloneModel = _.cloneDeep(_.get(model, 'line'))
        cloneModel.Quantity = value
        cloneModel.LineTotal = lineTotalCalculation(Number(value), Number(_.get(form, GRPOFK.DISCOUNT)), Number(_.get(model, 'line.UnitPrice')))
        const documentLines = _.cloneDeep(_.get(form, 'lines'))
        const index = documentLines.findIndex(function (item: any) {
            return item.ItemCode === _.get(model, 'line.ItemCode');
        });

        // Remove the item if found
        if (index !== -1) {
            documentLines.splice(index, 1);
        }
        setDocumentLines(documentLines)
        setStore('line', cloneModel)

    }

    const onWarehouseSelectHandler = (e: any, {key, text}: any) => {
        setForm('DfltWH', key)
        setForm('WhsName', text)

        const cloneModel = _.cloneDeep(_.get(model, 'line'))
        cloneModel.DfltWH = key
        cloneModel.WhsName = text
        const documentLines = _.cloneDeep(_.get(form, 'lines'))
        const index = documentLines.findIndex(function (item: any) {
            return item.ItemCode === _.get(model, 'line.ItemCode');
        });

        // Remove the item if found
        if (index !== -1) {
            documentLines.splice(index, 1);
        }
        setDocumentLines(documentLines)
        setStore('line', cloneModel)
    }

    return (
        <>
            {_.get(model, 'is-loading') ?
                <Grid className="grid-cols-2 gap-2 p-4">
                    <Shimmer/>
                    <Shimmer/>
                    <Shimmer/>
                    <Shimmer/>
                    <Shimmer/>
                    <Shimmer width="75%"/>
                </Grid> :
                <div className="grid grid-cols-2 gap-2">
                    <LabelOnLeft label="Item Code">
                        <TextField name={GRPOFK.QUANTITY} disabled value={_.get(model, 'line.ItemCode')}
                                   className="form-field-width"/>
                    </LabelOnLeft>
                    <LabelOnLeft label="Item Name">
                        <AutoCompleteDropDown method={PO_ERP_METHODS.GET_ITEMS}
                                              defaultKey={_.get(model, 'line.ItemCode')}
                                              onChange={onItemNameChangeHandler}/>
                    </LabelOnLeft>
                    <LabelOnLeft label="Quantity">
                        <TextField name={GRPOFK.QUANTITY} defaultValue={_.get(model, 'line.Quantity')}
                                   onChange={onQuantityChangeHandler} className="form-field-width"/>
                    </LabelOnLeft>
                    <LabelOnLeft label="Discount">
                        <TextField name={GRPOFK.DISCOUNT} defaultValue={_.get(model, 'line.Discount')}
                                   onChange={onDiscountChangeHandler} className="form-field-width"/>
                    </LabelOnLeft>
                    <LabelOnLeft label="Unit Price">
                        <TextField name={GRPOFK.QUANTITY} disabled value={_.get(model, 'line.UnitPrice')}
                                   className="form-field-width"/>
                    </LabelOnLeft>
                    <LabelOnLeft label="Warehouse">
                        <AutoCompleteDropDown method={PO_ERP_METHODS.GET_WHEREHOUSE_LIST}
                                              defaultKey={_.get(model, 'line.DfltWH')}
                                              onChange={onWarehouseSelectHandler}/>
                    </LabelOnLeft>
                    <LabelOnLeft label="GST Code">
                        <TextField name={GRPOFK.QUANTITY} disabled value={_.get(model, 'line.GSTCode')}
                                   className="form-field-width"/>
                    </LabelOnLeft>
                    <LabelOnLeft label="Line Tatal">
                        <TextField name={GRPOFK.QUANTITY} disabled value={_.get(model, 'line.LineTotal')}
                                   className="form-field-width"/>
                    </LabelOnLeft>
                </div>}
        </>
    )
})

const lineTotalCalculation = (quantity: number, discount: number, unitPrice: number) => {
    return ((quantity * unitPrice) * discount) / 100
}

export default EditDocumentLine