export enum POFK {
    SUPPLIER_CODE = 'CardCode',
    TAXDATE = 'TaxDate',
    CONTACT_PERSON = 'ContactPersonCode',
    REFNO = 'NumAtCard',
    POSTING_DATE = 'DocDate',
    DELIVERY_DATE = 'DocDueDate',
    DOCUMENT_DATE = 'DocDate',
    SELECTED_ITEM = 'ItemCode',
    QUANTITY = 'Quantity',
    DISCOUNT = 'discount',
    RATE = 'RATE',
    ORDER_DISCOUNT = 'DiscountPercent',
    SHIPPING_INSTRACTION = 'Comments'

}

export enum POFK_LOGISTIC {
    SHIP_TO_ADDRESS = 'ship_to',
    SHIPPING_TYPE = 'shipping_type',
    PAY_TO_ADDRESS = 'pay_to',
    DISPLAY_ADDRESS = 'company_address',
    STAMP_NO = 'stamp_no'

}

export enum GRPOFK_LOGISTIC {
    SHIP_TO_ADDRESS = 'ship_to',
    SHIPPING_TYPE = 'shipping_type',
    PAY_TO_ADDRESS = 'pay_to',
    DISPLAY_ADDRESS = 'company_address',
    STAMP_NO = 'stamp_no'

}

export enum GRPOFK {
    SUPPLIER_CODE = 'CardCode',
    SUPPLIER_NAME = 'CardName',
    CONTACT_PERSON = 'ContactPersonCode',
    REFNO = 'NumAtCard',
    POSTING_DATE = 'DocDate',
    DELIVERY_DATE = 'DocDueDate',
    DOCUMENT_DATE = 'TaxDate',
    SELECTED_ITEM = 'ItemCode',
    QUANTITY = 'Quantity',
    DISCOUNT = 'Discount',
    RATE = 'RATE',
    COMMENTS = "Comments",
    ORDER_DISCOUNT = 'DiscountPercent',
    SHIPPING_INSTRACTION = 'Comments',
    DOC_TOTAL = "DocTotal"
}

export enum GRPO_BIN_ALLOCATION_FK {
    BIN_LOCATION = 'BinAbsEntry',
    BIN_LOCATION_NAME = 'BinAbsEntryName',
    QUANTITY = 'Quantity',
}

export enum POFK_EDITE_FORM {
    QUANTITY = 'Quantity',
    DISCOUNT = 'discount',
    RATE = 'Price',
    SELECTED_ITEM = 'ItemCode',
    SUPPLIER_CODE = 'CardCode',
    WHEREHOUSE_CODE = 'DfltWH'
}

export enum PIFK {
    SUPPLIER_CODE = 'supplier_code',
    SUPPLIER_NAME = 'supplier_name',
    SUPPLIER_REF = 'RefNo',
    CONTACT_PERSON_CODE = 'ContactPersonCode',
    POSTING_DATE = 'posting_date',
    DELIVERY_DATE = 'delivery_date',
    DOCUMENT_DATE = 'document_date',
    SELECTED_ITEM = 'selected_item',
    RATE = 'RATE',
    SHIP_TO_ADDRESS = 'ShipToAddress',
    PAY_TO_ADDRESS = 'PayToAddress',
    SHIPPING_TYPE = 'TransportationCode',
    STAMP_NO = 'StampNo',
    ORDER_DISCOUNT = 'DiscountPercent',
    ITEM_CODE = 'ItemCode',
    ITEM_NAME = 'ItemDescription',
    QUANTITY = 'Quantity',
    DISCOUNT = 'DiscountPercent',
    UNIT_PRICE = 'UnitPrice',
    WAREHOUSE = 'WarehouseCode',
    WAREHOUSE_NAME = 'WarehouseName',
    GST_CODE = 'TaxCode',
    TAX_RATE = 'TaxPercent',
    LINE_TOTAL = 'LineTotal',
    SHIPPING_INSTRUCTION = 'Comments',
    DOC_CURRENCY = 'DocCurrency',
    LINE_CURRENCY = 'Currency',
    TOTAL_DOWN_PAYMENT = 'TotalDownPayment',
    CARD_NAME = 'CardName',
    TRANSPORTATION_NAME = 'TransportationName',

}

export enum PIFK_TBL_LINE_EDIT_FORM {
    ITEM_CODE = 'ItemCode',
    ITEM_NAME = 'ItemDescription',
    QUANTITY = 'Quantity',
    DISCOUNT = 'discount',
    UNIT_PRICE = 'UnitPrice',
    WAREHOUSE = 'WarehouseCode',
    GST_CODE = 'TaxCode',
    TAX_RATE = 'TaxRate',
    LINE_TOTAL = 'LineTotal',
    SUPPLIER_CODE = 'supplier_code',
    WAREHOUSE_NAME = 'WarehouseName'
}

export enum GRPO_BATCH_SETUP_FORM_KEYS {
    BATCH = 'BatchNumber',
    QTY = 'Quantity'
}

export enum FREIGHT_FORM_KEYS {
    EXPENSE_CODE = 'ExpenseCode',
    EXPENSE_NAME = 'ExpnsName',
    REMARK = 'Remarks',
    VAT_CODE = 'TaxCode',
    TAX_RATE = 'TaxRate',
    TOTAL_TAX_AMOUNT = 'TaxSum',
    DISTRIBUTE_METHOD = 'DistributionMethod',
    NET_AMOUNT = 'LineTotal',
    GROSS_AMOUNT = 'LineGross'

}

export enum PIFK_LOGISTIC {
    SHIP_TO_ADDRESS = 'ship_to',
    SHIPPING_TYPE = 'shipping_type',
    PAY_TO_ADDRESS = 'pay_to',
    DISPLAY_ADDRESS = 'company_address',
    STAMP_NO = 'stamp_no',

}

export enum PIFK_BATCH_LOCATION {
    BATCH_NUMBER = 'BatchNumber',
    QUANTITY = 'Quantity',
    ITEM_CODE = 'ItemCode',
    BASE_LINE_NUMBER = 'BaseLineNumber'
}

export enum PIFK_BIN_LOCATION {
    BIN_ABS_ENTRY = 'BinAbsEntry',
    QUANTITY = 'Quantity',
    SERIAL_AND_BATCH_NUMBER_BASE_LINE = 'SerialAndBatchNumbersBaseLine',
    BASE_LINE_NUMBER = 'BaseLineNumber'
}

export enum ARCNFK {
    CARD_CODE = 'CardCode',
    TAXDATE = 'TaxDate',
    CONTACT_PERSON = 'ContactPersonCode',
    REFNO = 'NumAtCard',
    POSTING_DATE = 'DocDate',
    DELIVERY_DATE = 'DocDueDate',
    DOCUMENT_DATE = 'DocDate',
    SELECTED_ITEM = 'ItemCode',
    QUANTITY = 'Quantity',
    DISCOUNT = 'discount',
    RATE = 'RATE',
    ORDER_DISCOUNT = 'DiscountPercent',
    SHIPPING_INSTRACTION = 'Comments',
    LINE_NUM = 'LineNum'

}

export enum ARCNFK_LOGISTIC {
    SHIP_TO_ADDRESS = 'ship_to',
    SHIPPING_TYPE = 'shipping_type',
    PAY_TO_ADDRESS = 'pay_to',
    DISPLAY_ADDRESS = 'company_address',
    STAMP_NO = 'stamp_no'

}

export enum ARCN_EDIT_FORM {
    QUANTITY = 'Quantity',
    DISCOUNT = 'discount',
    RATE = 'Price',
    SELECTED_ITEM = 'ItemCode',
    CARD_CODE = 'CardCode',
    WHEREHOUSE_CODE = 'DfltWH',
    BASE_LINE = 'BaseLine',
    BASE_ENTRY = 'BaseEntry',
    BASE_TYPE = 'BaseType',

}

export enum ARCN_CREATE_BATCH_FORM {
    BATCH_NAME = 'BatchNumber',
    AVAILABLE_QTY='Available_qty',
    QTY = 'Quantity',
    BASE_LINE = 'BaseLineNumber'
}

export enum SELECT_BIN_FORM {
    BIN_NAME = 'bin_name',
    BIN_CODE = 'code',
    QTY = 'Quantity'
}

