import _ from "lodash";
import PanelPivot from "./PanelPivot";
import {useDispatch} from "react-redux";
import {buttonStyles} from "@fluentui/react/lib/components/Nav/Nav.styles";
import {useLocalStore} from "@dladio/hooks";
import {ARCN_STORE_PATH} from "../../../../../const/local-store.const";
import {ARCN_ERP_METHODS} from "../../../../../const/method.const";
import {Panel, PanelType} from "@fluentui/react/lib/Panel";
import {middleErrorToast} from "../../../../../service/toast.service";
import {ARCN_FORM_STORE_KEYS} from "../../../../../const/form-store.const";
import {ARCN_EDIT_FORM, ARCNFK} from "../../../../../const/form-field.const";
import {DefaultButton, PrimaryButton} from "@fluentui/react";
import {exeMethod, getFormValue, getLocalStoreValue, setFormValue, setLocalStoreValue} from "../../../actions/actions";

const BinBatchAllocationPanel = () => {

    const {model, setStore} = useLocalStore(ARCN_STORE_PATH.BIN_BATCH_ALLOCATION_PANEL);
    const dispatch: any = useDispatch()
    const onSaveHandler = async () => {
        let editLineForm = await dispatch(getFormValue(ARCN_FORM_STORE_KEYS.EDITE_PANEL_FORM))
        let panelModel = await dispatch(getLocalStoreValue(ARCN_STORE_PATH.TABLE_LINE_EDIT_PANEL))
        let selectedItemIndex = _.get(panelModel, 'selected-line-index')

        if (_.get(editLineForm, ARCN_EDIT_FORM.SELECTED_ITEM) && _.get(editLineForm, ARCN_EDIT_FORM.QUANTITY)) {
            dispatch(setLocalStoreValue(ARCN_STORE_PATH.PAGE_LOADING, '', true))
            setStore('is-open', false)
            let batchModel = await dispatch(getLocalStoreValue(ARCN_STORE_PATH.BATCH_MODEL)) || []

            dispatch(exeMethod({
                method: ARCN_ERP_METHODS.GENERATE_ITEM_LINE,
                body: {
                    [ARCN_EDIT_FORM.SELECTED_ITEM]: _.get(editLineForm, ARCN_EDIT_FORM.SELECTED_ITEM),
                    [ARCN_EDIT_FORM.QUANTITY]: _.get(editLineForm, ARCN_EDIT_FORM.QUANTITY),
                    [ARCN_EDIT_FORM.DISCOUNT]: _.get(editLineForm, ARCN_EDIT_FORM.DISCOUNT),
                    [ARCN_EDIT_FORM.CARD_CODE]: _.get(editLineForm, ARCN_EDIT_FORM.CARD_CODE),
                    [ARCN_EDIT_FORM.WHEREHOUSE_CODE]: _.get(editLineForm, ARCN_EDIT_FORM.WHEREHOUSE_CODE),
                    [ARCN_EDIT_FORM.BASE_ENTRY]: _.get(editLineForm, ARCN_EDIT_FORM.BASE_ENTRY),
                    [ARCN_EDIT_FORM.BASE_TYPE]: _.get(editLineForm, ARCN_EDIT_FORM.BASE_TYPE),
                    [ARCN_EDIT_FORM.BASE_LINE]: _.get(editLineForm, ARCN_EDIT_FORM.BASE_LINE),
                    [ARCNFK.LINE_NUM]: _.get(editLineForm, ARCNFK.LINE_NUM),
                    "batchModel": batchModel
                }

            })).then((line: any) => {
                let clonedLines: any = _.cloneDeep(dispatch(getFormValue(ARCN_FORM_STORE_KEYS.TABLE_LINE_FORM)))
                clonedLines[selectedItemIndex] = line
                dispatch(setFormValue(ARCN_FORM_STORE_KEYS.TABLE_LINE_FORM, '', clonedLines))

            }).catch((error: any) => {
                console.log(error)
                middleErrorToast('Error', 'Item Line cannot edite')

            }).finally(() => {
                dispatch(setLocalStoreValue(ARCN_STORE_PATH.BATCH_MODEL, '', []))
                dispatch(setLocalStoreValue(ARCN_STORE_PATH.PAGE_LOADING, '', false))
                dispatch(setLocalStoreValue(ARCN_STORE_PATH.SELECTED_BATCH_LINE, '', ''))
            })

        } else {

        }
        setStore('is-open', false)
    }
    const onRenderFooterContent =
        () => (
            <div className={'flex gap-2'}>
                <PrimaryButton onClick={onSaveHandler} styles={buttonStyles}>
                    Submit
                </PrimaryButton>
                <DefaultButton onClick={onClickCancelHandler}>Cancel</DefaultButton>
            </div>
        )

    const onClickCancelHandler = () => {
        setStore('is-open', false)
        dispatch(setLocalStoreValue(ARCN_STORE_PATH.BATCH_MODEL, '', []))
        dispatch(setLocalStoreValue(ARCN_STORE_PATH.SELECTED_BATCH_LINE, '', ''))
        dispatch(setFormValue(ARCN_FORM_STORE_KEYS.CREATE_BATCH, '', {}))

    }

    return (
        <div>
            <Panel
                type={PanelType.custom}
                isOpen={_.get(model, 'is-open')}
                onDismiss={() => setStore('is-open', false)}
                headerText=""
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooterContent}
                isFooterAtBottom={true}
                customWidth={"90vw"}
                isHiddenOnDismiss={true}
            >
                <PanelPivot/>
            </Panel>
        </div>
    )

}

export default BinBatchAllocationPanel