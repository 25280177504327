import _ from "lodash";
import React from "react";
import {isArray} from "@dladio/utils";
import {useDispatch} from "react-redux";
import {useLocalStore} from "@dladio/hooks";
import {ARCN_STORE_PATH} from "../../../../../../const/local-store.const";
import {middleErrorToast} from "../../../../../../service/toast.service";
import {setLocalStoreValue} from "../../../../actions/actions";
import {
    DetailsHeader,
    DetailsList,
    DetailsListLayoutMode,
    Icon,
    IDetailsHeaderProps,
    IDetailsListStyles
} from "@fluentui/react";

const BinTable = () => {
    const dispatch: any = useDispatch()
    const {model: batches} = useLocalStore(ARCN_STORE_PATH.BATCH_MODEL);
    const {model: selectedBatchIndex} = useLocalStore(ARCN_STORE_PATH.SELECTED_BATCH_LINE);
    let selectedBatchRelatedBins = _.get(batches, [selectedBatchIndex, 'bins'], [])
    const onClickCellHandler = (model: any, index: number, column: any) => {
        if (column?.fieldName === 'Action') {
            try {
                let clonedbatches: any = isArray(batches) ? _.cloneDeep(batches) : []
                let clonedBins: any = isArray(selectedBatchRelatedBins) ? _.cloneDeep(selectedBatchRelatedBins) : []
                clonedBins.splice(index, 1)
                clonedbatches[selectedBatchIndex]['bins'] = clonedBins
                dispatch(setLocalStoreValue(ARCN_STORE_PATH.BATCH_MODEL, '', clonedbatches))
            } catch (e) {
                middleErrorToast("Error", "Something went wrong")
            }
        } else {
            dispatch(setLocalStoreValue(ARCN_STORE_PATH.SELECTED_BATCH_LINE, '', index))
        }
    }

    const onRenderItemColumn = (model: any, index: any, column: any) => {
        const fieldContent = _.get(model, column.fieldName);

        if (column.fieldName === 'Action') return (
            <div className={'cursor-pointer'} onClick={() => onClickCellHandler(model, index, column)}><Icon
                iconName="Delete"/></div>)

        return (
            <div className={'cursor-pointer'} onClick={() => {
                onClickCellHandler(model, index, column);
            }}>
                {fieldContent}
            </div>
        )
    }

    const _onRenderDetailHeader: any = (detailsHeaderProps: IDetailsHeaderProps) => {
        if (detailsHeaderProps) {
            return (
                <DetailsHeader
                    {...detailsHeaderProps}
                />
            )
        }
        return null
    }

    return (
        <>
            <div className={"overflow-auto h-lg"}>
                <DetailsList
                    items={selectedBatchRelatedBins}
                    columns={config.header_fields}
                    compact={true}
                    styles={gridStyles}
                    setKey="none"
                    onRenderItemColumn={onRenderItemColumn}
                    onRenderDetailsHeader={_onRenderDetailHeader}
                    isHeaderVisible={true}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={1}
                    selectionPreservedOnEmptyClick={true}
                />
            </div>
        </>
    )
}


const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                // height: ,
            },
        }
    },
    headerWrapper: {
        flex: '0 0 auto'
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
    }
};

const config: any = {
    header_fields: [
        {
            key: 1,
            fieldName: 'bin_name',
            name: 'Bin',
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#ffffff'}},
        },
        {
            key: 2,
            fieldName: 'Quantity',
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#ffffff'}},
            name: 'Qty',
        },
        {
            key: 3,
            fieldName: 'Action',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#ffffff'}},
            name: 'Action',
        }
    ],
    is_search_visible: true,
    height: 'sm',
    title: "Items"
}

export default BinTable;