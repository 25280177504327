export enum PERCHASE_ORDER_FORM_STORE_KEYS {
    PURCHASE_ORDER = 'purchase-order',
    EDITE_PANEL_FORM = 'edit-panel-purchase-order',
    FREIGHT_FORM = 'freight-panel',
    LOGISTIC_FORM = 'logistic-form',
    ERROR_FORM = 'error-form',
    TABLE_LINE_FORM = 'table-line-form'
}

export enum PURCHASE_INVOICE_FORM_STORE_KEYS {
    PURCHASE_INVOICE = 'purchase-invoice',
    FREIGHT_FORM = 'pi-freight-panel-form',
    LOGISTIC_FORM = 'pi-logistic-form',
    BIN_LOCATION_FORM = 'pi-bin-location-form',
    BATCH_LOCATION_FORM = 'pi-batch-location-form',
    TABLE_LINE_EDIT_FORM = 'pi-table-line-edit-form',
    TEMP_ADD_BATCH_FORM = 'pi-temp-add-batch-form',
    TEMP_ADD_BIN_FORM = 'pi-temp-add-bin-form',
}

export enum ADDRESS_FORM_KEY {
    ADDRESS_FORM = 'address-form'
}

export enum GRPO_FORM_STORE_KEYS {
    GRPO_DOCUMENT_LINE_FROM = 'GRPO_document_line_form',
    BATCH_SETUP_TABLE_FORM = 'GRPO_batch_setup_table_form',
    TABLE_LINE_EDIT_PANEL = 'GRPO_table_line_edite_panel',
    BATCH_SETUP_TABLE_LINE = 'GRPO_batch_setup_table_line',
    FREIGHT_FORM = 'freight_panel',
    LOGISTIC_FORM = 'logistic_form',
    BIN_ALLOCATION_TABLE_FORM = 'GRPO_bin_allocation_table_form'
}

export enum ARCN_FORM_STORE_KEYS {
    CREDIT_NOTE = 'ar-credit-note',
    EDITE_PANEL_FORM = 'edit-panel-ar-credit-note',
    CREATE_BATCH = 'create-batch-ar-credit-note',
    FREIGHT_FORM = 'ar-freight-panel',
    LOGISTIC_FORM = 'logistic-form',
    ERROR_FORM = 'error-form',
    TABLE_LINE_FORM = 'table-line-form',
    CURRENCY ='cur',
    SELECT_BINS = 'select-bin',
    DOWN_PAYMENT='ar-down-payments'
}
