import _ from "lodash";
import FreightTable from "../FreightTable/FreightTable";
import {useDispatch} from "react-redux";
import {buttonStyles} from "@fluentui/react/lib/components/Nav/Nav.styles";
import {useLocalStore} from "@dladio/hooks";
import {ARCN_ERP_METHODS} from "../../../../const/method.const";
import {Panel, PanelType} from '@fluentui/react/lib/Panel';
import {middleErrorToast} from "../../../../service/toast.service";
import {ARCN_STORE_PATH,} from "../../../../const/local-store.const";
import {exeMethod, setFormValue} from "../../actions/actions";
import {DefaultButton, PrimaryButton} from "@fluentui/react";
import {ARCN_FORM_STORE_KEYS} from "../../../../const/form-store.const";

const FreightEditPanel = () => {

    const {model, setStore} = useLocalStore(ARCN_STORE_PATH.FREIGHT_EDIT_PANEL)
    const dispatch: any = useDispatch()
    const onSaveHandler = () => setStore('is-open', false)
    const onRenderFooterContent =
        () => (
            <div className={'flex gap-2'}>
                <PrimaryButton onClick={onSaveHandler} styles={buttonStyles}>
                    Submit
                </PrimaryButton>
                <DefaultButton onClick={onClickCancelHandler}>Cancel</DefaultButton>
            </div>
        )

    const onClickCancelHandler = () => {
        setStore('is-open', false)
    }

    return (
        <div>
            <Panel
                type={PanelType.custom}
                isOpen={_.get(model, 'is-open')}
                onDismiss={() => setStore('is-open', false)}
                headerText="Freight"
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooterContent}
                isFooterAtBottom={true}
                customWidth={"90vw"}
                isHiddenOnDismiss={true}
            >
                <FreightTable/>
            </Panel>
        </div>
    )

}
export {FreightEditPanel}