import _ from "lodash";
import {useDispatch} from "react-redux";
import {buttonStyles} from "@fluentui/react/lib/components/Nav/Nav.styles";
import {useLocalStore} from "@dladio/hooks";
import DownPaymentTable from "./components/DownPaymentTable";
import {ARCN_STORE_PATH} from "../../../../../const/local-store.const";
import {Panel, PanelType} from "@fluentui/react/lib/Panel";
import {ARCN_FORM_STORE_KEYS} from "../../../../../const/form-store.const";
import SelectedDownPaymentTable from "./components/SelectedDownPaymentTable";
import {DefaultButton, PrimaryButton} from "@fluentui/react";
import {getLocalStoreValue, setFormValue, setLocalStoreValue} from "../../../actions/actions";

const DownPaymentPanel = () => {

    const {model, setStore} = useLocalStore(ARCN_STORE_PATH.DOWN_PAYMENT)
    const dispatch: any = useDispatch()
    const onSaveHandler = () => {
        setStore('is-open', false)
        const selectedDownPayments: any = dispatch(getLocalStoreValue(ARCN_STORE_PATH.SELECTED_DOWN_PAYMENT))
        dispatch(setFormValue(ARCN_FORM_STORE_KEYS.DOWN_PAYMENT, '', selectedDownPayments))
    }
    const onRenderFooterContent =
        () => (
            <div className={'flex gap-2'}>
                <PrimaryButton onClick={onSaveHandler} styles={buttonStyles}>
                    Submit
                </PrimaryButton>
                <DefaultButton onClick={onClickCancelHandler}>Cancel</DefaultButton>
            </div>
        )

    const onClickCancelHandler = () => {
        setStore('is-open', false)
        dispatch(setLocalStoreValue(ARCN_STORE_PATH.SELECTED_DOWN_PAYMENT, '', []))
    }

    return (
        <div>
            <Panel
                type={PanelType.custom}
                isOpen={_.get(model, 'is-open')}
                onDismiss={() => setStore('is-open', false)}
                headerText="Down Payments"
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooterContent}
                isFooterAtBottom={true}
                customWidth={"90vw"}
                isHiddenOnDismiss={true}
            >
                <DownPaymentTable/>
                <div className={'mt-3 mb-3 font-bold'}>
                    Selected Down Payments
                </div>
                <SelectedDownPaymentTable/>
            </Panel>
        </div>
    )

}
export default DownPaymentPanel