import React from "react";
import {
    DetailsHeader,
    DetailsList,
    DetailsListLayoutMode,
    Icon,
    IDetailsHeaderProps,
    IDetailsListStyles
} from "@fluentui/react";
import _ from "lodash";
import {useForm, useLocalStore} from "@dladio/hooks";
import {GRPO_FORM_STORE_KEYS} from "../../../const/form-store.const";
import {isArray} from "@dladio/utils";
import {GRPO_STORE_PATH} from "../../../const/local-store.const";
import {LoadingToast} from "../../../service/toast.service";
import {AppDispatch} from "../../../store/store";
import {useDispatch} from "react-redux";
import {getBatchSetupLine} from "./Panel/EditDocumentLinePanel/BinBatchAllocation/actions/actions";
import {showConfirmDialog} from "../../../store/reducers/confirm-dialog";


const DocumentSection: React.FC = () => {

    const {model} = useLocalStore(GRPO_STORE_PATH.PAGE_LOADING);
    const {form, setForm} = useForm(GRPO_FORM_STORE_KEYS.GRPO_DOCUMENT_LINE_FROM);
    const {setStore} = useLocalStore(GRPO_FORM_STORE_KEYS.TABLE_LINE_EDIT_PANEL);
    const {
        setStore: batchSetupTableStore
    } = useLocalStore(GRPO_FORM_STORE_KEYS.BATCH_SETUP_TABLE_LINE);
    const {
        setStore: binAllocationSetStore
    } = useLocalStore(GRPO_STORE_PATH.BIN_ALLOCATION_TABLE);
    const dispatch: AppDispatch = useDispatch();

    const onClickCellHandler = (model: any, index: number, column: any) => {
        if (column?.fieldName === 'Action') {
            dispatch(showConfirmDialog({
                title: "Confirmation",
                subtitle: "Are you sure to delete this item",
                onConfirm: () => {
                    let lines = isArray(_.get(form, 'lines')) ? _.cloneDeep(_.get(form, 'lines')) : []
                    lines.splice(index, 1)
                    setForm('lines', lines?.reverse())
                }
            }))

        } else {
            // setEditForm({...model})
            setStore("selected_line_index", index)
            setStore("LineNum", model.LineNum)
            setStore('is-open', true)
            setStore('batch_allocation_is_disable', true)
            setStore('line', model)
            batchSetupTableStore('BatchNumbers', _.get(model, 'BatchNumbers', []))
            binAllocationSetStore('DocumentLinesBinAllocations', _.get(model, 'DocumentLinesBinAllocations', []))
            dispatch(getBatchSetupLine())
        }
    }

    return (
        <>
            <div className="px-2 my-4">
                {
                    !model ? <Table config={config} model={_.get(form, 'lines') ?? []}
                                    onClickCellHandler={onClickCellHandler}/> :
                        <LoadingToast className={'w-full h-[40vh] bg-white'}/>
                }
            </div>
        </>
    )
}

export default DocumentSection

const Table = ({config, model, onClickCellHandler}: any) => {

    const onRenderItemColumn = (model: any, index: any, column: any) => {
        const fieldContent = _.get(model, column.fieldName)

        if (column.fieldName === 'Action') return (
            <div className={'cursor-pointer'} onClick={() => onClickCellHandler(model, index, column)}><Icon
                iconName="Delete"/></div>)

        return (
            <div className={'cursor-pointer'} onClick={() => {
                onClickCellHandler(model, index, column)
            }}>
                {fieldContent}
            </div>
        )
    }

    const _onRenderDetailHeader: any = (detailsHeaderProps: IDetailsHeaderProps) => {
        if (detailsHeaderProps) {
            return (
                <DetailsHeader
                    {...detailsHeaderProps}
                />
            )
        }
        return null
    }

    return (
        <div className={"overflow-auto h-lg"}>
            <DetailsList
                items={model}
                columns={_.get(config, 'header_fields')}
                compact={true}
                styles={gridStyles}
                setKey="none"
                onRenderItemColumn={onRenderItemColumn}
                onRenderDetailsHeader={_onRenderDetailHeader}
                isHeaderVisible={true}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={0}
                selectionPreservedOnEmptyClick={true}
            />
        </div>
    )
}

const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        backgroundColor: "white",
        overflowX: 'scroll',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                height: "40vh",
            },
        }
    },
    headerWrapper: {
        flex: '0 0 auto'
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
    }
};

const config = {
    header_fields: [
        {
            key: 1,
            fieldName: 'ItemCode',
            name: 'Item Code',
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#f0f0f0'}},
        },
        {
            key: 3,
            fieldName: 'ItemName',
            minWidth: 200,
            maxWidth: 300,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Item Name',
        },
        {
            key: 4,
            fieldName: 'Quantity',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Quantity',
        },
        {
            key: 5,
            fieldName: 'Discount',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Discount',
        },
        {
            key: 6,
            fieldName: 'UnitPrice',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Unit Price',
        },
        {
            key: 7,
            fieldName: 'WhsName',
            minWidth: 200,
            maxWidth: 200,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Warehouse',
        },

        {
            key: 8,
            fieldName: 'GSTCode',
            minWidth: 100,
            maxWidth: 100,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'GST Code',
        },
        {
            key: 9,
            fieldName: 'LineTotal',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Line Total',
        },
        {
            key: 10,
            fieldName: 'Action',
            minWidth: 150,
            maxWidth: 150,
            styles: {root: {backgroundColor: '#f0f0f0'}},
            name: 'Action',
        }
    ],
    is_search_visible: true,
    height: 'sm',
    title: "Items"
}