import {object, array, number} from 'yup';

const schema = object().shape({
    DocumentLines: array().of(
        object().shape({
            BatchNumbers: array().required('BatchNumbers are required').min(1),
            DocumentLinesBinAllocations: array().required('DocumentLinesBinAllocations are required').min(1),
            validateBatchBinAllocation: array().test('batch-bin-allocation', 'Some batches are missing bin allocation', function (value) {
                const {BatchNumbers, DocumentLinesBinAllocations} = this.parent;
                if (BatchNumbers && DocumentLinesBinAllocations) {
                    const allocatedBaseLineNumbers = DocumentLinesBinAllocations.map((allocation: any) => allocation.SerialAndBatchNumbersBaseLine);
                    const unallocatedBatches = BatchNumbers.filter((batchNumber: any, index: number) => !allocatedBaseLineNumbers.includes(index));
                    return unallocatedBatches.length === 0;
                }
                return true;
            })
        })
    )
});

export default schema;