import {isArray} from "@dladio/utils";
import {ComboBox} from "@fluentui/react"
import {useDerive} from "@dladio/service"
import {useBoolean} from "@fluentui/react-hooks";

const AutoCompleteDropDown = ({
                                  label = '',
                                  method,
                                  className,
                                  isDisabled,
                                  staticOptions,
                                  defaultValue = '',
                                  body,
                                  styles,
                                  defaultKey,
                                  errorMessage = '',
                                  onChange = () => {
                                  }
                              }: any) => {
    const [allowFreeform, {toggle: toggleAllowFreeform}] = useBoolean(true);

    let {value, isFetching} = useDerive('exec', {
        options: {
            method: method,
            body: body
        },
        path: 'message'
    })

    value = isArray(value) ? value : []

    return (
        <ComboBox className={`${className} min-w-[208px] max-w-[280px]`}
                  disabled={isDisabled}
                  label={label}
                  errorMessage={errorMessage}
                  selectedKey={defaultKey}
                  defaultSelectedKey={defaultValue}
                  onChange={onChange}
                  options={staticOptions ?? value} allowFreeform={allowFreeform} style={styles}
        />
    )
}

export default AutoCompleteDropDown