import _ from "lodash";
import {PIFK} from "../../../const/form-field.const";
import {isArray} from "@dladio/utils";
import {useForm, useLocalStore} from "@dladio/hooks";
import {TupleCard, TupleCardLine} from "@dladio/core-ui";
import {FontIcon, Text, TextField} from "@fluentui/react";
import {PURCHASE_INVOICE_STORE_PATH} from "../../../const/local-store.const";
import {PURCHASE_INVOICE_FORM_STORE_KEYS} from "../../../const/form-store.const";

const Summary = () => {
    const {setStore} = useLocalStore(PURCHASE_INVOICE_STORE_PATH.FREIGHT_EDIT_PANEL)
    const {form: freightForm} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.FREIGHT_FORM)
    const {form, formController} = useForm(PURCHASE_INVOICE_FORM_STORE_KEYS.PURCHASE_INVOICE)

    console.log('form', freightForm)

    const totalBeforeDiscount: any = isArray(_.get(form,'DocumentLines')) ? _.get(form,'DocumentLines')?.reduce((prevValue: any, currentValue: any) => (

        prevValue + ((+currentValue?.Quantity * currentValue?.UnitPrice) - (currentValue?.DiscountAmount ? currentValue?.DiscountAmount : 0)

        )

    ), 0) : 0
    const freight: any = isArray(freightForm) ? freightForm.reduce((prevValue: any, currentValue: any) => (prevValue + (currentValue?.LineTotal ? +(currentValue?.LineTotal) : 0)), 0) : 0
    const freightTax = isArray(freightForm) ? freightForm.reduce((prevValue: any, currentValue: any) => (prevValue + (currentValue?.TaxSum ? currentValue?.TaxSum : 0)), 0) : 0
    const freightWithTax = isArray(freightForm) ? freightForm.reduce((prevValue: any, currentValue: any) => (prevValue + (currentValue?.LineGross ? +(currentValue?.LineGross) : 0)), 0) : 0
    const Tax: any = isArray(_.get(form, 'DocumentLines')) ? _.get(form, 'DocumentLines')?.reduce((prevValue: any, currentValue: any) => prevValue + (currentValue?.TaxRate ? +currentValue?.TaxRate : 0), 0) + (freightTax ? +freightTax : 0) : 0
    const orderDiscount: any = (+(totalBeforeDiscount ?? 0) * (+(_.get(form, PIFK.ORDER_DISCOUNT) ?? 0) / 100))
    const total: any = isArray(_.get(form, 'DocumentLines')) ? _.get(form, 'DocumentLines')?.reduce((prevValue: any, currentValue: any) => prevValue + (currentValue?.LineTotal ? +currentValue?.LineTotal : 0), 0) + freightWithTax - orderDiscount : 0
    const totalPaymentDue: any = total - _.get(form, PIFK.TOTAL_DOWN_PAYMENT)
    const balanceDue: any = totalPaymentDue
    const appliedAmount: any = 0

    const onClickFreightHandler = () => {
        setStore('is-open', true)
    }

    const discountTupleLineMarkup: any = (
        <div className={'grid grid-cols-12 gx-0 border-b'}>
            <div className={'col-span-1'}>
                <Text
                    className={'font-semibold text-[#4a4a56]'}
                    variant={'small'}>
                    Discount
                </Text>
            </div>
            <div className={'col-span-3'}>
                <div className={'grid grid-cols-5'}>
                    <div className={'col-span-4 ml-3'}>
                        <TextField name={PIFK.ORDER_DISCOUNT} disabled={true} value={_.get(form, PIFK.ORDER_DISCOUNT)}
                        />
                    </div>
                    <div className={'flex justify-center items-center align-middle'}>
                        %
                    </div>
                </div>
            </div>
            <div className={'flex  col-span-6 flex-row gap-2'}>
                <Text
                    className={'font-semibold text-[#4a4a56]'}
                    variant={'small'}>
                    {_.get(form, PIFK.DOC_CURRENCY)} {isNaN(orderDiscount) ? `0.00` : orderDiscount.toFixed(2)}
                </Text>
            </div>
        </div>
    )

    const freightTupleLineMarkup = (
        <div className={'grid grid-cols-12 gx-0  border-b'}>
            <div className={'col-span-4'}>
                <Text
                    className={'font-semibold text-[#4a4a56]'}
                    variant={'small'}>
                    Freight
                </Text>
            </div>
            <div onClick={onClickFreightHandler}
                 className={'flex  col-span-6 flex-row gap-2'}
            >
                <Text
                    className={'font-semibold text-[#4a4a56]'}
                    variant={'small'}>
                    {_.get(form, PIFK.DOC_CURRENCY)} {freight.toFixed(2) ?? 0.00}
                </Text>
                <FontIcon
                    onClick={() => onClickFreightHandler()} className={''}
                    style={{fontSize: 10, cursor: 'pointer'}}
                    iconName="EditSolid12"
                />
            </div>
        </div>
    )

    return (
        <TupleCard title='Summary'>
            <TupleCardLine title="Total Items"
                           value={`${isArray(_.get(form, 'DocumentLines')) ? _.get(form, 'DocumentLines')?.length : 0}`}/>
            <TupleCardLine title="Total Before Discount"
                           value={`${_.get(form, PIFK.DOC_CURRENCY)} ${(totalBeforeDiscount ? totalBeforeDiscount.toFixed(2) : 0.00)}`}/>
            {discountTupleLineMarkup}
            <TupleCardLine title={'Total Down Payment'}
                           value={`${_.get(form, PIFK.DOC_CURRENCY)} ${_.get(form, PIFK.TOTAL_DOWN_PAYMENT)?.toFixed(2) ?? ''}`}/>
            {freightTupleLineMarkup}
            <TupleCardLine title="Total Tax" value={`${_.get(form, PIFK.DOC_CURRENCY)} ${Tax?.toFixed(2)}`}/>
            <TupleCardLine title="Total Payment Due"
                           value={`${_.get(form, PIFK.DOC_CURRENCY)} ${totalPaymentDue?.toFixed(2) ?? 0}`}/>
            <TupleCardLine title="Applied Amount"
                           value={`${_.get(form, PIFK.DOC_CURRENCY)} ${appliedAmount?.toFixed(2)}`}/>
            <TupleCardLine title="Balance Due"
                           value={`${_.get(form, PIFK.DOC_CURRENCY)} ${balanceDue?.toFixed(2) ?? 0}`}/>
        </TupleCard>
    )
}

export default Summary